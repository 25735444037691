import { Component, useComponentProps } from 'apprise-ui/component/component'
import { classname, Disabled, Styled } from 'apprise-ui/component/model'
import { Readonly } from 'apprise-ui/readonly/model'
import { useReadonlyProps } from 'apprise-ui/readonly/readonly'
import * as React from "react"
import { useDrop } from "react-dnd"
import { defaultItemType, defaultPath } from "./model"
import "./styles.scss"



// a thin, simplifying layer over react-dnd.



export type DroppableProps<T = any> = Styled & Disabled & Readonly & Partial<{

    // an identifier for the location of this droppable.  
    path: string

    // the specific item types it may allow to be dropped.
    accept: string[]

    // called on drop of an item from a certain location.
    onDrop: (item: T, path: string) => void
    onDoubleClick: () => void

    children: React.ReactNode | ((_: DropProps) => React.ReactNode)
}>

type DropProps = {

    isOver: boolean
    didDrop: boolean
    canDrop: boolean
}

export const Droppable = <T extends any = any>(props: DroppableProps<T>) => {

    const {

        className,
       
        path = defaultPath,
        accept = [defaultItemType],

        onDrop,
        onDoubleClick,

        disabled

    } = useComponentProps(props)

    const { readonly } = useReadonlyProps(props)

    const inactive = disabled || readonly

    const [childprops, ref] = useDrop({

        accept,

        // do nothing if event already handled, otherwwise callback client.
        drop: (item:any, monitor) => inactive || monitor.didDrop() ? undefined : onDrop?.(item?.data ?? item, path)

        ,

        collect: (monitor): DropProps => ({

            isOver: monitor.isOver({ shallow: true }) && !inactive,
            didDrop: monitor.didDrop(),
            canDrop: monitor.canDrop() && !inactive,

        })
    })

    const { isOver } = childprops

    const children = typeof props.children === 'function' ? props.children(childprops) : props.children

    const classes = classname(className, className || 'droppable-default')
    const innerClasses = classname(isOver && 'droppable-hovered')

    return <Component name='droppable' className={classes} {...props}>
        <div ref={ref} onDoubleClick={onDoubleClick} className={innerClasses} >
            {children}
        </div>
    </Component>

}