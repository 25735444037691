
import { Optional } from 'apprise-frontend-core/utils/common'
import { allLanguages, Language, useLanguage } from "./language"


export type Multilang = Partial<Record<Language,string>>

export const useL = () => useMultilang().l

export const useMultilang = () => {

    const lang = useLanguage()

    
    const self = {


        l: (ml: Optional<Multilang>) => ml ? (ml?.[lang.current()] ?? ml?.[lang.default()]) : undefined

        ,

        comparator: (m1: Optional<Multilang>, m2: Optional<Multilang>) =>  (self.l(m1) ?? '').localeCompare(self.l(m2) ?? '')

        ,


        test: (ml: any) : ml is Multilang => !!ml && typeof ml === 'object' && Object.keys(ml).every(k=>allLanguages.includes(k as Language)) 
    
    }


    return self

}