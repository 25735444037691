import { State } from 'apprise-frontend-core/state/api'
import { createContext } from 'react'


// stores a set of feature toggles and a "policy" to determine if a toggle is active, 
// ie. the feature should be available to users.


export type ToggleState<T extends string = string> = {

    toggles: readonly T[]
    isActive: (_: T) => boolean
}


// state-holding context, serves default until config mgmt is active, if ever.
export const ToggleContext = createContext<State<ToggleState>>(undefined!)


