
import { utils } from 'apprise-frontend-core/utils/common'
import { classname } from 'apprise-ui/component/model'
import { useUIPreferences } from 'apprise-ui/preferences'
import React, { useContext, useEffect } from 'react'
import { TableContext } from './context'
import { Column, ColumnProps, Table, TableCell, TableElement, TableProps } from './table'


export type TablePreferences = {

    tables: Record<string, { layout: string[] }>

}

export const useTableApi = <E extends TableElement>(props: TableProps<E>) => {

    const { name, layout } = props

    const tableLayout = layout ?? (name ? 'custom' : 'fixed')

    const tableName = name!  // use only when layout is custom

    const ctx = useContext(TableContext)

    //////////////////////////////////////////////////////////////////////////

    const preferences = useUIPreferences<TablePreferences>()

    const children = utils().elementsIn(props.children)

    const allCols = React.useMemo((): ColumnProps<E>[] => {

        const cols = children.filter(e => utils().isElementOf(Column)(e) || utils().hasRole('column')(e))

        return cols.map(c => c?.props)

    }, [children])

    // usage checks
    useEffect(() => {

        if (tableLayout === 'fixed')
            return

        if (!name)
            throw Error("table with custom layout needs a 'name' prop")

        if (allCols.find(c => !c.name))
            throw Error("table with custom layout needs columns with a 'name' prop")

        if (utils().dedup(allCols.map(c => c.name)).length !== allCols.length)
            throw Error("table with custom layout needs columns with unique 'name' props")


        // eslint-disable-next-line
    }, [tableLayout, name, children])


    const self = {

        allColumns: () => allCols


        ,

        currentLayout: () => {

            if (tableLayout === 'fixed')
                return allCols

            // session first, prefs next, then client default.
            const columnNames = ctx.get()[tableName]?.layout ?? preferences.get().tables?.[tableName]?.layout ?? (Array.isArray(tableLayout) ? tableLayout : [])

            if (columnNames.length === 0)   // hard-coded on columns
                return self.defaultLayout()

            // exclude pinned for retro-compatibility (saved prefs used to include them)
            let layout = columnNames.map(name => allCols?.find(c => !c.pinned && c.name === name)).filter(c => !!c) as ColumnProps<E>[] ?? []  //ignore columns that no longer exists.

            // forces pinned in the layout
            const pinned = allCols.filter(c => c.pinned)

            layout = [...pinned, ...layout]

            return layout

        }

        ,

        defaultLayout: () => {

            const explicit = allCols.filter(c => c.defaultLayout || c.pinned)

            return explicit?.length ? explicit : self.allColumns()
        }

        ,

        currentFilterText: (d: E) => self.currentLayout().flatMap(c => utils().arrayOf(c.text?.(d)))

        ,

        currentFilters: () => utils().dedup(self.currentLayout().flatMap(c => utils().arrayOf(c.filter)))

        ,

        setCurrentLayout: (cols: string[] | undefined) => {

            if (tableLayout === 'fixed')
                return

            ctx.set(s => s[tableName] = { ...s[tableName], layout: cols })

        }

        ,

        saveCurrentLayout: (prefs: TablePreferences) => {

            if (tableLayout === 'fixed')
                return

            const tablePrefs = prefs.tables ?? {}

            tablePrefs[tableName] = { ...tablePrefs[tableName], layout: self.currentLayout().map(c => c.name!) }

            prefs.tables = tablePrefs

        }

        ,

        counter: () => children.find(utils().isElementOf(Table.Counter))

        ,

        controls: () => children.filter(utils().isElementOf(Table.Control)).map(c => c.props?.children)


        ,

        filters: () => children.filter(utils().isElementOf(Table.Filter)).map(c => c.props)

        ,

        others: () => children.filter(utils().isElementOf(Table.Other)).map(c => c.props)

        ,

        selectionConfig: () => props.selection ? {
            onChange: (_, selected: E[]) => props.selection?.select?.(selected),
            fixed: 'left' as const,
            columnWidth: 30,
            getCheckboxProps: (r: E) => ({ disabled: !props.selection?.isSelectable?.(r, props.selection.selected!) }),
            selectedRowKeys: props.selection.selected!.map(e => typeof props.rowId === 'string' ? e[props.rowId] : props.rowId?.(e)),
            hideSelectAll: !props.selection.selectAll?.(props.selection.selected!)
        } : undefined

        ,

        actionButtons: () => children.filter(utils().isElementOf(Table.Buttons))[0]

        ,

        adaptProps: <E extends TableElement>(props: Partial<ColumnProps<E>> = {}) => ({

            title: props.title,
            key: props.name,
            help: props.help ?? props.title,
            dataIndex: props.path,
            width: props.hidden ? '0' : props.width,
            align: props.align ?? 'left',

            // implementing this to avoid full re-render on row selection...
            // basing it on identity of the entire row, as going at cell-level might kill the optimisation.
            shouldCellUpdate: props.noMemo ? () => true : props.renderIf ?? ((previous: E, next: E) => previous !== next),

            className: props.className,

            render: (...args) => (

                <TableCell style={(props.styleFor as any)?.(...args)} className={classname('apprise-table-cell', `cell-align-${props.align ?? 'left'}`, props.className, (props.classnameFor as any)?.(...args))}>
                    {((props.render ?? defaultRender) as any)(...args)}
                </TableCell>
            ),

        })

        ,

        checkScroll: (wrapper: HTMLElement) => {
            const storedTop = ctx.get()?.[tableName]?.scroll ?? 0
            const tableBody = wrapper.querySelector('.ant-table-body') as HTMLElement

            tableBody.onscroll = () => {
                console.log('SET')

                ctx.setQuietly(s => s[tableName] = { ...s[tableName], scroll: tableBody.scrollTop })
            }

            storedTop && !tableBody.scrollTop && requestAnimationFrame(() => tableBody.scrollTop = storedTop)
        }

    }

    return self
}



const defaultRender = (t: any) => typeof t === 'object' ? JSON.stringify(t) : t
