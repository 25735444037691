import { Slider as AntdSlider } from 'antd'
import { utils } from 'apprise-frontend-core/utils/common'
import { classname, Ranged, Wide } from 'apprise-ui/component/model'
import { useChangeHelper } from 'apprise-ui/field/changehelper'
import { Field, useFieldProps } from 'apprise-ui/field/field'
import { ChangeTracked, Fielded, Uncontrolled } from 'apprise-ui/field/model'
import { useReadonlyHelper } from 'apprise-ui/field/readonlyhelper'
import { useResetHelper } from 'apprise-ui/field/resethelper'
import * as React from 'react'
import './styles.scss'


type ValueType = number | [number, number] | undefined

export type SliderProps<T extends ValueType> = Fielded<T> & ChangeTracked<T> & Ranged & Uncontrolled & Wide & Partial<{

    children: T

    marks: Record<number, React.ReactNode>
    step: number

    tipRender: true |( (_: number | undefined) => React.ReactNode)

    reveal: boolean | ((_: T) => React.ReactNode)
}>


const defaultReveal = (n: number) => <span style={{ fontSize: 'small' }}>{n ?? 0}</span>
const defaultIntervalReveal = (([n1, n2]) => <span style={{ fontSize: 'small' }}>{n1}-{n2}</span>)

export const SliderBox = (props: SliderProps<number>) => {

    const { reveal, ...rest } = props

    const defaultedReveal = !!reveal && typeof reveal === 'boolean' ? defaultReveal : reveal

    return <Slider {...rest} reveal={defaultedReveal} />
}

SliderBox.Interval = (props: SliderProps<[number, number]>) => {

    const { reveal, ...rest } = props

    const defaultedReveal = !!reveal && typeof reveal === 'boolean' ? defaultIntervalReveal : reveal
  
    return <Slider {...rest} interval reveal={defaultedReveal}>
        {props.children ?? [0, 0]}
        </Slider>
}

const Slider = <T extends ValueType>(clientprops: SliderProps<T> & {interval?:boolean}) => {

    // track the last value reported by the field, so that we can tell later if we'rendering an internal change (comes from the filed) 
    // or an external change (e.g. a reset).
    const trackedOnChange =  clientprops.onChange ? ((v:T | undefined) => { 

        lastChange.current = v

        clientprops.onChange?.(v)

    }) : undefined


    const props = useFieldProps({...clientprops, onChange :trackedOnChange, delay: clientprops.delay ?? 500})


    const { delay, children, defaultValue, innerClassName, innerStyle, controlled, interval, disabled, range, tipRender= t => t, debug, step, ...rest } = props

    const reveal = !!props.reveal && typeof props.reveal === 'boolean' ? defaultIntervalReveal : props.reveal
    
    const { onChange, flushDebouncedChange, cancelDebouncedChange } = props

    const { pastMode, pastValue } = useChangeHelper(props)

    useReadonlyHelper(props)

    useResetHelper(props)

    // forgets the type: antd wants us to make a choice, but we're still working generically here.
    const currentValue: any = children ?? defaultValue

    const latestValue = pastMode ? pastValue : currentValue

    const lastChange =  React.useRef<T | undefined>(latestValue)

    

    // loses typing to overcome antd assumption type of slider is statically known.
    const change: any = onChange

    const [key, changeKey] = React.useState(0)


    React.useEffect(() => {

        const shouldSync = controlled && (!utils().deepequals(latestValue, lastChange.current))

        // resync internal and external state.
        if (shouldSync) {

            // cancel any delayed change that may be scheduled at this point.
            cancelDebouncedChange()

            lastChange.current = latestValue

            changeKey(k => ++k)
        }

    }, [controlled, latestValue, cancelDebouncedChange])

    const formatter = typeof tipRender === 'boolean' ? (t => t) : tipRender

    const content = <AntdSlider key={key} disabled={disabled} style={innerStyle} className={classname(props.className, innerClassName)}
        {...rest}
        defaultValue={latestValue}
        onChange={change}
        onAfterChange={flushDebouncedChange }
        step={step}
        range={interval}
        tooltip={{formatter}} />


    if (debug)
        console.log("controlled", controlled, "external", children, "interval", interval, "reveal", !!reveal)



    return <Field name='sliderbox' {...props}>
        {reveal ?
            <div className='sliderbox-reveal-group' style={{ display: 'flex', ...innerStyle }}>
                <div style={{ flexGrow: 1 }}>
                    {content}
                </div>
                {/* with any width, the value can grow larger without shrinking the slider, eventually overflows to the right. */}
                <div className='sliderbox-value'>
                    {reveal?.(latestValue)}
                </div>
            </div>

            :
            content
        }
    </Field>
}