import { Action, useActions } from 'apprise-frontend-core/authz/action'
import { any } from 'apprise-frontend-core/authz/constants'
import { useLoggedOracle } from 'apprise-frontend-core/authz/oracle'
import { useT } from 'apprise-frontend-core/intl/language'
import { Optional } from 'apprise-frontend-core/utils/common'
import { tenantActions } from 'apprise-frontend-iam/authz/oracle'
import { useUserOracle } from 'apprise-frontend-iam/authz/user'
import { useLogged } from 'apprise-frontend-iam/login/logged'
import { noTenant } from 'apprise-frontend-iam/tenant/model'
import { UserLabel } from 'apprise-frontend-iam/user/label'
import { User, useUserModel } from 'apprise-frontend-iam/user/model'
import { useUserStore } from 'apprise-frontend-iam/user/store'
import { Button } from 'apprise-ui/button/button'
import { classname, Resettable } from 'apprise-ui/component/model'
import { Field, useFieldProps } from 'apprise-ui/field/field'
import { Fielded } from 'apprise-ui/field/model'
import { Label } from 'apprise-ui/label/label'
import { useReadonlyProps } from 'apprise-ui/readonly/readonly'
import { MultiSelectBox } from 'apprise-ui/selectbox/selectbox'
import { SwitchBox } from 'apprise-ui/switchbox/switchbox'
import { Column, Table } from 'apprise-ui/table/table'
import { Tip } from 'apprise-ui/tooltip/tip'
import { LockIcon } from 'apprise-ui/utils/icons'
import React, { useState } from 'react'
import { FaAsterisk } from 'react-icons/fa'
import { Permission, usePermissions } from './model'
import './permissionbox.scss'
import { RevealOnMount } from 'apprise-ui/utils/revealonmount'

// shows, grants, and revokes permissions over resources.
// takes a range of users, a range of resources of a given type, and a range of permissions that may be granted or revoked over those resources.
// takes also a set of permissions already granted and shows them in a table where users, resources, and permissions are mapped to columns.
// two dropdowns select additional users and resources to form new rows and use them grant new permissions (preselecting is also possible). 
// when users don't range, serves as viewer/editor for the permissions of a given user (user-centric). 
// viceverse when resources don't range, serves as viewer/editor for the permissions over a given resource (resource-centric, or team-oriented).


export type PermissionBoxProps<R> = Fielded<Permission[]> & Resettable & ResourceProps<R> & {

    permissions: Permission[]               // the current set of granted permissions.
    maxPrivilege?: Action

    userRange: User[]                       // the users in scope.
    preselectedUsers?: User[]                // the users in scope that are pre-selected.

    resetSignal: any                         // changes if selection should be reset.
}


export type ResourceProps<R> = {

    permissionRange: Action[]               // the range of permissions that can be shown, granted, or revoked.

    resourceRange: R[]                      // the resources in scope.
    wildcard?: boolean                 // true if permissions cannot be shown, given, or granted over all resources at once.     

    preselectedResources?: R[]              // the resources in scopes that are pre-selected.
    preselectWildcard?: boolean

    tenantResource: boolean
    resourceSingular: string
    resourcePlural: string
    resourceText: (t: R) => Optional<string>
    resourceId: (t: R) => string
    resourceRender?: (t: R) => any
    resourceComparator?: (t1: R, t2: R) => number



}

type State<R extends any = any> = {

    users: User[]
    resources: R[]
}


type Row<R> = {

    id: string,
    user: User,
    resource: R,
    name: string,
    isnew: boolean,
    order: 0 | 1 | 2
    disabledMessage: string | undefined
}

// extended properties for internal use.
export type ProcessedProps<R extends any = any> = Partial<PermissionBoxProps<R>> & {

    processedUserRange: User[]
    processedResourceRange: R[]
    wildcardOnly: boolean

}

export const PermissionBox = <R extends any = any>(clientprops: Partial<PermissionBoxProps<R>>) => {

    const t = useT()
    const perm = usePermissions()

    const oracle = useUserOracle()

    const props = useProcessed(useFieldProps(clientprops));

    const {

        permissionRange,
        permissions,
        maxPrivilege,
        onChange,

        preselectedUsers,
        preselectedResources,

        userRange,
        resourceRange,

        wildcard,
        wildcardOnly,

        processedResourceRange,
        processedUserRange,

        userRender,
        resourceRender,

        userText,
        resourceText,

        userId,
        resourceId,

        debug,
        resetSignal,

        resourceSingular,
        resourcePlural,
        userSingular,



        ...rest

    } = props


    const { disabled, readonly } = useReadonlyProps(props)

    const initialState = { users: preselectedUsers, resources: preselectedResources }

    const [state, stateSet] = React.useState<State>(initialState)

    // syncs on external reset.
    React.useEffect(() => {

        stateSet(initialState)

        // eslint-disable-next-line
    }, [resetSignal])

    const { users, resources } = state

    const rows = useRows(props, state)

    
    const setResources = (resources: R[] = []) => stateSet(s => ({ ...s, resources }))
    const setUsers = (users: User[] = []) => stateSet(s => ({ ...s, users }))

    const grant = (permissions: Permission[], p: Permission) => perm.reconcile([p, ...permissions])

    const revoke = (permissions: Permission[], p: Permission) => {

        const index = permissions.findIndex(pp => perm.idOf(p) === perm.idOf(pp))

        if (index >= 0) {
            const copy = [...permissions]
            copy.splice(index, 1)
            return perm.reconcile(copy)
        }
        return permissions
    }

    const flip = (row: Row<R>, action: Action) => (value: boolean | undefined) => {

        const p: Permission = { subject: row.user.username, action: row[action.name].action }

        onChange?.(value ? grant(permissions, p) : revoke(permissions, p))

    }

    const { filteredUserRange = [], filtereResourceRange = [] } = useRemainingOptions(rows, props, state)

    debug && console.log(
        "type",
        "users selected", users.length,
        "out of remaining", filteredUserRange.length,
        "resources selected", resources.length,
        "out of remaining", filtereResourceRange.length,
        "rows", rows.length,
        "actions", permissionRange.length,
        "permissions", permissions.length)



    // show selectors and columns if not empty or "centric".
    const showUserSelector = userRange.length > 1
    const showUserCol = userRange.length > 1
    const showResourceSelector = resourceRange.length > 1
    const showResourceCol = userRange.length === 1 || resourceRange.length > 1 || wildcardOnly     // but show it if there's only wildcard or in user centric.

    // adapts style and placeholder to partial selection.
    const usersSelected = showUserSelector && users.length > 0
    const resourcesStillToSelect = usersSelected && resources.length === 0
    const resourcesSelected = showResourceSelector && resources.length > 0
    const usersStillToSelect = users.length === 0 && resourcesSelected

    const [showSelectors, setShowSelectors] = useState(false)


    // avoid Add More if there's a single user and this has already the highest privilege in no particular tenant scope.
    const singleUserHasMaxPrivilege =  userRange.length === 1 && maxPrivilege && oracle.given(userRange[0]).can(maxPrivilege, noTenant)


    props.debug && console.log({ rows, permissions, showUserSelector, showResourceSelector })


    return <Field name='permbox' {...rest}>

        {   showSelectors &&
            <RevealOnMount>

                {showUserSelector &&

                    <MultiSelectBox<User> noClear
                        innerClassName={classname(usersStillToSelect && 'selector-partial')}
                        placeholder={t(`permission.table_user_placeholder${usersStillToSelect ? '_partial' : ''}`)}
                        keyOf={userId} options={processedUserRange} render={userRender} textOf={userText} onChange={setUsers}>
                        {users}
                    </MultiSelectBox>

                }

                {showResourceSelector &&

                    <MultiSelectBox<R> noClear
                        innerClassName={classname(resourcesStillToSelect && 'selector-partial')}
                        placeholder={t(`permission.table_resource_placeholder${resourcesStillToSelect ? '_partial' : ''}`, { plural: t(resourcePlural).toLocaleLowerCase() })}
                        keyOf={resourceId} options={filtereResourceRange} render={resourceRender} textOf={resourceText} onChange={setResources}>
                        {resources}
                    </MultiSelectBox>

                }

            </RevealOnMount>
        }

        <Table fixedHeight data={rows} noBar noHeader={rows.length === 0} emptyPlaceholder={t('permission.table_empty_placeholder')}

            initialSort={[{ key: 0, mode: 'asc' }, { key: 1, mode: 'asc' }]}
            rowClassName={row => classname('perm-row', row.isnew && 'perm-newrow')}

            {...rest}>


            {/* stays hidden but can we base a logical order on it. */}
            <Column<Row<R>> hidden render={r => r.order} />

            {/* stays hidden but can we base a logical order on it. */}
            <Column<Row<R>> hidden render={r => r.name} />

            {showUserCol &&

                <Column<Row<R>> title={t(userSingular)} render={row => userRender(row.user)} />

            }

            {showResourceCol &&

                <Column<Row<R>> title={t(resourceSingular)} render={row => resourceRender?.(row.resource)} />

            }

            {

                permissionRange.map((action, i) => {

                    const title = t(action.shortName || action.name)
                    const desc = action.description ?? (action.shortName ? undefined : action.name) // fallback to name if isn't used for title.

                    return <Column<Row<R>> name='switch' key={i} width={action?.width ?? 100} align='center' title={desc ? <Tip tipPlacement='topRight' tip={t(desc)}>{title}</Tip> : title} render={row => {

                        const locked = (disabled || readonly || row[action.name].disabled)

                        return <div className='apprise-row permbox-switch'>

                            {locked &&

                                <Tip tip={row[action.name].disabledMessage}>
                                    <LockIcon className='switch-locked' />
                                </Tip>
                            }
                            <SwitchBox disabled={locked} onChange={flip(row, action)}>
                                {row[action.name].active}
                            </SwitchBox>
                        </div>

                    }} />

                })


            }

        </Table>

        {(showUserSelector || showResourceSelector) &&

            <div className={classname('more-permissions', showSelectors && 'hidden')}>
                <Button enabled={!singleUserHasMaxPrivilege} type='link' onClick={() => setShowSelectors(true)} >{t('permission.add_more')}</Button>
            </div>
        }

    </Field>




}


// helpers

const useProcessed = <R extends any = any>(props: Partial<PermissionBoxProps<R>>) => {

    const t = useT()
    const users = { ...useUserStore(), ...useUserModel() }

    // defaults

    const {

        permissions = [],
        onChange = () => { },
        permissionRange = [],
        resourceRange = [],

        // defaults to all users, provided there are resources to combine with.
        userRange = resourceRange.length > 0 ? users.all() : [],

        // adds wildcard by default, unlesss it's resource-centric.
        wildcard = resourceRange.length !== 1,

        preselectWildcard,
        preselectedResources = preselectWildcard ? [any as R] : [],
        preselectedUsers = [],

        resourceText = JSON.stringify,
        resourceComparator,
        resourceRender,
        resourceId,

        resourceSingular = t('permission.table_resource_singular'),
        resourcePlural = t('permission.table_resource_plural'),

        debug,

        ...rest

    } = props


    const wildcardOnly = wildcard && resourceRange.length === 0

    // defaults and orders resource range
    const orderedUserRange = [...userRange].sort(users.comparator)

    // add wildcard to resource range, if desired.  
    const extendedResourceRange = wildcard ? [any, ...resourceRange] as R[] : resourceRange

    // adapts comparator to wildcard.
    const extendedResourceComparator = resourceComparator && ((r1: R, r2: R) => r1 === any ? -1 : r2 === any ? 1 : resourceComparator(r1, r2))

    const orderedResourceRange = React.useMemo(() => {

        if (!resourceComparator)
            return extendedResourceRange

        debug && console.log("sorting resources...")

        return [...extendedResourceRange].sort(extendedResourceComparator)

        // eslint-disable-next-line
    }, [props.resourceRange])

    const allTitle = t('permission.perm_all_resources', { plural: t(resourcePlural) })

    // adapts render to wildcard.
    const extendedResourceRender = ((r: R) => r === any ?

        <Label icon={<FaAsterisk style={{ marginTop: 1 }} size={10} />} className='permbox_all_resources' title={allTitle} />

        :

        resourceRender?.(r))

    // adapts key function to wildcard.
    const extendedResourceId = ((r: R) => r === any ? any : resourceId?.(r)!)


    // adapts text extraction to wildcard.
    const extendedResourceText = (r: R) => r === any ? allTitle : resourceText?.(r)



    return {

        ...rest, debug,

        permissionRange,
        permissions,
        onChange,

        userRange,
        resourceRange,

        wildcard,
        wildcardOnly,

        processedUserRange: orderedUserRange,
        processedResourceRange: orderedResourceRange,

        preselectedUsers,

        // defaults to wildcard if allowed to force its row to show.
        preselectedResources: preselectedResources.length === 0 && wildcardOnly ? [any as R] : preselectedResources,

        resourceSingular,
        resourcePlural,

        // swaps in extended resources APIs unless there's no wildcard
        resourceRender: wildcard ? extendedResourceRender : resourceRender,
        resourceId: wildcard ? extendedResourceId : resourceId,
        resourceComparator: wildcard ? extendedResourceComparator : resourceComparator,
        resourceText: wildcard ? extendedResourceText : resourceText,

        // adds user APIs for simmetry.
        userText: users.fullName,
        userSingular: 'permission.table_user_col',
        userId: (u: User) => u.username,
        userRender: (u: User) => <UserLabel bare user={u} />,


    }
}


// computes rows for the table.

const useRows = <R extends any = any>(props: ProcessedProps<R>, state: State<R>) => {

    const {
        permissionRange = [],
        permissions = [],
        userRange,
        processedUserRange,
        resourceRange,
        processedResourceRange,
        resourceId, resourceRender,
        tenantResource
    } = props

    // tells us what has really changed and should be kept or 
    const initialPermissions = React.useRef<Permission[]>(permissions)

    const t = useT()
    const logged = useLogged()
    const oracle = useLoggedOracle()
    const actions = useActions()
    const perm = usePermissions()

    const { users, resources } = state

    const idmap = perm.idmapOf(permissions)
    const initialIdMap = perm.idmapOf(initialPermissions.current)

    //  synthesis a row for each point in the cross-product of users and ranges in range.
    //  then removes those that shouldn't remain in the table (based on various factors.)
    return processedUserRange.flatMap(user => {

        const userActions = permissions.filter(p => p.subject === user.username).map(p => p.action)
        const initialUserActions = initialPermissions.current.filter(p => p.subject === user.username).map(p => p.action)

        return processedResourceRange.map(resource => rowFor(user, resource, userActions, initialUserActions))
    })
        .filter(r => r.visible)


    // helper functions are hoisted 
    function rowFor(user: User, r: R, userActions: Action[], initialUserActions: Action[]) {

        const resource = resourceId?.(r)!
        const tenant = (tenantResource && (r as any).tenant) || noTenant
        const passthrough = tenantResource && user.tenant !==noTenant && actions.isLikeAny(tenantActions.manage, userActions)  // no specific tenant
        const initiallyPassthrough = tenantResource && user.tenant !==noTenant && actions.impliedByAny(actions.specialise(tenantActions.manage, tenant), initialUserActions)

        const resourceName = resourceRender?.(r)

        const userCentric = userRange?.length === 1
        const resourceCentric = resourceRange?.length === 1

        return permissionRange.map(a => actions.specialise(a, resource)).reduce((acc, a) => {

            const permission: Permission = { subject: user.username, action: a }
            const contained = idmap[perm.idOf(permission)]
            const active = contained || passthrough || actions.impliedByAny(a, userActions)

            // disabled if virtual, implied, own, passthrough, or forbidd
            const disabledTest = ([

                [() => logged.username === user.username, t("permission.perm_locked_your_own")],
                [() => !!a.virtual, t("permission.perm_locked_virtual")],
                [() => passthrough, t("permission.perm_locked_passthrough")],
                [() => active && !contained, t("permission.perm_locked_implied")],
                [() => !oracle.can(a), t("permission.perm_locked_unauthorized")]


            ] as [() => boolean, string][]).find(test => test[0]())

            const disabled = !!disabledTest
            const disabledMessage = disabledTest && disabledTest[1]

            const subjectSelected = userCentric ? true : users.find(u => u.username === user.username)
            const resourceSelected = resourceCentric ? true : resources.find(r => resourceId?.(r) === resource)
            const selected = subjectSelected && resourceSelected

            const initiallyContained = initialIdMap[perm.idOf(permission)]
            const initiallyActive = initiallyPassthrough || actions.impliedByAny(a, initialUserActions);

            // does this cell make the row visible? 

            const visible = acc.visible ||
                !!selected              // a) the user wants to see it
                || contained             // b) shows current state
                || initiallyContained    // c) clarifies next state...
                // ...and required when 'demoting' (mustn't disappear mid-flight)

                // if resource-centric, we must also show users with permissions implied by templates
                // or we lose info (in subject-centric we mustn't, it'd be redundant)
                || (!!resourceCentric && (active || initiallyActive))



            const isnew = acc.isnew || !initiallyActive
            const order = resource === any ? 1 : isnew ? 2 : 0 as 0 | 1 | 2

            return { ...acc, visible, order, isnew, [a.name]: { action: a, active, disabled, disabledMessage, tenant } }

        },

            { id: `${user.username}: ${resource}`, user, order: 0 as 0 | 1 | 2, resource: r, name: `${user.username}: ${resourceName}`, isnew: false, visible: false, disabledMessage: undefined }

        )

    }


}

// excludes from the option list users and tenants that cannot be further combined to form new rows. 
const useRemainingOptions = <R extends any = any>(rows: Row<R>[], props: ProcessedProps<R>, state: State<R>) => {

    const { processedUserRange, processedResourceRange } = props

    const { users, resources } = state

    // users and resources that are in rows but not currently selected (deduped)
    const usersInRows = rows.map(row => row.user).filter((u, i, a) => a.indexOf(u) === i).filter(u=> !users.includes(u))
    const resourcesInRows = rows.map(row =>row.resource).filter(r => r !== any).filter((r, i, a) => a.indexOf(r) === i).filter(r => !resources.includes(r!))

    const noUsersLeft = processedUserRange.length === usersInRows.length
    const noResourcesLeft = processedResourceRange.length === resourcesInRows.length

    // keeps users and resources if they can be still combined: either they're not picked yet.
    const filtereResourceRange = noUsersLeft ? processedResourceRange.filter(r => !resourcesInRows.includes(r)) : processedResourceRange
    const filteredUserRange = noResourcesLeft ? processedUserRange.filter(u => !usersInRows.includes(u)) : processedUserRange

    return { filteredUserRange, filtereResourceRange }
}
