import { usePreload } from 'apprise-frontend-core/client/preload';
import { Optional } from 'apprise-frontend-core/utils/common';
import { useCategoryStore } from 'apprise-frontend-tags/category/store';
import { tagPlural, tagSingular } from 'apprise-frontend-tags/constants';
import { useExternalTagStore } from 'apprise-frontend-tags/external/store';
import { useCrud } from 'apprise-ui/utils/crudtask';
import { useContext } from 'react';
import { CategoryReference, TagCategory, useCategoryModel } from '../category/model';
import { tagapi, useTagCalls } from './calls';
import { Tag, TagReference, noRef, useTagModel } from './model';
import { TagCacheContext } from './provider';


export const useTagStore = () => {

    const state = useContext(TagCacheContext)


    const preload = usePreload()

    const external = useExternalTagStore()

    const crud = useCrud({ singular: tagSingular, plural: tagPlural })

    const model = useTagModel()
    const calls = useTagCalls()

    const catstore = useCategoryStore()
    const catmodel = useCategoryModel()

    const self = {


        allTags: () => state.get().all

        ,

        allTagsOf: (type: Optional<string>) => self.allTags().filter(t => t.type === type)

        ,

        allTagsOfCategory: (ref: CategoryReference | undefined, onlyactive = false) => self.allTags().filter(tag => tag.category === ref && (onlyactive ? tag.lifecycle.state === 'active' : true))

        ,

        lookupTag: (tag: Optional<TagReference>) => tag ? self.allTags().find(t => t.id === tag) ?? external.lookupById(tag)  : undefined

        ,

        safeLookupTag: (tag?: Optional<TagReference>) => self.lookupTag(tag) ?? model.noTag(tag)

        ,


        fetchAllTags: crud.fetchAllWith(async () => {

            const all =  await (preload.get<Tag[]>(tagapi) ?? calls.fetchAllTags())

            state.set(s => s.all = all)

            return all

        }).done()

        ,

        saveTag: crud.saveOneWith(async (tag: Tag) => {

            const isNew = !tag.lifecycle.created

            const saved = isNew ? await calls.addTag(tag) : await calls.updateTag(tag)

            state.set(s => s.all = isNew ? [saved, ...s.all] : s.all.map(t => t.id === saved.id ? saved : t))

            return saved

        }).done()

        ,

        removeTag: crud.removeOneWith(async (tag: Tag, onConfirm?: () => void) => {

            await calls.deleteTag(tag)

            state.set(s => s.all = s.all.filter(c => c.id !== tag.id))

            onConfirm?.()

        }).withConsent()

        ,



        //  groups all known tags by category, but - unless the field is to be explicitly ignored - 
        //  the tags of categories that don't map to fields are put in the no-category group.
        allTagsByCategory: (type: Optional<CategoryReference>, ignoreField?: boolean) => {

            // prelim indexing by category
            const catmap: { [key: string]: Tag[] } = self.allTagsOf(type)?.reduce((acc, next) => {

                const cat = next.category && (ignoreField || catstore.safeLookupCategory(next.category).properties.field?.enabled) ? next.category : noRef

                return { ...acc, [cat]: [next, ...(acc[cat] ?? [])] }

            }, {})


            return [...catstore.allCategoriesOf(type), catmodel.noCategory()].map(c => ({ category: c, categoryTags: catmap[c.id] }))

        }

        ,

        removeTagsInCategory: (category: TagCategory) => state.set(s => s.all = s.all.filter(tag => tag.category !== category.id))


    }

    return self;

}