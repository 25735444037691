import { useCall } from 'apprise-frontend-core/client/call'
import { usePreload } from 'apprise-frontend-core/client/preload'
import { Tag } from 'apprise-frontend-tags/tag/model'
import { tagType } from '../constants'

export const etagapi = "/etag"

export const useExternalTagCalls = () => {

    const call = useCall()

    const preload = usePreload()

   const self = {

        fetchTagsInCategory: (category : string) => preload.get<Tag[]>(`${etagapi}/${category}`) ?? call.at(`${etagapi}/${category}`,tagType).get<Tag[]>()

    }

    return self;

}
