import { createElement, Fragment, ReactNode, useEffect, useRef, useState } from "react"
import { useComponentBridge } from "./bridge"



// Returns some content or some other, usually a placeholder of sort, based on a condition.
// When the condition is not met, runs a task and reevaluates the condition.
// If the condition is still not met after running the task, optionall shows an error placeholder.
// The task may also run on demand, regardless of the condition.

// note: resets the state on all outcomes in order to restart again. as a side-effect, it expects the task to throw just once.
// if it throws multiple times - e.g. uses Promise.all() to parallelise subtask - it will restart on individual failures.
// and likely cause infinite loops. Use Promise.allSettled() to parallelise subtasks.

export type GuardProps = {

    /// the condition to render.
    when?: boolean

    // the content to render if the condition is met.
    render: ReactNode | (() => ReactNode)

    // the task to run if the condition is not met.
    orRun: () => any

    // the content to renderif the condition is not met.
    andRender?: ReactNode | ((_: TaskState) => ReactNode)

    // the content to render if the condition is not met and the task is not running.
    errorPlaceholder?: ReactNode | (() => ReactNode)

    debug?:boolean
}

export type GuardResult = {

    //  the content to render, based on the condition.
    content: JSX.Element


    //  indicates whether the task is running.
    running: boolean

    //  forces the task to run unconditionally. 
    force: () => void
}

export type TaskState = {

    running: boolean
    forced: boolean
}


export const useRenderGuard = (props: GuardProps): GuardResult => {

    const bridge = useComponentBridge()

    const [, tick] = useState<number>(0)

    const render = () => tick(i => ++i)

    const state = useRef<State>({ ...initialState })

    // keeps state on behalf of clients that guard only on mount until the task is completed.
    // this is the common use case. clients that want to control rendering, specify `when` condition and we back off.
    const [taskCompleted,taskCompletedSet] = useState(false)

    const { when: ready = taskCompleted, orRun: run, render: children, andRender: fallback = null, errorPlaceholder = bridge.placeholder.error } = props

    // captures reference values at the beginning of thos render cycle, so that they can be used in useEffect before they are changed in this cycle.
    const idle = state.current.phase === 'idle'

    const start = (state.current.override || !ready) && idle

    if (start)
        state.current.phase = 'running'

    const running = state.current.phase === 'running'
    const completed = state.current.phase === 'completed'

    const cancelled = useRef(false);

    // cleans up when client unmounts, so we bail out from renders.
    useEffect(() => () => {
        
        cancelled.current = true

    } , [])  // (no deps => runs only once => cleans up only once on unmount.


    // 
    const runref = useRef<typeof run>(undefined!)

    runref.current = run

    useEffect(() => {

        if (start)
            Promise.resolve(render)
                .then(runref.current)
                .finally(() => state.current = { ...initialState, phase: 'completed' })
                .then(()=>taskCompletedSet(true))
                .finally(() => {
                    
                    // mem mgmt: severs ref to task to exclude from closure.
                    runref.current = undefined!; 
                    
                    if (!cancelled.current)
                        render()
                })


    })

    const renderFallback = () => typeof fallback === 'function' ? fallback({ running, forced: state.current.override }) : fallback
    const renderChildren = () => typeof children === 'function' ? children() : children
    const renderError = () => typeof errorPlaceholder === 'function' ? errorPlaceholder() : errorPlaceholder

    const content: any = running ? renderFallback() : ready ? renderChildren() : renderError()

    const force = () => Promise.resolve(state.current = { ...initialState, override: true }).then(render)

    props.debug && console.log(state.current)

    //  resets after completion after showing error, or task will never have a chance to run again.
    if (completed)
        state.current = { ...initialState }

    return { content: content ?? createElement(Fragment, content), running, force }


}


type State = {

    phase: "idle" | "running" | "completed"
    override: boolean

}

const initialState: State = {

    phase: "idle",
    override: false
}