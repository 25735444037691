import { useT } from 'apprise-frontend-core/intl/language'
import { utils } from 'apprise-frontend-core/utils/common'
import { CategoryLabel } from 'apprise-frontend-tags/category/label'
import { CategoryReference, TagCategory } from 'apprise-frontend-tags/category/model'
import { CategoryIcon, TagIcon } from 'apprise-frontend-tags/constants'
import { Label } from 'apprise-ui/label/label'
import { OptionMenu, OptionMenuProps } from 'apprise-ui/optionmenu/optionmenu'
import { SelectBox } from 'apprise-ui/selectbox/selectbox'
import { ContextualFilterProps, useFilterState } from 'apprise-ui/utils/filter'
import { useCallback, useEffect, useMemo } from 'react'
import { TagLabel, TagTypeLabel } from './label'
import { Tag, Tagged, TagReference, useTagModel } from './model'
import { useTagModules } from './modules'
import { useTagStore } from './store'




export type TagFilterProps<T = any> = ContextualFilterProps & OptionMenuProps<T> & Partial<{

        key: string | number
        label?: React.ReactNode

        tagged?: T[]
        filtered: T[]

        excludeCategories?: string[]
        initiallyOpen?: boolean

}>

// works with Tagged objects, so knows how to extract tags.
export const useTagFilter = <T extends Tagged>(props: TagFilterProps<T>) =>

        useTagHolderFilter({ ...props, tagsOf: t => t.tags })



// works with generic items 
export type TagHolderFilterProps<T = any> = TagFilterProps & {

        tagsOf: (_: T) => string | string[] | undefined
}


export const useTagHolderFilter = <T extends any = any>(props: TagHolderFilterProps<T>) => {

        const t = useT()
        const store = useTagStore()
        const model = useTagModel()

        const defaultLabel = <Label icon={<CategoryIcon />} title={t('tag.plural')} />

        const { context, contextKey = 'tags', filtered = [], tagged = filtered, tagsOf, label = defaultLabel, initiallyOpen, excludeCategories = [], ...rest } = props

        // options are distinct tags in population (iif not in excluded cats).
        const distinctTagIds = useMemo(() => {

                const alltags = tagged.flatMap(r => tagsOf(r) ?? [])

                        .filter((v, i, a) => a.indexOf(v) === i)

                        // we want to keep working with unresolved ids, so we use them as is.
                        // (safeLookup would replace them instead with the 'unknown' identifier, but this would break our filter match.)
                        .map(id => store.lookupTag(id) ?? { ...model.noTag(id), id })

                        .filter(tag => tag.lifecycle.state !== 'inactive' && (tag?.category === undefined || !excludeCategories.includes(tag.category)))

                alltags.sort(model.comparator)

                return alltags.map(t => t.id)
        }
                // eslint-disable-next-line
                , [tagged])                             // recompute on data change.


        const filter = useFilterState<{ active: string[], open: boolean, options: string[] }>(context)


        const state = filter.get(contextKey) ?? { active: distinctTagIds, open: initiallyOpen, options: distinctTagIds }

        const { active, open } = state

        /// reset selection if options changes since mounted (because population changes) .

        useEffect(() => {

                if (!utils().deepequals(state.options, distinctTagIds))
                        filter.reset()

                // eslint-disable-next-line
        }, [distinctTagIds])

        // console.log({ contextKey, distinctTagsIds, storeoptions: state.options, active })



        ////////////////////////////////////////////////////////////////////////////////////

        // on mount, keep only active that are still active. 
        // useEffect(() => {

        //         const purged = active.filter(t => store.safeLookupTag(t).lifecycle.state !== 'inactive')

        //         filter.set(contextKey)({ ...state, active: purged.length ? purged : undefined! })

        // // eslint-disable-next-line
        // }, [])

        ///////////////////////////////////////////////////////////////////////////////////


        // filter function: includes elements with no tags or that have at least one tag.
        const tagfilter = useCallback(

                (o: T) => (tagsOf(o) ?? []).length === 0 || active.some(t => tagsOf(o)?.includes(t))


                // eslint-disable-next-line
                , [active])  // recompute when selection changes


        const setActive = (active: string[]) => filter.set(contextKey)({ ...state, active })
        const setOpen = (open: boolean) => filter.set(contextKey)({ ...state, open })

        const TagFilter = (

                <OptionMenu key={props.key} height={200} active={(active ?? [])} setActive={setActive} open={open} onChange={setOpen} label={label} {...rest}>
                        {distinctTagIds.map(tag =>

                                <OptionMenu.Option key={tag} value={tag} title={<TagLabel noIcon bare noTip tag={tag} />}

                                />
                        )}
                </OptionMenu>
        )



        // filtered data
        const tagFilteredData = useMemo(

                () => filtered.filter(tagfilter),

                [filtered, tagfilter])    // recompute on data or filter change


        return { TagFilter, tagfilter, tagFilteredData, active, open, setActive, setOpen, }
}


export const useTagTypeFilters = (type: string) => {




}


export type TagTypeFilterProps<S extends Tag | TagCategory> = ContextualFilterProps & {

        categories: S[]
}


export const useTagTypeFilter = <S extends Tag | TagCategory>(props: TagTypeFilterProps<S>) => {

        const { categories, context, contextKey = 'tagtype' } = props
        const modules = useTagModules()
        const t = useT()

        const { get, set } = useFilterState<string | undefined>(context)

        const selected = get(contextKey)

        const allTypes = modules.all().map(m => m.type)

        const typeFilter = allTypes.length < 2 ? null :

                <SelectBox
                        width={150}
                        placeholder={<Label icon={<CategoryIcon />} title={t('tag.all_types')} />} placeholderAsOption
                        onChange={set(contextKey)}
                        options={allTypes}
                        render={type => <TagTypeLabel bare noTip type={type} />}>
                        {selected}
                </SelectBox >


        const typeFiltered = useMemo(() => categories.filter(c => !selected || c.type === selected), [categories, selected])

        return { typeFilter, typeFiltered, selectedType: selected, selectType: set(contextKey) }



}


export type MultiTagFilterProps<T = any> = TagFilterProps & {

        tagsOf: (_: T) => string[] | undefined
}

export const useMultiTagFilter = <T extends any = any>(props: MultiTagFilterProps<T>) => {

        const store = useTagStore()
        const model = useTagModel()

        const { context, contextKey = 'multitags', filtered = [], tagged = filtered, tagsOf, initiallyOpen, excludeCategories = [], ...rest } = props

        const mapAndFilter = (tags: TagReference[]) =>

                // we want to keep working with unresolved ids, so we use them as is.
                // (safeLookup would replace them instead with the 'unknown' identifier, but this would break our filter match.)
                tags.map(id => store.lookupTag(id) ?? { ...model.noTag(id), id })

                        .filter(tag => tag.lifecycle.state !== 'inactive' && tag.category && !excludeCategories.includes(tag.category))

        // // options are distinct tags in population (iif not in excluded cats).
        const distinctTagIds = useMemo(() => {

                const alltags = mapAndFilter(utils().dedup(tagged.flatMap(r => tagsOf(r) ?? [])))

                alltags.sort(model.comparator)

                const map = utils().index(alltags).byGroup(t => t.category)

                return Object.keys(map).sort().reduce((obj, key) => ({ ...obj, [key]: map[key]?.map(t => t.id) }), {} as Record<CategoryReference, string[]>)
        }
                // eslint-disable-next-line
                , [tagged])                             // recompute on data change.


        const categories = Object.keys(distinctTagIds)

        const filter = useFilterState<{
                active: Record<CategoryReference, TagReference[]>,
                open: Record<CategoryReference, boolean>,
                options: Record<CategoryReference, TagReference[]>
        }>(context)


        const state = filter.get(contextKey) ?? { active: distinctTagIds, open: {}, options: distinctTagIds }

        const { active, open } = state

        // /// reset selection if options changes since mounted (because population changes) .

        useEffect(() => {

                if (!utils().deepequals(state.options, distinctTagIds))
                        filter.reset()

                // eslint-disable-next-line
        }, [distinctTagIds])

        //console.log({ contextKey, distinctTagsIds, storeoptions: state.options, active })


        // // filter function: includes elements with no tags or that have at least one tag.
        const tagfilter = useCallback(

                (o: T) => {

                        const tagmap = utils().index(mapAndFilter(tagsOf(o) ?? [])).mappingByGroup(t => t.category, t => t.id)

                        return Object.entries(active).reduce((acc, [category, activeForCategory]) => acc && (!tagmap[category]?.length || activeForCategory.some(t => tagmap[category].includes(t))), true as boolean)

                }


                // eslint-disable-next-line
                , [active])  // recompute when selection changes


        const setActive = (category: CategoryReference, active: TagReference[]) => filter.set(contextKey)({ ...state, active: { ...state.active, [category]: active } })
        const setOpen = (category: CategoryReference, open: boolean) => filter.set(contextKey)({ ...state, open: { ...state.open, [category]: open } })

        const filters = categories.reduce((acc, category) => ({
                ...acc,
                [category]: <OptionMenu height={200} active={(active[category] ?? [])} setActive={active => setActive(category, active)} open={open[category]} onChange={open => setOpen(category, open)} label={<CategoryLabel icon={<TagIcon />} bare category={category} />} {...rest}>
                        {distinctTagIds[category].map(tag =>
                                <OptionMenu.Option key={tag} value={tag} title={<TagLabel noIcon bare noTip tag={tag} />} />
                        )}
                </OptionMenu>

        }), {} as Record<CategoryReference, JSX.Element>)



        // // filtered data
        const tagFilteredData = useMemo(

                () => filtered.filter(tagfilter),

                [filtered, tagfilter])    // recompute on data or filter change


        return { filters, tagfilter, tagFilteredData, active, open, setActive, setOpen, }
}