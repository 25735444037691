import { useT } from 'apprise-frontend-core/intl/language'
import { utils } from 'apprise-frontend-core/utils/common'
import { Button } from 'apprise-ui/button/button'
import * as React from 'react'
import { MdOutlineFlipToBack, MdOutlineFlipToFront } from 'react-icons/md'
import { classname, Tall, Wide } from "../component/model"
import { ChangeTracked, Fielded, Uncontrolled } from './model'
import './styles.scss'




export const PastModeIcon = MdOutlineFlipToFront
export const PresentModeIcon = MdOutlineFlipToBack

export const useChangeHelper = <S extends Fielded<T> & ChangeTracked<T> & Uncontrolled & Wide & Tall, T = any>(props: S, $?: Partial<{ 
    
    props: S, 
    pastValue: T,
    currentValue: T,
   

}>) => {

    const t = useT()

    const { trackChange, label, labelDecorations = [] } = props

   
    // for regular scenarios, we can infer stuff: children have type T and that's our current value.
    // outliers and special cases must pass it in.

    const { pastValue = props.pastValue, currentValue = (props?.children as T || undefined) ?? props.defaultValue  } = $ ?? {}
   
    const initial = React.useRef(currentValue)

    const previous = pastValue ?? initial.current

    const hasChanged = trackChange && !utils().deepequals(previous, currentValue)
  
    const [pastMode, pastModeSet] = React.useState(false)

    if (!props.horizontal && hasChanged) {

        const flipIcon = pastMode ? <PastModeIcon /> : <PresentModeIcon />
        const flipTip = pastMode ? t('This value has changed.\nClick to see latest.') : t('This value has changed.\nClick to see original.')
        const changeButton = <Button tip={flipTip} tipDelay={.8} noReadonly className='change-decoration' type='ghost' size='small' onClick={() => pastModeSet(s => !s)}>{flipIcon}</Button>
        labelDecorations.unshift(changeButton)
        props.className = classname(props.className, pastMode ? 'apprise-field-pastmode' : 'apprise-field-currentmode')

    }

    props.readonly = props.readonly || pastMode
    props.canUnlock = props.canUnlock && !pastMode

    props.debug && console.log("change-helper",{ label, initial: initial.current, pastValue, currentValue, hasChanged })

    return { pastMode, pastValue: previous }


}