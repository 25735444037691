import { useT } from 'apprise-frontend-core/intl/language';
import { useForm } from 'apprise-frontend-core/utils/form';
import { Button, ButtonRow } from 'apprise-ui/button/button';
import { Form } from 'apprise-ui/form/form';
import { GroupBox } from 'apprise-ui/groupbox/groupbox';
import { RouteGuard } from 'apprise-ui/link/routeguard';
import { Topbar } from 'apprise-ui/topbar/topbar';
import { Explainer } from 'apprise-ui/utils/explainer';
import producer from "immer";
import React from 'react';
import { CategoryLabel } from './label';
import { TagCategory, useCategoryModel } from './model';
import { useCategoryStore } from './store';

export type ReorderProps = React.PropsWithChildren<{

  type: string
  name: string

}>

export const Reorder = (props: ReorderProps) => {

  const t = useT();

  const store = useCategoryStore();
  const { ordinalComparator } = useCategoryModel()

  const { type, name } = props

  const ordered = store.allCategoriesOf(type).sort(ordinalComparator)

  const form = useForm(ordered)

  // assigns ordinals, saves and resets form.
  const saveCategories = () => Promise.resolve(form.edited.map(updateOrdinal)).then(store.saveCategories).then(saved => form.reset.to(saved).quietly())
 
  // (functionally) updates the field ordinal of a category (using an immer proxy for convenience).
  const updateOrdinal  = (category: TagCategory, i:number) => producer( (m: TagCategory) => { 
    
    m.properties.field.ordinal=i}
    
  )(category)

  const revertBtn = <Button enabled={form.dirty} onClick={form.reset.toInitial.confirm}>{t('tag.category_reorder_revert')}</Button>

  const saveBtn = <Button type='primary' enabled={form.dirty} onClick={saveCategories}>{t('tag.category_reorder_save')}</Button>

  const renderCategory = (category: TagCategory) => {

    const enabled = !!category.properties.field.enabled 

    return <GroupBox.Card dot key={category.id}>
      <CategoryLabel tip={enabled? null : t('tag.category_reorder_card_disabled_tip')} enabled={enabled} category={category} />
    </GroupBox.Card>
  }

  return <>

    <Topbar>
      <ButtonRow>
        {saveBtn}
        {revertBtn}
      </ButtonRow>
    </Topbar>

    <Explainer>{t('tag.category_reorder_explainer', { name })}</Explainer>

    <Form>

      <GroupBox noAdd noRemove
        render={renderCategory}
        onChange={categories => form.set.to(categories!)}>

        {form.edited}

      </GroupBox>

    </Form>

    <RouteGuard when={form.dirty} />


  </>

}