import * as React from 'react'


export const RoutableTargetContext = React.createContext<TargetProps>({
    include:'all',
    exclude:[]
})

type TargetProps = Partial<{

    include: 'all' | string[]
    exclude: 'all' | string[]
}>

type Props = React.PropsWithChildren<TargetProps>

export const RoutableTargets = (props:Props) => {

    const {include='all',exclude=[], children} = props

    const value = React.useMemo( () => ({include,exclude}),[include,exclude])

    return  <RoutableTargetContext.Provider value={value}>
                {children}
            </RoutableTargetContext.Provider>

}
