import { useMultilang } from 'apprise-frontend-core/intl/multilang'
import { noop, useDebounced } from 'apprise-frontend-core/utils/function'
import { Component, useComponentProps } from 'apprise-ui/component/component'
import * as React from 'react'
import { MdOutlineFlipToBack, MdOutlineFlipToFront } from 'react-icons/md'
import { classname, Tall, Wide } from "../component/model"
import { Fielded, Uncontrolled } from './model'
import './styles.scss'



export const PastModeIcon = MdOutlineFlipToFront
export const PresentModeIcon = MdOutlineFlipToBack

export type FieldProps<T> = React.PropsWithChildren<Fielded<T> & Partial<{

    name: string,

}>>

export const FieldContext = React.createContext<Fielded<any>>(undefined!)

export const Field = <T extends any>(props: FieldProps<T>) => {

    const ml = useMultilang()

    const fieldprops = useFieldProps(props)

    const {
        name,
        className,
        horizontal,
        centered,
        label,
        labelDecorations = [],
        focusDecorations = [],
        status,
        help,
        useHelp,
        msg,
        forceinfo,
        children } = fieldprops


    const classes = [
        className,        // client class
        `field-${status}`,
        `field-${horizontal ? 'horizontal' : centered ? 'centered' : 'vertical'}`,
        useHelp ? 'field-with-help' : undefined
    ]

    const lbl = ml.test(label) ? ml.l(label) : label

    const decorations = [

        ...[lbl, ...labelDecorations].map((d, i) => <div key={`decoration-${i}`} className='field-label-item'>{d}</div>),
        ...focusDecorations.map((d, i) => <div key={`silent-decoration-${i}`} className='field-label-item label-silent-item'>{d}</div>)
    ]

    return <Component {...fieldprops} name={name} className={classname('apprise-field', ...classes)} >

        <div className={'field-main'}>

            {(label || forceinfo) &&
                <div className='field-main-lbl'>{decorations}</div>
            }
            <div className='field-main-input-grp'>
                <div className='field-main-input'>
                    {children}
                </div>
                {(msg || forceinfo) &&
                    <div className='field-main-message'>{ml.test(msg) ? ml.l(msg) : msg}</div>
                }
            </div>
        </div>

        {(help || forceinfo) &&
            <div className='field-help'>
                {ml.test(help) ? ml.l(help) : help}
            </div>
        }

    </Component>

}

export type AdditionalProperties = {

    controlled: boolean,
    useHelp: boolean,
    debounced: boolean
    cancelDebouncedChange: () => void
    flushDebouncedChange: () => any
}



// transforms field properties setting defaults from context, and deriving other properties.
export const useFieldProps = <S extends Fielded<T> & Uncontrolled & Wide & Tall, T = any>(props: S): S & AdditionalProperties => {

    // field field defaults from context.
    const contextualDefaults = React.useContext(FieldContext)


    // overlay actual props on them.
    const defaultedprops = { ...contextualDefaults, ...props }

    const {

        info: { status: s = 'default', label: l, labelDecorations: ld = [], focusDecorations: fd = [], msg: m, help: h } = {},
        label = l, labelDecorations = ld, focusDecorations = fd, status = s, help = h, msg = m,
        noTip,
        delay:
        clientDelay = 0,
        uncontrolled,
        forceinfo,
        onChange,
        width, minWidth,
        height,minHeight 
    } = defaultedprops

    // parse delay
    const delay = typeof clientDelay === 'boolean' ? 100 : clientDelay

    // help is active is we're in a vertical context and there's something to write or info is forced and not disably.
    const useHelp = (!!help || !!forceinfo) && !contextualDefaults?.horizontal

    // computes styles shortcuts
    const innerStyle = {  ...defaultedprops.innerStyle, 
        minWidth: minWidth ?? defaultedprops.innerStyle?.minWidth,
        width: width ?? defaultedprops.innerStyle?.width, 
        minHeight: minHeight ?? defaultedprops.innerStyle?.minHeight,
        height: height ?? defaultedprops.innerStyle?.height 
    }

    // add also general component props for the convenience of a single destructuring.
    const componentprops = useComponentProps(defaultedprops)

    const { disabled } = componentprops

    const controlled = !!onChange && !uncontrolled

    const debounced = controlled && delay > 0

    // creates a debounced version of onChange that doesn't become stale.
    const debouncedOnChange = useDebounced(onChange, delay)

    const _onChange = disabled ? noop : controlled && delay > 0 ? debouncedOnChange : onChange

    const derivedprops = {

        label, status, msg, labelDecorations, focusDecorations,
        help,
        innerStyle,
        // show help if there's some, or if the form/context says fields are vertical
        useHelp,
        delay,
        controlled,
        noTip: useHelp || noTip,
        onChange: _onChange,
        rawOnChange: disabled ? noop : onChange,
        debounced,
        cancelDebouncedChange: debouncedOnChange.cancel,
        flushDebouncedChange: debouncedOnChange.flush,

    }


    // cancel any pending changes on unmount.
    React.useEffect(() => {

        debouncedOnChange.cancel()

        // eslint-disable-next-line
    }, [])

    return { ...defaultedprops, ...componentprops, ...derivedprops }

}