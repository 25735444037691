

import { useT } from 'apprise-frontend-core/intl/language'
import { Component } from 'apprise-ui/component/component'
import { Disabled, Styled } from 'apprise-ui/component/model'
import { Readonly } from 'apprise-ui/readonly/model'
import { useReadonlyProps } from 'apprise-ui/readonly/readonly'
import { Tipped } from 'apprise-ui/tooltip/model'
import { IconBaseProps } from 'react-icons'
import { AiFillSave, AiOutlineBars, AiOutlineCloseCircle, AiOutlineCopy, AiOutlineDown, AiOutlineDownload, AiOutlineEdit, AiOutlineFlag, AiOutlineLeft, AiOutlineLoading, AiOutlineLock, AiOutlineMinus, AiOutlineMinusCircle, AiOutlineMore, AiOutlineNumber, AiOutlinePlus, AiOutlineQuestionCircle, AiOutlineRight, AiOutlineSave, AiOutlineSearch, AiOutlineStop, AiOutlineUndo, AiOutlineUnlock, AiOutlineUpload } from "react-icons/ai"
import { BiAlarmExclamation, BiLinkExternal, BiText } from 'react-icons/bi'
import { BsInfoCircle } from 'react-icons/bs'
import { FiAperture, FiMenu } from 'react-icons/fi'
import { GoDotFill } from "react-icons/go"
import { MdOutlineHelp, MdTimer } from 'react-icons/md'
import { SiDevdotto } from "react-icons/si"

import "./icons.scss"

//  shared icons, for a simple form of theming. 

export const AddIcon = AiOutlinePlus
export const CloneIcon = AiOutlineCopy
export const DateIcon = MdTimer
export const DotIcon = GoDotFill
export const DownIcon = AiOutlineDown
export const EditIcon = AiOutlineEdit
export const TextIcon = BiText
export const GenericIcon = FiAperture
export const InactiveIcon = AiOutlineStop
export const InfoIcon = BsInfoCircle
export const LanguageIcon = AiOutlineFlag
export const ListIcon = AiOutlineBars
export const LockIcon = AiOutlineLock
export const UnlockIcon = AiOutlineUnlock
export const MenuIcon = FiMenu
export const MoreIcon = AiOutlineMore
export const OpenIcon = BiLinkExternal
export const CloseIcon = AiOutlineCloseCircle
export const RemoveIcon = AiOutlineMinus
export const RemoveItemIcon = AiOutlineMinusCircle
export const SaveIcon = AiOutlineSave
export const SaveIconFull = AiFillSave
export const SearchIcon = AiOutlineSearch
export const RevertIcon = AiOutlineUndo
export const UnknownIcon = AiOutlineQuestionCircle
export const DownloadIcon = AiOutlineDownload
export const UploadIcon = AiOutlineUpload
export const LeftIcon = AiOutlineLeft
export const RightIcon = AiOutlineRight
export const NumberIcon = AiOutlineNumber
export const HelpIcon = MdOutlineHelp
export const LoadIcon = (props: IconBaseProps) => <AiOutlineLoading className='icon-spin' {...props} />


export const FileIcon = (props: IconBaseProps) => <AiOutlineLoading {...props} />

export const AllertIcon = ({color='orange',...rest}: IconBaseProps) => <BiAlarmExclamation color={color} {...rest} />


export const DevOnlyIcon = (props:IconBaseProps) =>  {
    
    const {color='lightseagreen', size=22, ...rest} = props

     return <SiDevdotto size={size} color={color} {...rest} />

}


export const IconReadonly = (props: Partial<Tipped & Styled & Disabled & Readonly & Pick<IconBaseProps,'color'>>) => {

    const t = useT()

    const { readonly, color='orange' } = useReadonlyProps(props)

    return readonly ? <Component name="readonly" tip={t("ui.readonly")} {...props}><LockIcon color={color} /></Component> : null

}

export const IconInactive = (props: Partial<Tipped & Styled>) => {

    const t = useT()

    return <Component name="inactive" {...props} tip={t("ui.inactive")}><InactiveIcon /></Component>
}

export const IconProtected = (props: Partial<Tipped & Styled>) => {

    const t = useT()

    return <Component name="protected" {...props} tip={t("ui.protected")}><LockIcon /></Component>
}