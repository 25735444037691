import { useT } from 'apprise-frontend-core/intl/language'
import { Button } from 'apprise-ui/button/button'
import { useComponentProps } from 'apprise-ui/component/component'
import { classname, Debugged } from 'apprise-ui/component/model'
import { ControlledReadonly } from 'apprise-ui/readonly/model'
import { useControlledReadonlyProps } from 'apprise-ui/readonly/readonly'
import { LockIcon, UnlockIcon } from 'apprise-ui/utils/icons'
import React from 'react'
import { Fielded } from './model'


export const useReadonlyHelper = (props: Partial<Fielded & ControlledReadonly>) => {

    const { disabled } = useComponentProps(props)

    const { readonly, canUnlock, readonlyLocked, readonlyLockedSet, focusDecorations = [] } = useControlledReadonlyProps(props)

    const readonlyOrUnlocked = readonly || !readonlyLocked

    const toggle = () => readonlyLockedSet(!readonlyLocked)

    const readonlyButton = <ReadonlyButton canUnlock={!!canUnlock} locked={readonlyLocked} toggleLock={toggle} />

    const show = readonlyOrUnlocked && !disabled

    if (show)
        focusDecorations.unshift(readonlyButton)

    props.readonly = readonly

    if (readonly)
        props.className = classname(props.className, `apprise-readonly`)

    props.debug && console.log("readonly-helper",{readonly,show,canUnlock,readonlyLocked})
}


type Props = Debugged & {

    canUnlock: boolean
    locked: boolean
    toggleLock: () => any
}

const ReadonlyButton = (props: Props) => {

    const { canUnlock, locked, toggleLock } = props

    const classes = classname('suffix-readonly-btn', canUnlock && (locked ? 'btn-locked' : 'btn-unlocked'))

    //console.log(props)
    props.debug && console.log("readonly-helper", { canUnlock, locked })

    // removing this for now as tooltips can dangle for mis-interaction with show/hide-on-over
    const t = useT()
    const tip = t(canUnlock ? locked ? 'ui.readonly_unlock' : 'ui.readonly_lock' : 'ui.readonly_locked')


    return <Button enabled={canUnlock} innerClassName={classes} noReadonly tipDelay={1.5} tipPlacement='bottomRight' tip={tip} type='ghost' onClick={toggleLock}>
        {locked || !canUnlock ? <LockIcon /> : <UnlockIcon />}
    </Button>

}