
import { useT } from 'apprise-frontend-core/intl/language'
import { defaultParseOptions, ParseContext, ParseIssue, Resource, ResourceParser, ResourceRef } from './model'



// all the data produced by parsing multiple resources, along with blocking errors and non-blocking issues.
export type ParseOutcome<T> = {

    data: T[],

    issues: Record<ResourceRef, ParseIssue[]>

}


// this feeds multiple rsources ot a parser and aggregates the outcomes.
// it merges the data and indexes the issues by resource.
export const useMultiParser = <T,S={}>(parse: ResourceParser<T,S>) => {

    const t = useT()

    return async (resources: Resource[], ctx = defaultParseOptions as ParseContext<S>) => {

        // 1. parses all bytestreamss bound to resources concurrently. 
        //    indexes all future outcomes by their stream id.
        //    converts failures or empty outcomes into parsing issues.

        const parseTasks = resources.map((resource =>

            parse?.(resource, ctx).then(outcome => {

                // adds an additional issue for "empty" files.
                if (outcome.data.length === 0 && !ctx.emptyFileIssue)
                    outcome.issues.push({ message: t('parse.empty_resource', { file: resource.name }), type: 'error' })

                return { resource: resource.id, outcome }

            })
                // exploded: adds a single issue derived from error.
                .catch(error => {

                    const issues = [{ message: error.message ?? t('parse.unspecified_error'), type: 'error' } as ParseIssue]

                    return { resource: resource.id, outcome: { data: [], issues } } // an error produces no data: streamlines post-processing.

                })

        ))
            .filter(o => !!o)

        //  2. wait for all parsing outcomes to accumulate all their records and issues.
        

        return Promise.all(parseTasks).then(outcomes => {

            const outcome = { data: [], issues: {} } as ParseOutcome<T>

            return outcomes.reduce(

                (acc, { outcome, resource }) => {

                    // accumulate errors, if any.
                    if (outcome.data?.length)
                        acc.data.push(...outcome.data)

                    // accumulate issues, if any.
                    if (outcome.issues?.length)
                        acc.issues[resource] = outcome.issues

                    return acc

                }

                , outcome)

        })


    }
}