import { useLogin } from 'apprise-frontend-iam/login/logged';
import { useUserCalls } from 'apprise-frontend-iam/user/calls';
import { User } from 'apprise-frontend-iam/user/model';
import { useCrud } from 'apprise-ui/utils/crudtask';
import { useLoggedUserCalls } from './calls';
import { useGuestUser } from './constants';



export const useLoggedStore = () => {

    const login = useLogin()
    const calls = useLoggedUserCalls()
    const users = useUserCalls()
    const crud = useCrud({ singular: 'user.singular' })

    const guest = useGuestUser()

    const updateTask = crud.saveOneWith(async (logged: User) =>

        login.login(await users.updateProfile(logged))

    )
    const self = {

        fetchLoggeduser: crud.fetchOneWith(async () => {

            // in development, guest access can only be based on path.
            // so when we get a 401, we login a guest user and set a public prefix for all future calls.
            const fetch = !login.isGuestAllowed() ? calls.fetchLoggedUser : async () => {

                try {

                    return await calls.fetchLoggedUser()

                }
                catch (error: any) {

                    if (error.response?.status === 401 || error.response?.status === 403) {

                        return guest

                    }
                    else
                        throw error
                }


            }

            login.login(login.isAuthenticated() ? await fetch() : login.lastLogged() ?? await fetch())


        })
            .with(config =>

                config.show('user.load_logged').error("user.load_logged_error")

            )
            .done()



        ,

        // optionally takes task configurer callback.
        update: updateTask.with(config => {

            config.show('user.updating_profile')

        })
            .done()

        ,

        updateTask




    }

    return self


}


