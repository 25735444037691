import { Multilang, useMultilang } from 'apprise-frontend-core/intl/multilang'
import { Optional, elementProxyRole, utils } from 'apprise-frontend-core/utils/common'
import { useStable } from 'apprise-frontend-core/utils/function'
import { classname } from 'apprise-ui/component/model'
import { InactiveIcon } from 'apprise-ui/utils/icons'
import React from 'react'
import { IconBaseProps } from 'react-icons/lib'
import { Column, ColumnProps, TableElement } from './table'



export const useTableUtils = <T extends TableElement>() => {

    const common = utils()
    const ml = useMultilang()

    const self = {

        compareStringsOf: (f: (_: T) => string | undefined) => common.compareStringsOf<T>(f)

        ,

        compareNumbersOf: (f: (_: T) => number) => common.compareNumbersOf<T>(f)

        ,


        compareDatesOf: (f: (_: T) => string | number | undefined) => common.compareDatesOf<T>(f)

        ,


        compareMultilangOf: (f: (_: T) => Multilang | undefined) => (t1,t2) => ml.comparator(f(t1),f(t2))

        ,

        applyComparator: <S extends any>(comparator: (s1: Optional<S>, s2: Optional<S>) => number) => ({

            to: (f: (_: T) => Optional<S>) => (t1: T, t2: T) => comparator(f(t1), f(t2))

        })

        ,


        EmptyCell: (props: IconBaseProps) => <InactiveIcon color='lightgray' {...props} className={classname('emptycell', props.className)} />

        ,

        // a column that fixes its type.
        Column: useStable((props: Omit<ColumnProps<T, T>, 'path'>) => {

            return <Column {...props} />

        }, { [elementProxyRole]: { value: true}, column: { value: true } })

        ,

        columnsOf: (..._: (JSX.Element | 0 | boolean | undefined | null)[]) => _.filter(c => !!c).map(c => c as JSX.Element).map((c, i) => React.cloneElement(c, { key: c.props.name ?? i }))

    }

    return self
}