import { useCall } from 'apprise-frontend-core/client/call'
import { Account } from './model'

const svc = "admin"
export const accountApi = `/account`

export const useAccountCalls = () => {

    const call = useCall()


    const self = {

        fetchAccount: (username: string) => call.at(`${accountApi}/${username}`, svc).get<Account>()

        ,

        createAccount: (account: Account) => call.at(`${accountApi}`, svc).post<Account>(account)

        ,

        updateAccount: (account: Account) => call.at(`${accountApi}/${account.username}`, svc).put<Account>(account)


        ,

        deleteAccount: (username: string) => call.at(`${accountApi}/${username}`, svc).delete()

        ,

        verifyAccount: (username: string) => call.at(`${accountApi}/${username}/verify`, svc).post<Account>()

        ,

        activateAccount: (username: string) => call.at(`${accountApi}/${username}/activate`, svc).post<Account>()

        ,

        deactivateAccount: (username: string) => call.at(`${accountApi}/${username}/suspend`, svc).post<Account>()

        ,

        deactivateAccounts: (usernames: string[]) => call.at(`${accountApi}/suspend`, svc).post<string[]>(usernames)

        ,

        resetAccount: (username: string) => call.at(`${accountApi}/${username}/reset`, svc).post<Account>()

    }

    return self
}