
import { useRouting } from 'apprise-ui/utils/routing';
import { useContext } from 'react';
import { alltagsTabs } from '../list';
import { Tag, useTagModel } from './model';
import { TagRoutingContext, TagRoutingState } from './provider';

export const tagSystemRoute = "/tags"


export const useTagRouting = () => {

    const { routeTo } = useRouting()

    const state = useContext(TagRoutingContext)

    const model = useTagModel()

    const self = {

        tagListRoute: () => `${tagSystemRoute}?tab=${alltagsTabs}`

        ,


        tagDetailRoute: (id?: string) => id ? `${tagSystemRoute}/${id}` : self.newTagRoute()

        ,


        newTagRoute: () => `${tagSystemRoute}/new`

        ,

        routeToTag: (tag: Tag | string) => routeTo(self.tagDetailRoute(typeof tag === 'string' ? tag : tag.id))

        ,

        routeToNewTag: (directives: Partial<TagRoutingState> = {}) => Promise.resolve(state.reset(directives)).then(() => routeTo(self.newTagRoute())) 
      
        ,

        routeToTagList: () => routeTo(self.tagListRoute())

        ,

        nextTag: () => state.get().nextTag ?? model.newTag(undefined!)


    }

    return self

}

