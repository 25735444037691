
import { createContext, useContext, useEffect, useState } from 'react';
import { ControlledReadonly, Readonly } from './model';


export const ReadonlyContext = createContext(false);

export type CauseContextState = {
    cause: string | undefined

}

export type ReadOnlyProps = {

    value: boolean
    noDelegate?: boolean

}

export const ReadOnly = (props: React.PropsWithChildren<ReadOnlyProps>) => {

    const { children, value, noDelegate } = props

    const inherited = useContext(ReadonlyContext) && !noDelegate

    return <ReadonlyContext.Provider value={value || inherited}>
            {children}
    </ReadonlyContext.Provider>
}


/** 
 * takes into account context and process properties to conclude the client is readonly.
 **/
export const useReadonlyProps = <T extends Readonly>(props: T): T => useDeferredReadonlyProps().getReadOnlyProps(props)


/** 
 *  return a function that takes into account context and process properties to conclude the client is readonly.
 *  the function can be used ahead of conditionals and loops where hooks cannot be invoked.
 **/
export const useDeferredReadonlyProps = () => {

    const readonlyContext = useContext(ReadonlyContext)

    const getReadOnlyProps = <T extends Readonly>(props: T): T => {

        const { readonly, noReadonly } = props

        // context directives, without an override for the client.
        const contextIsReadonly = readonlyContext && !noReadonly

        // readonly if explicitly or contextually so.
        const isReadonly = readonly || contextIsReadonly

        return { ...props, readonly: isReadonly }

    }

    return { getReadOnlyProps }
}


type DerivedProps = {

    readonlyLocked: boolean
    readonlyLockedSet: (_: boolean) => any
}

/** 
 *  keeps the locked state of a readonly client and uses it to overrides the 'readonly' prop.
 *  also exposes the state in a new prop for clients that need to know when they are unlocked or simply not readonly. 
 **/
export const useControlledReadonlyProps = <T extends ControlledReadonly>(props: T): T & DerivedProps => {

    const { readonly } = useReadonlyProps(props)

    const [locked, lockedSet] = useState(true)

    // returns to locked as and if readonly changes
    useEffect(() => {

        lockedSet(true)

    }, [readonly])

    // overrides readonly: must also be locked.
    // if it'snt locke,d then acts as if this weren't readnly.
    const temporarilyReadonly = readonly && locked

    return { ...props, readonly: temporarilyReadonly, readonlyLocked: locked, readonlyLockedSet: lockedSet }

}

