import { mocks } from 'apprise-frontend-core/utils/mock';


export type Lifecycle<T=any> = Partial<{

    state: T
    previousState: T,
    created: number,
    lastModified: number,
    lastModifiedBy: string

}>

export type Lifecycled<T=any> = {

    lifecycle: Lifecycle<T>
}

export const useLifecycleMocks =()=>{


    const self = {

        pastLifecycle : <E>(state?: E): Lifecycle<E> => {

            const created = mocks.randomDateSince(new Date(2019, 0, 1))
            const lastModified = new Date(Number(created))
            mocks.randomDateSince(created)
        
            return {
                state,
                created: created.getTime(),
                lastModified: lastModified.getTime(),
                lastModifiedBy: 'apprise-support'
            }
        }
    }

    return self;
   
}