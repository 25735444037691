import { useContext } from 'react'
import { ClientContext } from './state'



export type Service = {

    prefix: string
    label: string
    default?: boolean

}

export const useService = () => {


    const state = useContext(ClientContext)

    const self = {


        get: (name: string): Service => {

            const config = state.get().config

            return config.services[name] ?? self.default()

        },

        default: (): Service => {

            const config = state.get().config

            for (const k in config.services)
                if (config.services[k].default)
                    return config.services[k]


            const keys = Object.keys(config.services)

            if (keys.length === 1)
                return config.services[keys[0]]

            throw new Error("no default service!");

        }


    }

    return self
}