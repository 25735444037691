
// uses the platform to returns current location, even in closures.
// (router doesn't seem to offer a way to do this.)
// but removes prefix, like the router would.
export const useCurrentLocation = () => {

    const current = () => {

        const { pathname, search, hash } = location

        return { pathname: `/${pathname.split("/").slice(2).join("/")}`, search, hash }
    }

    return { current }


}