import { InfosysConfig } from '#config';
import { useIndicatorParser, useOperationParser } from '#operation/parser';
import { useConfig } from 'apprise-frontend-core/config/api';
import { useMultiParser } from 'apprise-frontend-parse/parser';
import { useDefaultModelParser, useDefaultParser } from 'apprise-frontend-parse/resource';
import { useDefaultBookParser } from 'apprise-frontend-parse/workbook';
import { Submission } from './model';

export const operationRowType = 'op'


export const useSubmissionParser = (submission: Submission) => {

    const modelparser = useDefaultModelParser(useOperationParser(submission))

    const config = useConfig<InfosysConfig>()

    const bookparser = useDefaultBookParser(modelparser, () => config, operationRowType)

    return useMultiParser(useDefaultParser({ modelparser, bookparser }))

}


export const operationIndicatorRowType = 'ind'

export const useOperationIndicatorParser = () => {

    const modelparser = useDefaultModelParser(useIndicatorParser())

    const config = useConfig<InfosysConfig>()

    const bookparser = useDefaultBookParser(modelparser, () => config, operationIndicatorRowType)

    return useMultiParser(useDefaultParser({ modelparser, bookparser }))

}
