import { fallbackStateOver, State } from 'apprise-frontend-core/state/api';
import { createContext } from 'react';
import { Action } from './action';
import { AuthzOracle } from './oracle';


export type AuthzOracleFactory = () => AuthzOracle

export type AuthzState = {

    // return the current oracle: expecting a user module to overwrite it with a real oracle.
    // modelled as a factory, so that it can be conveniently proxied to access the latest logged user.
    oracleFactory: AuthzOracleFactory

    // all actions registered by modules
    actions: Record<string, Action>
}



// answers always yes.
export const noOracle : AuthzOracle = {

    isAdmin: () => true,
    can: () => true,
    canAny: () => true,
    canAll: () => true,
    canForSome: () => true

}

export const initialAuthzState: AuthzState = {

    oracleFactory: () => noOracle,    // standard implementation allows everything.

    actions: {}
}

export const AuthzContext = createContext<State<AuthzState>>(fallbackStateOver(initialAuthzState))
