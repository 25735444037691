import { StateProvider } from "apprise-frontend-core/state/provider";
import { PropsWithChildren, useContext } from "react";
import { TableContext, TableDataContext } from './context';
import { SortSpec } from './sorting';
import { TableElement } from './table';



export type TableState = {

    layout: string[] | undefined
    sort: SortSpec[]
    scroll: number
};

export type TablesState = Record<string, TableState>;

const initialTableState = { } as TablesState;

export const TableProvider = (props: PropsWithChildren) => {

    const { children } = props;

    return  <StateProvider initialState={initialTableState} context={TableContext}>
        { children }
    </StateProvider>
}




export type TableData<T extends TableElement = TableElement> = {

    data: T[]
    selected: T[]
    textfilter: string | undefined
}

export type TablesDataState = Record<string, TableData>;

const initialTableDataState = { } as TablesDataState;

export const TableDataProvider =  (props: PropsWithChildren) => {
    
    const { children } = props;

    return  <StateProvider initialState={initialTableDataState } context={TableDataContext}>
        { children }
    </StateProvider>
}

// why export? so clients can take it as a signal table is not mounted yet.
export const unavailableData : TableData = {

    data: [],
    selected: [],
    textfilter: undefined
}


export const useTableData = <T extends TableElement = TableElement>(table: string) => {

    const ctx = useContext(TableDataContext)

    return ctx.get()?.[table] as TableData<T> ?? unavailableData


}