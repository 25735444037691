

import { TaggedModule } from 'apprise-frontend-tags/tag/modules'
import { AiOutlineUser } from "react-icons/ai"
import { FaUserCircle, FaUserTie } from 'react-icons/fa'
import { IconBaseProps } from 'react-icons'
import { Tip } from 'apprise-ui/tooltip/tip'
import { Tipped } from 'apprise-ui/tooltip/model'
import { useT } from 'apprise-frontend-core/intl/language'


export const userType = "user"
export const UserIcon = AiOutlineUser
export const UserIconFull = FaUserCircle
export const UserManagerIcon = (props:IconBaseProps & Tipped) => {

    const t = useT()
   
    return <Tip tip={t('user.manager')} {...props}>
                <FaUserTie size={11} color='coral' {...props} />
            </Tip>

}

export const userLoggedKey = 'apprise.loggeduser';


export const userChangeTopic = userType
export const userLogoutTopic = 'logout'

export const userSingular = `user.singular`
export const userPlural = `user.plural`

export const tagModule: TaggedModule = ({

    type: userType,
    singular: userSingular,
    plural: userPlural 

})