import { Layout } from 'antd'
import { useT } from 'apprise-frontend-core/intl/language'
import { Multilang, useMultilang } from 'apprise-frontend-core/intl/multilang'
import { fallbackStateOver, State } from 'apprise-frontend-core/state/api'
import { utils } from 'apprise-frontend-core/utils/common'
import { Component, useComponentProps } from 'apprise-ui/component/component'
import { classname, Disabled, Styled, StyledContainer, Tall, Wide } from 'apprise-ui/component/model'
import { ValidationStatus } from 'apprise-ui/field/validation'
import { Tipped } from 'apprise-ui/tooltip/model'
import { Tip } from 'apprise-ui/tooltip/tip'
import { UnknownIcon } from 'apprise-ui/utils/icons'
import * as React from 'react'
import Skeleton from 'react-loading-skeleton'
import { NavLink, useLocation } from 'react-router-dom'
import { SectionModel } from './model'
import { PageModelContext } from './page'


const defaultSidebarWidth = 320
const emptySidebarWidth = 80


//  marks and control transitions, for clients that want to optimise their own rendering as the sidebar contracts or expans.
export type SidebarState = {
    collapsed: boolean | undefined,
    ongoing: boolean
}

export const SidebarContext = React.createContext<State<SidebarState>>(fallbackStateOver({
    collapsed: undefined,
    ongoing: false
} as SidebarState))

export type SidebarProps = React.PropsWithChildren<Styled & {

    defaultOpen: boolean
    width: number

}>

export const Sidebar = (props: SidebarProps) => {

    const { width, defaultOpen, children } = props

    const ctx = React.useContext(SidebarContext) ?? {}

    const { pathname } = useLocation()

    const { collapsed = !defaultOpen } = ctx.get()

    const startTransitionAndCollapse = () => ctx.set(s => {

        s.ongoing = true;
        s.collapsed = !collapsed

    })

    const { icon = <UnknownIcon />, title = '<title>', sections = [], links = [], color } = React.useContext(PageModelContext) ?? {}

    // per-page, or based on provision of some content
    const finalwidth = width ?? (children || !collapsed) ? defaultSidebarWidth : emptySidebarWidth

    return <Layout.Sider className='page-sider focus-mode-aware-left' width={finalwidth}
        collapsible={!!children} collapsedWidth={emptySidebarWidth} collapsed={collapsed || !children}
        onTransitionEnd={e => { if (e.propertyName === 'width') ctx.set(s => s.ongoing = false) }}
        onCollapse={startTransitionAndCollapse}>

        <div style={{ background: color }} className='page-sider-logo'>
            {collapsed ? <Tip style={{ lineHeight: 0 }} tip={title} tipPlacement='bottomRight'>{icon}</Tip> : icon}
            {<div className='page-sider-logo-title'>
                {title}
            </div>}
        </div>

        <div className='page-sider-main'>

            <div className='page-sider-iconbar'>

                {sections.filter(enabledSection).map((section, i) => {

                    const route = Array.isArray(section.route) ? section.route[0] : section.route!

                    //  tolerates final slash in both route spec and url.
                    const exactlyAtRoute = [pathname, `${pathname}/`, pathname.substring(0, pathname.length - 1)].includes(route)

                    return <Tip key={i} tipPlacement="right" tip={section.title ?? '<title>'} >


                        <NavLink className={classname('page-sider-icon', exactlyAtRoute && 'active-exact')} exact={section.exact} to={route} >

                            {section.icon ?? <UnknownIcon />}

                        </NavLink>



                    </Tip>

                })}

                <div className='page-sider-links'>
                    {links.map((link, key) =>
                        <div className='page-sider-link' key={key}>
                            {link}
                        </div>
                    )}
                </div>


            </div>

            {collapsed || children}

        </div>

    </Layout.Sider>
}



export const SidebarContent = ($: Partial<SidebarProps>) => {

    const animated = React.useRef(true);

    const { children, className, style, ...rest } = $

    const childs = utils().elementsIn(children);

    const affixIdx = childs.findIndex(utils().isElementOf(SidebarContent.Affix))

    const affix = childs[affixIdx]

    const affixHeight = affix ? (affix?.props?.height ?? 300) : 0
   
    React.useEffect(() => {
        
        animated.current = false
    })

    if (affixIdx > -1) {

        childs.splice(affixIdx, 1);
    }

    return <Component name="sidebar" {...rest} style={{ ...style }} className={classname(className, animated.current && 'animated')}>

        <div className="sidebar-content-wrapper sidebar-scrollbar dark-custom-scrollbar" style={{ height: `calc(100% - ${ affixHeight }px)` }}>

            { childs }

        </div>
        <div className="sidebar-affix-wrapper" style={{ height: `${ affixHeight }px`, overflow: 'hidden' }}>

            { affix }
        </div>

    </Component>
}

SidebarContent.Affix = function Affix($: React.PropsWithChildren<Tall>) {

    const { children, height = 300 } = $

    return <div className='sidebar-affix' style={{ height, minHeight: height, maxHeight: height }}>{children}</div>

}

SidebarContent.Title = function Affix($: React.PropsWithChildren<{}>) {

    const { children } = $

    return <div className='sidebar-title'>{children}</div>
}



// const isRouteActive = ({ route, exact }: Partial<SectionModel>, pathname: string) => {

//     const routes = Array.isArray(route) ? route : [route ?? '']

//     return routes.includes("/") ? pathname === "/" : routes.some(r => exact ? pathname === r : pathname.startsWith(r))

// }


const enabledSection = (s: Partial<SectionModel>) => (s.enabled ?? true) && !(s.disabled ?? false)

type SidebarTransitionAwareProps = React.PropsWithChildren<StyledContainer & Wide & Tall & Partial<{

    placeholder: React.ReactNode
}>>


export const SidebarTransitionAware = (props: SidebarTransitionAwareProps) => {

    const { className, style = {}, height, width, placeholder = <Skeleton count={30} />, children } = props

    const transition = React.useContext(SidebarContext).get()

    return <div style={{ ...style, height, width }} className={classname('sidebar-transition', 'transition-ongoing', className)}>
        {transition.ongoing ? placeholder : children}
    </div>
}


export type SidebarPropertyProps = React.PropsWithChildren<Tipped & Disabled & Partial<{

    name: Multilang | React.ReactNode
    type: ValidationStatus
}>>

export const SidebarProperty = (props: SidebarPropertyProps) => {

    const t = useT()
    const multilang = useMultilang()

    const unknownValue = t("ui.unknown").toLocaleLowerCase()

    const { name, children, type = 'normal', disabled, ...rest } = useComponentProps(props)

    const valueclasses = classname('property-value-emphasis', `property-value-${type}`, disabled && 'property-value-disabled')

    return <div className="sidebar-property">

        {name && <div>{multilang.test(name) ? multilang.l(name) : name}</div>}

        <div style={{ display: "flex", justifyContent: "flex-end" }} className={valueclasses}>
            <Tip tipClassName='sidebar-property-tooltip' {...rest}>
                {children || unknownValue}
            </Tip>
        </div>

    </div>
}
