import { Action, useActions } from 'apprise-frontend-core/authz/action'
import { any } from 'apprise-frontend-core/authz/constants'
import { useLoggedOracle } from 'apprise-frontend-core/authz/oracle'
import { TagCategory } from './category/model'
import { TagIcon, tagType } from './constants'




const baseAction = { icon:<TagIcon />, type: tagType, resource : any }


export const categoryActions = {  
    
    manage:  { ...baseAction, shortName:"tag.action_manage_short",name:"tag.action_manage_name", labels:["manage"], description: "tag.action_manage_desc", actionType: 'admin'} as Action,
}

export const useTagOracle = () => {

    const actions = useActions()
    const oracle = useLoggedOracle()

    const can = {

        manageAll: () => oracle.can(categoryActions.manage),
        manage: (category: TagCategory) => oracle.can(actions.specialise(categoryActions.manage, category.id))

    }


    const self = {

        canAdd: can.manageAll

        ,

        canRemove: (category?: TagCategory | undefined) => category ? can.manage(category) : can.manageAll()

        ,

        canEdit: (category: TagCategory | undefined) =>  category ? can.manage(category) : can.manageAll()

    }


    return self
}
