import { useLayoutEffect, useRef } from "react"

export const RevealOnMount = (props: React.PropsWithChildren<{
    duration?: number
    easing?: string
}>) => {
    const { children, duration = 350, easing = 'ease-in-out' } = props
    const stateRef = useRef<boolean>(false)
    const domRef = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        if (!stateRef.current && domRef.current) {
            const height = domRef.current.offsetHeight

            domRef.current.style.cssText += `;height: 0px;transition: height ${ duration }ms ${ easing };`

            requestAnimationFrame(() => {
                domRef.current!.ontransitionend = ({ propertyName }) => {
                    if (propertyName !== 'height') return
                    stateRef.current = true
                    domRef.current!.style.cssText = ''
                }
                domRef.current!.style.height = `${ height }px`;
            })
        }
    // eslint-disable-next-line
    }, [])

    return <div ref={ domRef } style={{ overflow: 'hidden' }}>{ children }</div>;
}
