import { useT } from 'apprise-frontend-core/intl/language'
import { useBusyState } from 'apprise-frontend-core/utils/busyguard'
import { utils } from 'apprise-frontend-core/utils/common'
import { ReactNode } from 'react'
import shortid from 'shortid'
import { AskConsentProps, useFeedback } from './feedback'


type Feedback = ReturnType<typeof useFeedback>

export type TaskProps<P extends Array<any>, T = any> = Partial<{

    skipIf: boolean | (T & Exclude<T, Function>) | ((...args: P) => boolean | T)
    log: string | ((...args: P) => string)
    noBusyWait: boolean,
    clockTimingAs: string,
    show: ReactNode | ((...args: P) => ReactNode)
    notify: Parameters<Feedback['showNotification']> | Parameters<Feedback['notify']>[0] | ((_: T, ...args: P) => Parameters<Feedback['showNotification']>[0]) | true  // true means default.
    throw: Parameters<Feedback['showAndThrow']>[0]
    error: Parameters<Feedback['showError']>[0]
    minimumDuration: number | boolean | ((...args: P) => number | boolean),
    catch: ((e: any, ...args: P) => T)
    wait: boolean | number
}>

export type Task<P extends Array<any>, R> = (...any: P) => Promise<R>

export type Configurator = Parameters<ReturnType<ReturnType<typeof useAsyncTask>['make']>['with']>[0]

export const defaultMiniumTaskDuration = 150
export const defaultMinimumWait = 100


export const useAsyncTask = () => {

    const t = useT()

    const busy = useBusyState()
    const feedback = useFeedback()

    const factory = {

        make: <P extends Array<any>, R>(task: Task<P, R>, defaults: TaskProps<P, R> = {}) => {

            const clause = <F extends (...args: any[]) => void>(fun: F) => (...args: Parameters<typeof fun>) => { fun(...args); return config }

            const props: TaskProps<P, R> = defaults

            const config = {


                log: clause((_: TaskProps<P, R>['log']) => props.log = _)

                ,

                minimumDuration: clause((_: TaskProps<P, R>['minimumDuration']) => props.minimumDuration = _)

                ,

                notify: clause((_: TaskProps<P, R>['notify'] = true) => props.notify = _)

                ,

                quietly: clause(() => { props.notify = undefined; props.show = undefined! })

                ,

                noBusyWait: clause((value: boolean = true) => { props.noBusyWait = value })


                ,

                clockTimingAs : clause((value: string = 'task') => { props.clockTimingAs = value })

                ,

                show: clause((_: TaskProps<P, R>['show']) => props.show = _)

                ,

                throw: clause((_: TaskProps<P, R>['throw']) => { props.throw = _; props.error = undefined; props.catch = undefined })

                ,

                error: clause((_: TaskProps<P, R>['error']) => { props.error = _; props.throw = undefined; props.catch = undefined })

                ,

                catch: clause((_: TaskProps<P, R>['catch']) => { props.catch = _; props.throw = undefined; props.error = undefined })

                ,

                wait: clause((_: TaskProps<P, R>['wait']) => { props.wait = _ })


            }

            const api = {

                skipIf: (_: boolean | (R & Exclude<R, Function>) | ((...args: P) => boolean | R)) => {

                    props.skipIf = _

                    return api
                }

                ,

                with: (configure: (_: typeof config) => void) => {

                    configure(config) // applies policy.

                    return api

                }
                ,


                withConsent: (props: Partial<AskConsentProps> = {}) => {


                    const self = {

                        // asks for consent, the runs the task.
                        done: () => self.and(() => { }),

                        // collects callback, asks for consent, then runs the task then runs the callback.
                        and: (onDone: (result: R) => void) => (...args: P) => feedback.ask(props).thenRun(() => api.done()(...args).then(onDone))


                    }

                    return self

                }


                ,

                done: () => async (...args: P) => {

                    const id = shortid()

                    const { log, notify, show, wait, minimumDuration, noBusyWait, clockTimingAs } = props


                    let skip = props.skipIf

                    if (skip) {

                        if (typeof skip === 'function')
                            skip = (skip as any)(...args)

                        // return undefined or the provided result
                        return (typeof skip === 'boolean' ? undefined : skip) as unknown as R

                    }


                    log && console.log(typeof log === 'function' ? log(...args) : log)

                   
                    noBusyWait || busy.toggle(id, typeof show === 'function' ? show(...args) : show)


                    const t0 = performance.now()

                    if (wait && (typeof wait === 'boolean' || wait > 0))
                        await utils().waitNow(typeof wait === 'boolean' ? defaultMinimumWait : wait)


                    try {


                        const result = await task(...args)



                        notify &&

                            (typeof notify === 'object' ? feedback.showNotification(notify[0], notify[1])

                                :

                                feedback.showNotification(typeof notify === 'function' ? notify(result, ...args) : typeof notify === 'boolean' ? undefined : t(notify))
                            )

                        return result
                    }


                    catch (e) {

                        if (props.throw)
                            return feedback.showAndRethrow(e, props.throw)
                        if (props.error)
                            feedback.showError(props.error)(e)
                        if (props.catch)
                            return props.catch(e, ...args)


                        throw e
                    }
                    finally {

                        const time = performance.now() - t0

                        const duration = typeof minimumDuration === 'function' ? minimumDuration(...args) : minimumDuration

                        const minimumTime = duration ? typeof duration === 'boolean' ? defaultMiniumTaskDuration : duration : undefined

                        if (minimumTime && time < minimumTime)
                            await utils().waitNow(minimumTime - time)

                        if (clockTimingAs)
                            console.log(`${clockTimingAs} took ${time} msec.`)


                        noBusyWait || busy.toggle(id)
                    }

                }

            }

            return api
        }
    }


    return factory

}
