import { AxiosError, AxiosRequestConfig } from 'axios'
import { useContext } from 'react'
import { ClientContext } from './state'


export type ErrorInterceptor = (_: AxiosError<any>) => never | void
export type RequestInterceptor = (_: AxiosRequestConfig) => void


export const useInterceptor = () => {

    const state = useContext(ClientContext)
  
  
    const self = {
  
      addOnError: (interceptor: ErrorInterceptor) => {
  
        state.setQuietly(s => s.config.onError.unshift(interceptor))
      }
  
      ,
  
      addOnRequest: (interceptor: RequestInterceptor) => {
  
        state.setQuietly(s => s.config.onRequest.unshift(interceptor))
      }
  
      ,
  
    }
  
    return self
  
  }