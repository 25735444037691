
// access and change application state.

export type State<S = any> = {

    // returns the curen state.
    get: <SLICE extends S = S> () => SLICE

    // applies a set of state changes and triggers a render.
    set: <SLICE extends S = S>(_: Mutator<SLICE>) => Promise<void>,

    // applies a set of state changes without triggering a render.
    setQuietly: <SLICE extends S = S>(_: Mutator<SLICE>) => Promise<void>

    // resets state to a given value or the original value.
    reset: (_?: S) => void

    // resets state to a given value or the original value,without triggering a render.
    resetQuietly: (_?: S) => void

}

// changes its argument in place.
// used to capture required state changes in the State API.
export type Mutator<S> = (_: S) => any





// read-only implementation of the State API over a constant value. 
// a technical device to suppor cherry-picking of modules. 
// can be used as a fallback by APIs that don't find their state mounted in the component tree. 
// this occurs when their module isn't adopted, but its client modules are.
// the no-op implementation allows clients to make progress without breaking.

export const fallbackStateOver = <S = any>(s: S): State<S> => ({

    get: <SLICE extends S = S>(): SLICE => s as SLICE,
    set: <SLICE = S>(_: Mutator<SLICE>) => Promise.resolve(),
    setQuietly: <SLICE = S>(_: Mutator<SLICE>) => Promise.resolve(),
    reset: () => { },
    resetQuietly: () => { }

})