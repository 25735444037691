import { useT } from 'apprise-frontend-core/intl/language';
import { Optional } from 'apprise-frontend-core/utils/common';
import { useTenancyOracle } from 'apprise-frontend-iam/authz/tenant';
import { useLogged } from 'apprise-frontend-iam/login/logged';
import { Label, LabelProps } from 'apprise-ui/label/label';
import { Tip } from 'apprise-ui/tooltip/tip';
import { AiOutlineBook } from 'react-icons/ai';
import { UserIcon, UserManagerIcon, userType } from './constants';
import { User, UserReference, unknownUserId, useUserModel } from './model';
import { useUserRouting } from './routing';
import { useUserStore } from './store';


export type UserLabelProps = LabelProps & {

    user: Optional<UserReference | User>
}

export const NoUserLabel = (props: Pick<UserLabelProps,'mode'>) => {

    const t = useT()

    return <Label mode={props.mode} icon={<UserIcon />} title={t('user.no_user')} />
}

export const UserLabel = (props:UserLabelProps) => {

    const logged = useLogged()
    const store = useUserStore()
    const routing = useUserRouting()
    const model = useUserModel()
    const oracle = useTenancyOracle()

    const {title: titleOverride, user, decorations=[], unknown: unknownOverride, ...rest} = props

    // have nothing, render nothing. 
    if (!user)
        return null

    // resolve reference, failure is modelled as unknown user to pass-through next steps. 
    const resolved =  typeof user === 'string' ? store.safeLookup(user) : user

    const note = resolved.details.preferences.note?.[logged.tenant] 
  
    if (note) 
        decorations.push(<Tip tip={note}>{<AiOutlineBook />}</Tip>)

    const inactive = resolved?.lifecycle.state === 'inactive'

    const route = routing.detailRoute(resolved?.username)

    // unresolved reference: show it, unless clients overrides.
    const unknown = resolved.username === unknownUserId ? unknownOverride ?? (typeof user === 'string' ? user : user.details.lastname) : false
    
    // resolved reference: show full name, unless client overrides.
    const title = unknown ? undefined : titleOverride ?? model.fullName(resolved)

    const icon = oracle.given(resolved).isTenantManager() ? <UserManagerIcon /> : <UserIcon />

    return <Label unknown={unknown} decorations={decorations} readonly={resolved?.guarded} inactive={inactive} icon={icon} title={title} linkTarget={userType} linkTo={route} {...rest} />

}



export const ManagerLabel = (props: { user: User }) => {

    const { user } = props

    const t = useT()
    const oracle = useTenancyOracle(user)

    return oracle.isTenantManager() ? <UserLabel user={user} mode='tag' fill='coral' title={t('user.manager')} /> : null
}