
import { DeepPartial } from 'apprise-frontend-core/utils/common'




export type ParserConfig = {

    parse: {

        xslxMimeTypes?: string[]
        jsonMimeTypes?: string[]

        headerCornerstone: string | string[],

        headerCornerstoneColOffset?: number,
        headerCornerstoneRowOffset?: number,

        excludeSheet?: string[],
        excludeSheetIndex?: number[],

        includeSheet?: string[],
        includeSheetIndex?: number[],

        headerRowRadius?: number,
        headerColRadius?: number,

        rowRadius?: number,
        colRadius?: number,

        keys: Record<string, ParseKey>

        type?: DeepPartial<Record<string, Omit<ParserConfig['parse'], 'type'> & {

            sheet?: Record<string, Partial<Omit<ParserConfig['parse'], 'type' | 'sheet'>>>

        }>>

    }
}

export type ParseKey = {
    key: string,
    tkey?: string,
    exact?: boolean,
    filter?: (row: any) => boolean,
    aliases?: Record<string, string | string[] | undefined>
    colAliases?: string | string[]
}

export const defaultParserConfig: DeepPartial<ParserConfig['parse']> = {


    xslxMimeTypes: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'],
    jsonMimeTypes: ['application/json'],

    headerRowRadius: 50,
    headerColRadius: 100,

    headerCornerstoneColOffset: 0,
    headerCornerstoneRowOffset: 0

}