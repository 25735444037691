import { useT } from 'apprise-frontend-core/intl/language'
import { useCallback, useContext } from 'react'
import { TagModuleContext } from './provider'



export type TaggedModule = {

    icon?: JSX.Element
    type: string
    singular: string
    plural: string
    customProperties?: string[]
    
}



export const useTagModules = () => {

    const state = useContext(TagModuleContext)

    const t = useT()

    const allModules = state.get().all

    // eslint-disable-next-line
    const sortedModules = useCallback( () => [...allModules].sort((m1,m2) => t(m1.singular).localeCompare(m2.singular)), [allModules] )

    const self = {

        register: (module: TaggedModule) => {
            state.set(s => s.all.push(module))
            return self;
        }

        ,

        lookup: (type: string) => state.get().all.find(m=>m.type===type)
        
        ,

        all: sortedModules

    }

    return self;
}