import { Action, useActions } from 'apprise-frontend-core/authz/action';
import { useLoggedOracle } from 'apprise-frontend-core/authz/oracle';
import { TenantIcon, tenantType } from 'apprise-frontend-iam/tenant/constants';
import { User } from 'apprise-frontend-iam/user/model';
import { Tenant } from '../tenant/model';
import { useTenancyOracle } from './tenant';


// currently, we don't expect user-specific actions, as user management rights will descend from tenant management rights.
// we also don't expect fine-grained tenant management rights.

export const basetenant = { icon: <TenantIcon />, type: tenantType, actionType: 'admin' as const }


export const tenantActions: Record<string,Action> = {

    manage: { ...basetenant,  labels: ["manage"], shortName: "tenant.actions.manage_short", name: "tenant.actions.manage_name", description: "tenant.actions.manage_desc"},
    edit: { ...basetenant, labels: ["manage", "edit"], virtual: true, shortName: "tenant.actions.manage_edit_short", name: "tenant.actions.manage_edit_name", description: "tenant.actions.manage_edit_desc", width:90 },
    admin: { ...basetenant, labels: ["manage", "user"], virtual: true, shortName: "tenant.actions.manage_user_short", name: "tenant.actions.manage_user_name", description: "tenant.actions.manage_user_desc", width:90 }
 
}



//  answers authz questions about logged user internal to this module. 
//  (cf. useUserOracle used internally and externally to asl questions about arbitrary users)

export const useInternalUserOracle = () => {

    const tenancy = useTenancyOracle()
    const tenants = useInternalTenantOracle()
   
    const self = {

        canEdit: (user: User) => tenants.canEdit(user?.tenant) 

        ,

       
        canAdd: () => tenants.canAdd() || tenancy.isTenantManager()


        ,

        canRemove: (user: User) => user.lifecycle.state === 'inactive'


    }


    return self
}



//  answers tenancy questions about logged user internal to this module. 
//  (cf. useTenancyOracle used internally and externally to asl questions about arbitrary users)

export const useInternalTenantOracle = () => {

    const oracle = useLoggedOracle()
    const actions = useActions()

    const logged = useTenancyOracle()

    const idOf = (tenant: string | Tenant | undefined) => typeof tenant === 'string' ? tenant : tenant?.id

    const can = {

        manageAll: () => oracle.can(tenantActions.manage)

        ,
        
        manage: (tenant?: string | Tenant) => (logged.hasNoTenant() || ((tenant as Tenant)?.id ?? tenant) === logged.tenant()) &&  oracle.can(actions.specialise(tenantActions.manage, idOf(tenant) ))

    }

    const self = {


        canAdd: () => logged.hasNoTenant() && can.manageAll()

        ,

        canEdit: can.manage

        ,

        canRemove: (tenant?: Tenant | undefined) => {

            
            if (!tenant)
                return can.manageAll()


            const state = tenant.lifecycle.state === 'inactive' 

            return state && can.manage(tenant)
        
        }

    }

    return self
}


