import { utils } from 'apprise-frontend-core/utils/common'
import { classname, Debugged, Styled } from 'apprise-ui/component/model'
import React, { CSSProperties, KeyboardEventHandler, useEffect, useRef, useState } from 'react'



export type SuffixHelperProps = React.PropsWithChildren<Debugged & Styled & Partial<{

    suffix: React.ReactNode | React.ReactNode

    show: boolean               // when should the suffix show?
    
    auto: boolean              // whether the suffix show activate automaticall or on SHIFT-click

    mask: boolean              // should we hide the input while the suffix shows and is active?

    focusOnHover: boolean
   
    maskStyle: CSSProperties
    maskClassName: string
}>>

/**
 *  places some content, typically an icon(s), in the top-right corner of its children, typically an input field.
 *  it does so after a given condition is a met, either automaticlly or after a SHIFT click on hover and/or focus. 
 */


export const SuffixHelper = (props: SuffixHelperProps) => {

    const { className, style, suffix, show, children, focusOnHover, mask, maskStyle, maskClassName, auto } = props

    // we show the suffix only when SHIFT-clicking on hover 
    const [state, stateSet] = useState({ key: false, over: false, focus: false })

    const ref = useRef<HTMLDivElement>(null)

     const onMouseOver = (over: boolean) => {

        if (focusOnHover)
        
            if (over)
                ref.current?.focus()
            else
                ref.current?.blur() 

        stateSet(s => ({ ...s, key: !over ? false: s.key, over }))
    }

    const onFocus = (focus: boolean) => {

        stateSet(s => ({ ...s, key: !focus ? false : s.key, focus }))
        
    }

    const onKeyPress: KeyboardEventHandler = e => {

        if (e.shiftKey)
            stateSet(s => ({ ...s, key: !s.key }))
    }

    // interacting with the suffix might well hide it
    // when that happens, we sync the state for when we're show it again.
    useEffect(() => {

        if (!show)
            stateSet(s => ({ ...s, key: false }))     // user needs to click again

    }, [show])

    const active = auto || (state.key && ( state.over || state.focus))

    const suffixes = utils().arrayOf(suffix).map((s, i) =>
        <div key={i} className='suffix' >
            {s}
        </div>)

    const classes = classname('suffix-container', active && 'suffix-container-active', className)

    props.debug && console.log("show",show,"state",state,"active", active, "mask",mask)
    
    return <div tabIndex={-1} ref={ref} className={classes} onFocus={() => onFocus(true)} onKeyDown={onKeyPress} onMouseEnter={() => onMouseOver(true)} onMouseLeave={() => onMouseOver(false)}>

        {show && active && <>

            { mask &&
                <div style={maskStyle} className={classname('suffix-mask-container',maskClassName)}>
                   <div className='suffix-mask' />
                </div>

            }

            <div style={style} className="suffixes">{suffixes}</div>

        </>}

        {children}

    </div>
}
