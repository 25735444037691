
import { ConnectionOptions, connect } from 'nats.ws';
import * as React from 'react';
import { EventConnectionContext } from './context';


export const useEventConnection = () => {

    const state = React.useContext(EventConnectionContext)

    const self = {

        init: async (mode: 'connect' | 'reconnect') => {

            console.log(mode === 'reconnect' ? 're-connecting to event bus...' : 'connecting to event bus...')

            const connectionProperties : ConnectionOptions =  {

                servers: `${window.location.protocol === 'http:' ? 'ws' : 'wss'}://${window.location.host}/event`,
                maxReconnectAttempts: -1,
                waitOnFirstConnect: true
               // , timeout: 10000    default is 200000

            }



            try {

                const connection = await connect(connectionProperties)

                state.set(s => s.connection = connection)

                connection.closed().then(err => {
                    if (err)
                        console.log(`error disconnecting from event bus`, err)
                })

                return connection

            } catch (err) {

                mode === 'reconnect' || console.log(`cannot connect to event bus`, err)

            }


        }


    }


    return self

}