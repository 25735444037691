

import { AuthzOracleFactory } from 'apprise-frontend-core/authz/context';
import { useContext } from 'react';
import { LoginContext } from '../login/login';
import { useUserOracle } from './user';




// used internally, registers an oracle for the user currently logged. 

export const useLoggedOracleFactoryImpl = (): AuthzOracleFactory => {

    const oracle = useUserOracle()

    // access state directly to fetch currently logged user factory closure.
    const state = useContext(LoginContext)

    const factory = () => oracle.given(state.get().logged)

    return factory
}
