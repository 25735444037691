

import { operationIndicatorRowType, operationRowType } from '#submission/parser';
import { RemoteAuthzConfiguration } from 'apprise-frontend-core/authz/config';
import { RemoteClientConfiguration } from 'apprise-frontend-core/client/config';
import { Config } from 'apprise-frontend-core/config/model';
import { RemoteIntlConfiguration } from 'apprise-frontend-core/intl/config';
import { DeepPartial } from 'apprise-frontend-core/utils/common';
import { defaultParserConfig, ParserConfig } from 'apprise-frontend-parse/config';
import { categoryType, tagType } from 'apprise-frontend-tags/constants';

// this is the type we project over the configuration when we want to read it: useConfig<InfosysConfig>().
// it includes options defined by libraries and options defined by the application.
// some library-defined options are read only by libraries, but some may be read also bvy the application.

export type InfosysConfig = Config & RemoteIntlConfiguration & RemoteClientConfiguration & RemoteAuthzConfiguration & ParserConfig & {

   version: string
   latest: boolean

}

export const useDefaulConfig = (): DeepPartial<InfosysConfig> => {

   return {

      latest: true,

      propertyExcludes: {

         [tagType]: ['value'],
         [categoryType]: ['form-support']
      }

      ,

      client: {
         mocks: { responseDelay: 150 },
         services: { domain: { label: "domain", prefix: "/domain", default: true } }
      }

      ,

      authz: {

         enableMultiManagers: false // reinforces default for clarity.

      }

      ,

      parse: {

         ...defaultParserConfig,

         headerCornerstoneRowOffset: 1

         ,

         type: {


            [operationRowType]: {

               headerCornerstone: "OP-1",

               keys: {

                  "OP-1": { key: 'cci', exact:true },
                  "OP-2": { key: 'id', exact:true },
                  "OP-3": { key: 'description', exact:true, tkey: 'op.desc' },
                  "OP-4": { key: 'cfr', exact:true, tkey: 'op.cfr' },
                  "OP-5": { key: 'sector', exact:true },

                  "OP-6": { key: 'objective', exact:true },
                  "OP-7": { key: 'location', exact:true, tkey: 'op.location' },
                  "OP-8": { key: 'beneficiaryName', exact:true, tkey: 'op.beneficiary_name' },
                  "OP-9": { key: 'beneficiaryCode', exact:true, tkey: 'op.beneficiary_code' },
                  "OP-10": { key: 'beneficiaryType', exact:true },
                  "OP-11": { key: 'beneficiaryGender', exact:true },

                  "OP-12": { key: 'people', exact:true, tkey: 'op.people' },
                  "OP-13": { key: 'partners', exact:true, tkey: 'op.partners' },
                  "OP-14": { key: 'beneficiaryLead', exact:true, tkey: 'op.beneficiaryLead' },
                  "OP-15": { key: 'domain', exact:true },
                  "OP-16": { key: 'progress', exact:true },

                  "OP-17": { key: 'eligibleCost', exact:true, tkey: 'op.eligibleCost' },
                  "OP-18": { key: 'eligiblePublicCost', exact:true, tkey: 'op.eligiblePublicCost' },
                  "OP-19": { key: 'emfafSupport', exact:true, tkey: 'op.emfafSupport' },
                  "OP-20": { key: 'approvalDate', exact:true, tkey: 'op.approvalDate' },
                  "OP-21": { key: 'eligibleExpenditure', exact:true, tkey: 'op.eligibleExpenditure' },
                  "OP-22": { key: 'eligiblePublicExpenditure', exact:true, tkey: 'op.eligiblePublicExpenditure' },
                  "OP-23": { key: 'emfafEligibleExpenditure', exact:true, tkey: 'op.emfafExpenditure' },
                  "OP-24": { key: 'finalPaymentDate', exact:true, tkey: 'op.finalPaymentDate' },

                  "OP-25": { key: 'intervention', exact:true },
                  "OP-26": { key: 'type', exact:true },

                  "OP-27": { key: 'gtIncrease', exact:true, tkey: 'op.gtIncrease' },
                  "OP-28": { key: 'fleetSegment', exact:true, tkey: 'op.fleetSegment' },

                  "OP-29": { key: 'sscf', exact:true, tkey: 'op.sscf_short' },
                  "OP-30": { key: 'landingObligation', exact:true, tkey: 'op.landingObligation' },
                  "OP-31": { key: 'climateChange', exact:true, tkey: 'op.climateChange' },
                  "OP-32": { key: 'nonDiscrimination', exact:true, tkey: 'op.nonDiscrimination' },
                  "OP-33": { key: 'genderEquality', exact:true, tkey: 'op.genderEquality' },
                  "OP-34": { key: 'disability', exact:true, tkey: 'op.disability' },

                  "OP-35": { key: "support", exact:true },
                  "OP-36": { key: 'beneficiarySupported', exact:true, tkey: 'op.beneficiarySupported' },

               }

            }

            ,

            [operationIndicatorRowType]: {

               headerCornerstone: "IND-1",

               keys: {

                  "IND-1": { key: 'cci', exact:true },
                  "IND-2": { key: 'id', exact:true },
                  "IND-3": { key: "indicator", exact:true },
                  "IND-4": { key: "baseline", exact:true },
                  "IND-5": { key: "expected", exact:true },
                  "IND-6": { key: "expost", exact:true }
               }

            }

         }

      }
   }

}


// config that anchors on English Names.
export const useMockTextHeaderConfig = (): DeepPartial<InfosysConfig> => {

   return {

      parse: {


         excludeSheet: ['List_of_codes'],
         headerCornerstone: 'cci',

         keys: {

            cci: { key: 'cci' },
            unique_identifier: { key: 'id' },

         }

         ,

         type: {

            [operationIndicatorRowType]: {

               includeSheet: ['annex_II'],

               keys: {

                  common_result: { key: "indicator" },
                  baseline_value: { key: "baseline" },
                  indicative_result: { key: "expected" },
                  "ex-post_result": { key: "expost" }
               }

            }

            ,

            [operationRowType]: {

               includeSheet: ['annex_I'],

               keys: {

                  description: { key: 'description', tkey: 'op.desc' },
                  common_fleet: { key: 'cfr', tkey: 'op.cfr' },
                  type_of_op: { key: 'type' },
                  sector: { key: 'sector' },
                  specific_objective: { key: 'objective' },
                  nuts: { key: 'location', tkey: 'op.location' },
                  name_of_beneficiary: { key: 'beneficiaryName', tkey: 'op.beneficiary_name' },
                  beneficiary_code: { key: 'beneficiaryCode', tkey: 'op.beneficiary_code' },
                  type_of_beneficiary: { key: 'beneficiaryType' },
                  gender_of_beneficiary: { key: 'beneficiaryGender' },
                  lead_partner: { key: 'beneficiaryLead', tkey: 'op.beneficiaryLead' },
                  beneficiary_receiving: { key: 'beneficiarySupported', tkey: 'op.beneficiarySupported' },
                  state_of_progress: { key: 'progress' },

                  number_of_people: { key: 'people', tkey: 'op.people' },
                  number_of_partners: { key: 'partners', tkey: 'op.partners' },

                  type_of_intervention: { key: 'intervention' },
                  indication_as_to_whether: { key: 'domain' },

                  total_eligible_cost: { key: 'eligibleCost', tkey: 'op.eligibleCost' },
                  total_eligible_public: { key: 'eligiblePublicCost', tkey: 'op.eligiblePublicCost' },
                  emfaf_support: { key: 'emfafSupport', tkey: 'op.emfafSupport' },
                  total_eligible_expenditure: { key: 'eligibleExpenditure', tkey: 'op.eligibleExpenditure' },
                  total_eligible_public_expenditure: { key: 'eligiblePublicExpenditure', tkey: 'op.eligiblePublicExpenditure' },
                  emfaf_eligible: { key: 'emfafEligibleExpenditure', tkey: 'op.emfafExpenditure' },
                  date_of_approval: { key: 'approvalDate', tkey: 'op.approvalDate' },
                  date_of_final: { key: 'finalPaymentDate', tkey: 'op.finalPaymentDate' },

                  increase_of_the_gross: { key: 'gtIncrease', tkey: 'op.gtIncrease' },
                  description_of_the_fleet: { key: 'fleetSegment', tkey: 'op.fleetSegment' },

                  operation_relevant_to_small: { key: 'sscf', tkey: 'op.sscf_short' },
                  operation_relevant_to_the_landing: { key: 'landingObligation', tkey: 'op.landingObligation' },
                  operation_relevant_to_climate: { key: 'climateChange', tkey: 'op.climateChange' },
                  "operation_relevant_to_non-discrimination": { key: 'nonDiscrimination', tkey: 'op.nonDiscrimination' },
                  operation_relevant_to_gender: { key: 'genderEquality', tkey: 'op.genderEquality' },
                  operation_relevant_to_rights: { key: 'disability', tkey: 'op.disability' },

                  form_of_support: { key: "support" }

               }

            }

         }
      }
   }

}