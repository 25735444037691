

import { StateProvider } from 'apprise-frontend-core/state/provider'
import { useRenderGuard } from 'apprise-frontend-core/utils/renderguard'
import * as React from 'react'
import { useActionRegistry } from './action'
import { AuthzContext, initialAuthzState } from './context'
import { standardActions } from './constants'



// mounts authz state and initialises it.

type AuthzProps = React.PropsWithChildren<{}>

export const Authz = (props: AuthzProps) => {

    const { children } = props

    return <StateProvider initialState={initialAuthzState} context={AuthzContext}>
        <AuthzInitializer>
            {children}
        </AuthzInitializer>
    </StateProvider>
}


// initialises authz state by registering the default actions.
const AuthzInitializer = (props: AuthzProps) => {

    const actions = useActionRegistry()

    const activate = () => {

        console.log("registering standard actions...")

        actions.register(Object.values(standardActions))
    }

    const { content } = useRenderGuard({

        render: props.children,
        orRun: activate


    })

    return content

}