import { Action, ActionDto, useActions } from 'apprise-frontend-core/authz/action'
import { useInclusionList } from 'apprise-frontend-core/config/api'
import { Language, useLanguage } from 'apprise-frontend-core/intl/language'
import { Optional, useUtils } from 'apprise-frontend-core/utils/common'
import { Lifecycled } from 'apprise-frontend-core/utils/lifecycle'
import { noTenant, TenantResource } from 'apprise-frontend-iam/tenant/model'
import { useTenantStore } from 'apprise-frontend-iam/tenant/store'
import { userType } from './constants'
import { Note } from './notebox'
import { AccountState } from 'apprise-frontend-iam/account/model'

export type UserLifecycleState = "active" | "inactive"

export type UserDetails = {
  firstname: string
  lastname: string
  email: string
  preferences: Partial<UserPreferences>
}

type BaseUser = TenantResource & Lifecycled<UserLifecycleState> & {

  username: string
  details: UserDetails
  tags: string[]
  guarded: boolean

}


export type UserDto = BaseUser & {

  permissions: ActionDto[]

}

export type User = BaseUser & {

  permissions: Action[]
  fetched: boolean

}

export type UserReference = string


export type UserPreferences = Partial<{

  accountId: string,
  accountState: AccountState
  language: Language
  address: string
  phone: string
  note: Note

}> & { [key: string]: any }   // extensible by clients



export const unknownUserId = 'unknown'


export const useNewUser = () => {

  const { isIncluded } = useInclusionList(userType)

  return (tenant: string): User => {

    return {

      username: undefined!,
      tenant,
      guarded: false,
      lifecycle: { state: isIncluded('active') ? 'inactive' : 'active' },
      details: {
        firstname: undefined!,
        lastname: undefined!,
        email: undefined!,
        preferences: {
          accountState: 'none'
        }
      },
      tags: [],
      permissions: [],
      fetched: false

    }

  }

}

export const useUnknownUser = () => {

  const newUserIn = useNewUser()

  return (username?: Optional<UserReference>): User => ({

    ...newUserIn(noTenant),

    username: unknownUserId,
    lifecycle: {
      state: 'active'
    },
    details: {
      firstname: undefined!,
      lastname: username || undefined!,
      email: undefined!,
      preferences: {}
    }

  })
}



export const useUserModel = () => {

  const utils = useUtils()
  const lang = useLanguage()
  const tenantStore = useTenantStore()
  const actions = useActions()
  const unknownUser = useUnknownUser()

  const self = {

    textOf: (t: Optional<User>) => utils.join(t ? [t.username, self.fullName(t)] : []),

    unknownUser

    ,

    fullName: (u: Optional<User>) => u ? `${u?.details.firstname ?? ''} ${u?.details.lastname ?? ''}`.trim() : undefined

    ,

    clone: (u: User): User => ({ ...utils.deepclone(u), username: undefined!, lifecycle: { state: 'inactive' } })

    ,

    reconciledPermissions: (u: User) => actions.reconcile(u.permissions)

    ,

    defaultLanguage: (user: User) => user.tenant === noTenant ? lang.default() : tenantStore.safeLookup(user.tenant).preferences.language ?? lang.default()

    ,


    preferredLanguage: (user: User) => user.details?.preferences.language ?? self.defaultLanguage(user)

    ,

    comparator: (u1: Optional<User>, u2: Optional<User>) => self.fullName(u1)?.localeCompare(self.fullName(u2) ?? '') ?? -1

  }

  return self
}