
import { State } from 'apprise-frontend-core/state/api';
import { StateProvider } from 'apprise-frontend-core/state/provider';
import { CustomProperty } from 'apprise-frontend-tags/customprop/model';
import { createContext, PropsWithChildren } from 'react';
import { TagCategory } from './model';




export type CategoryCache = {

    all: TagCategory[]
}



export const CategoryCacheContext = createContext<State<CategoryCache>>(undefined!)

export const initialCategoryCache: CategoryCache = {

    all: []
}


export type CategoryRoutingState = {

    nextCategory: TagCategory
    routeAfterNext: (added: TagCategory) => any

}

export const CategoryRoutingContext = createContext<State<Partial<CategoryRoutingState>>>(undefined!)

export const initialRoutingState: Partial<CategoryRoutingState> = {}


export type CustomPropertyState = {

    types: CustomProperty[]
}


export const CustomPropertyContext = createContext<State<CustomPropertyState>>(undefined!)

export const intialCustomPropertyContext: CustomPropertyState = {
    types: []
}

export const CategoryProvider = (props: PropsWithChildren<{}>) =>

    <StateProvider initialState={initialCategoryCache} context={CategoryCacheContext}>
        <StateProvider initialState={initialRoutingState} context={CategoryRoutingContext}>
            <StateProvider initialState={intialCustomPropertyContext} context={CustomPropertyContext}>
                {props.children}
            </StateProvider>
        </StateProvider>
    </StateProvider>

