import { useT } from 'apprise-frontend-core/intl/language'
import { useComponentBridge } from 'apprise-frontend-core/utils/bridge'
import { utils } from 'apprise-frontend-core/utils/common'
import { Button } from 'apprise-ui/button/button'
import { classname, Sized, Tall, Wide } from 'apprise-ui/component/model'
import { useChangeHelper } from 'apprise-ui/field/changehelper'
import { Field, useFieldProps } from 'apprise-ui/field/field'
import { ChangeTracked, Fielded, Uncontrolled } from 'apprise-ui/field/model'
import { useReadonlyHelper } from 'apprise-ui/field/readonlyhelper'
import { useResetHelper } from 'apprise-ui/field/resethelper'
import { SuffixHelper } from 'apprise-ui/field/suffixhelper'
import { RemoveItemIcon } from 'apprise-ui/utils/icons'
import { Sources } from 'quill'
import { lazy, Suspense, useEffect, useRef, useState } from 'react'
import 'react-quill/dist/quill.snow.css'
import { useQuillContext } from './context'
import "./styles.scss"


export const ReactQuill = lazy(() => import('react-quill'))


export type RichboxProps = Fielded & ChangeTracked & Sized & Wide & Tall & Uncontrolled & Partial<{

    placeholder: true | string

    children: string

    onRemove: () => any

    noToolbar?: boolean
    noImage?: boolean

}>

export const RichBox = (clientprops: RichboxProps) => {


    // track the last value reported by the field, so that we can tell later if we'rendering an internal change (comes from the filed) 
    // or an external change (e.g. a reset).
    const trackedOnChange = clientprops.onChange ? ((v: string | undefined) => {

        lastChange.current = v

        clientprops.onChange?.(v)

    }) : undefined


    const props = useFieldProps({ ...clientprops, onChange: trackedOnChange, delay: clientprops.delay || true })

    const currentValue = props.children ?? props.defaultValue

    const { pastMode, pastValue } = useChangeHelper(props)

    const latestValue = pastMode ? pastValue : currentValue

    const lastChange = useRef<string | undefined>(latestValue)



    const t = useT()

    // we use this to inspect and reset the internal state of the field.
    const ref = useRef<any>(null)

    const initial = useRef(props.children)

    // we revert empty string to undefined if that was the initial value, so we avoid false positives in change tracking. 
    // but we leave it empty if we have a default value, so don't end up resetting it if we wanted instead to empty the field.

    const [key, changeKey] = useState(0)


    const trimmed = (value: string) => value?.replace("<p><br></p>", '').replace(/<p>(.*?)<\/p>/, '$1').trim()

    //eslint-disable-next-line
    useEffect(() => {

        const shouldsync = props.controlled && !utils().deepequals(latestValue, lastChange.current)

        if (shouldsync) {
            props.cancelDebouncedChange();
            lastChange.current = latestValue;
            changeKey(s => ++s)
        }
    })

    const change = (content: string, _: any, source: Sources) => {

        // we react only to user changes, not api change. this avoids rerender on mount, when the first value set triggers a programmatic change.
        if (source !== 'user')
            return

        const trimmedValue = trimmed(content)

        // we revert empty string to undefined if that was the initial value, so we avoid false positives in change tracking. 
        // but we leave it empty if we have a default value, so don't end up resetting it if we wanted instead to empty the field.
        const initialNotEmpty = initial.current && initial.current.trim().length > 0
        const currentNotEmpty = trimmedValue.length > 0
        const normalised = currentNotEmpty || initialNotEmpty || props.defaultValue ? trimmedValue : initial.current

        props.onChange?.(normalised)
    }



    const classes = classname(props.className, props.noToolbar && 'richbox-notoolbar', props.defaultValue && !props.children && 'input-default-value')

    useReadonlyHelper(props)

    useResetHelper(props)

    const removable = !!props.onRemove


    const removeBtn = removable && <Button className='suffix-remove-lang' type='ghost' noReadonly={true} onClick={props.onRemove}>
        <RemoveItemIcon color='orange' />
    </Button>

    const { Indicator } = useComponentBridge()

    const quillProps = useQuillContext(ref)

    return <Field name="richbox" {...props} className={classes}  >
        <SuffixHelper show={removable} suffix={removeBtn} >

            <Suspense fallback={<Indicator msg='' waiting />}>
                <ReactQuill
                    {...quillProps}
                    key={key}
                    ref={ref}
                    defaultValue={props.defaultValue ?? ''}   // empty string is a workaround for an unreleased fix: see https://github.com/zenoamaro/react-quill/issues/498
                    onChange={change}
                    style={props.innerStyle}
                    className={props.innerClassName}
                    readOnly={props.readonly || props.disabled}
                    placeholder={props.placeholder === true ? t('ui.field_placeholder') : props.placeholder}
                />
            </Suspense>

        </SuffixHelper>
    </Field>


}


