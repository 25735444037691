import * as React from 'react'


export type Toolbridge = {

    baseUrl: string

}

export type ToolbridgeProps = React.PropsWithChildren<{
    tool: Toolbridge
}>

export const defaultToolBridge: Toolbridge = {

    baseUrl: "/"
}

export const ToolBridgeContext = React.createContext<Toolbridge>(defaultToolBridge)

export const useToolBridge = () => {
    
    const ctx = React.useContext(ToolBridgeContext)
    
    return { 
        
        ...ctx,

        absoluteOf: (path:string) => `${ctx.baseUrl}/${path}`.replace(/\/{2,}/g, "/")
    
    }
}

export const ToolbridgeProvider = (props:ToolbridgeProps) => {

    const {tool, children} = props

    return <ToolBridgeContext.Provider value={tool}>
        {children}
    </ToolBridgeContext.Provider>

}