import { StateProvider } from 'apprise-frontend-core/state/provider'
import { PropsWithChildren, useContext } from 'react'
import { ToggleContext, ToggleState } from './context'
import { useMode } from 'apprise-frontend-core/config/api'



export type ToggleProps<T extends string = string> = PropsWithChildren<Partial<ToggleState<T>>>

const initialToggleState: ToggleState = {

    // context is untyped, so we erase specific types at this point.
    toggles: [],
    isActive: undefined!

}


// in the default policy, apps opt-out of features in production, while all features are available elsewhere.
// so apps specify work-in-progress until features are fully released, amd they specify library features until they want to adopt them.

export const useOptOutPolicy = (toggle: string) => {

    const mode = useMode()

    const { toggles } = useContext(ToggleContext).get()

    return !mode.production || !toggles.includes(toggle)

}


export const Toggles = <T extends string = string>(props: ToggleProps<T>) => {

    const { toggles = [], isActive = useOptOutPolicy , children } = props

    const state: ToggleState = {

        ...initialToggleState,

        // context is untyped, so we erase specific types at this point.
        isActive: isActive as (_:string) => boolean,

        toggles,
    }

    return <StateProvider initialState={state} context={ToggleContext}>
        {children}
    </StateProvider>

}


export const useToggles = <T extends string=string> () => {

    const state = useContext(ToggleContext)

    const { isActive } = state.get()

    return {
        
        isActive: isActive as (_:T) => boolean
    }
}


