


//  types

import { utils } from 'apprise-frontend-core/utils/common'
import { useContext } from 'react'
import { any } from './constants'
import { AuthzContext } from './context'




// someting that can be done in an application, a capability.
// associated with a user, models the right to trigger the action.

export type ActionDto = {   // as exchanged on the wire.

    labels: string[]
    resource?: string
    type: string

}

export type Action = ActionDto & {  // as locally enriched for presentation purposes.

    name: string
    shortName?: string
    icon?: JSX.Element
    description: string
    virtual?: boolean
    width?: string | number
    actionType?: 'admin' | 'tenant'

}


// shared internally by apis.
const concat = (type: string, labels: string[], resource: string = any) => `${type}:${labels.join(":")}:${resource}`



// collects actions registered by other modules.

export const useActionRegistry = () => {

    const state = useContext(AuthzContext) 

    const key = (dto: ActionDto) => concat(dto.type, dto.labels)

    const self = {

        all: () => state.get().actions,

        lookup: (action: ActionDto) => self.all()[key(action)],

        register: (actions: Action[]) => state.set(s => actions.forEach(a => s.actions[key(a)] = a))
    }

    return self

}


// utility functions and a comparison algebra to use as the basis for an authorization api.
export const useActions = () => {

    const registry = useActionRegistry()

    const self = {

        concat
        
        , 
        
        idOf:  ({ type, labels, resource }: ActionDto) => concat(type, labels, resource)

        ,


        specialise: (a: Action, resource?: string): Action => ({ ...a, resource })

        ,

        // the first has at least the same labels as the second, where 'same' includes also an any match.
        matchLabels: (a1: Action, a2: Action) => !a2.labels.some((lbl, i) => lbl !== any && lbl !== a1.labels[i])

        ,

        /** match labels as well as type. */
        match: (a1: Action, a2: Action) => {

            const matchtype = (a1.type === a2.type || a2.type === any || !a2.type)

            // matching type: same (including both any or missing), or action's is any or missing
            return matchtype && self.matchLabels(a1, a2)

        }

        ,

        /** at least one matches labels as well as type. */
        matchAny: (a: Action, actions: Action[]) => actions.some(aa => self.match(a, aa))

        ,


        /** at least one matches labels as well as type. */
        matchAnyOther: (a: Action, actions: Action[]) => actions.some(aa => self.match(a, aa) && !self.is(a, aa))

        ,

        /** matches labels, types, and resource */
        impliedBy: (a1: Action, a2: Action) => self.match(a1, a2) && (a1.resource === a2.resource || a2.resource === any || a2.resource === undefined)

        ,

        /** at least one matches labels, types, and resource */
        impliedByAny: (a: Action, actions: Action[]) => actions.some(aa => self.impliedBy(a, aa))

        ,

        /** same labels and same type. */
        isLike: (a1: Action, a2: Action) => self.match(a1,a2) && self.match(a2,a1)

        ,

        /** at least one has same labels and same type. */
        isLikeAny: (a: Action, actions: Action[]) => actions.some(aa => self.isLike(aa, a))

        ,

        /** same labels, same type, and same resource (deeply equals once externed). */
        is: (a1: Action, a2: Action) => utils().deepequals(self.extern(a1), self.extern(a2))

        ,

        /** at least one has same labels, same type, and same resource (deeply equals once externed). */
        isAny: (a: Action, actions: Action[]) => actions.some(aa => self.is(aa, a))

        ,

        stringify: (a: Action) => `${self.idOf(a)}:${a.resource}`

        ,

        // order by umplication, then lexicographically
        comparator: (a1: Action, a2: Action) => self.impliedBy(a1, a2) ? self.impliedBy(a2, a1) ? 0 : 1 : self.impliedBy(a2, a1) ? -1 : self.stringify(a2).localeCompare(self.stringify(a1))

        ,

        intern: (dto: ActionDto): Action => {

            const resolved = registry.lookup(dto)

            const merged: Action | undefined = resolved && { ...resolved, resource: dto.resource }

            const unknownAction: Action = { ...dto, name: self.idOf(dto), description: "unknown" }

            return merged || unknownAction

        }

        ,

        extern: ({ type, labels, resource }: Action): ActionDto => ({ type, labels, resource: (resource || any) })

        ,

        reconcile: <A extends Action>(actions: A[]): A[] => {

            // sort upfront from most generic to most specific, so that reconciliation can be treated in 'streaming' fashion
            const sorted = [...actions]

            sorted.sort(self.comparator)

            //  simply add one by one if not already implied by previous ones
            return sorted.reduce((acc, a) => self.impliedByAny(a, acc) ? acc : [a, ...acc], [] as A[])

        }

    }

    return self
}
