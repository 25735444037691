import debounce from 'lodash/debounce';
import React from 'react';




export const noop = () => {}

type AnyFunction = (...args:any[]) => any

// returns a stable proxy of any function, regardless of its original stability.
// if it's invoked with undefined returns a noop proxy.
export const useStable = <S extends AnyFunction> (fn: S = noop as S, props: PropertyDescriptorMap = {}) => {
   
    const ref = React.useRef<S>();
    
    ref.current = fn;

    return React.useMemo( ()=> {
        
        const wrapper = (...args: Parameters<S>) => ref.current?.(...args) as ReturnType<S>

        Object.defineProperties(wrapper,props);

        return wrapper
    
    // eslint-disable-next-line
    }, [])

}

// if it's invoked with undefined returns a noop proxy.
export const useDebounced = <S extends AnyFunction> (fn: S | undefined, delay: number) => {
   
     // creates a stable proxy of onChange that doesn't become stale.
     const stableFn = useStable(fn)

     // debounce it once for as long as delay doesn't change.
     return React.useMemo(() => 
     
         debounce(stableFn, delay)
         
     ,[stableFn,delay])
     
}