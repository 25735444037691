

import { useMode } from 'apprise-frontend-core/config/api';
import { useT } from "apprise-frontend-core/intl/language";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { useContext } from 'react';
import { useService } from './service';
import { sessionKey, useClientSession } from './session';
import { ClientContext } from "./state";



export const useCall = (target?: string) => {

    const state = useContext(ClientContext)

    const mode = useMode()
    const service = useService()

    const defaultOnError = useDefaultOnError()

    const session = useClientSession()

    // passes request condifguration through all methods with a callback.
    const request = (reqconfig: AxiosRequestConfig) => {


        state.get().config.onRequest

            // purges the undefined.
            .filter(i => i)

            .forEach(interceptor => interceptor!(reqconfig))

        const currentSession = session.get()


        if (currentSession)
            if (reqconfig.headers)
                reqconfig.headers[sessionKey] = currentSession
            else
                reqconfig.headers = { [sessionKey]: currentSession }

        return reqconfig

    }



    const error = (e: AxiosError): never => {

        // invokes all error handlers (app,modules,default), until one throws.
        [

            ...state.get().config.onError,

            defaultOnError

        ]

            // purges the undefined.
            .filter(i => i)

            .forEach(interceptor => interceptor(e))

        throw e;  //   throw it if no handler has yet (typechecker demands it).
    }

    // TODO: this cleanup connection error in dev mode when connecting to an http proxy via Vite.
    // for some reasons, NSCONNERROR are reported as 200 with empty responses. remove once we figure out
    // how to config the proxy to not do that. 
    const checkForProxyNoConnError = (r: AxiosResponse) => {

        if (!r.data && r.status !== 204 && mode.development)
            throw new Error("no proxy connection.")

        return r
    }

    const self = {

        fq: (path: string | RegExp, targetName?: string) => {

            const target = targetName ? service.get(targetName) : service.default();
            const prefix = target.prefix;
            return `${prefix}${path}`


        }

        ,

        at: (path: string, targetName: string | undefined = target) => self.atPath(self.fq(path, targetName)),



        atPath: (path: string) => ({

            get: <T>(config: RequestConfig = {}) => state.get().impl(request({ ...config, method: 'get', url: path })).then(checkForProxyNoConnError).catch(error).then(r => config.raw ? r : r.data) as Promise<T>,
            post: <T>(data?: any, config: RequestConfig = {}) => state.get().impl(request({ ...config, method: 'post', data, url: path })).catch(error).then(r => config.raw ? r : r.data) as Promise<T>,
            put: <T>(data?: any, config: RequestConfig = {}) => state.get().impl(request({ ...config, method: 'put', data, url: path })).catch(error).then(r => config.raw ? r : r.data) as Promise<T>,
            delete: (config: RequestConfig = {}) => state.get().impl(request({ ...config, method: 'delete', url: path })).catch(error).then(r => config.raw ? r : r.data) as Promise<void>,

        })




    }

    return self;

}

export type RequestConfig = AxiosRequestConfig & Partial<{

    raw: boolean
}>


// internal api: default handler of edge errors and server errors with 'canonical' {message,details} payload.  
const useDefaultOnError = () => {

    const t = useT()

    return (e: AxiosError<any>) => {

        const url = e.config?.url

        if (e.response) { //  if response is conventional, parse it. else use bare error message.

            // build a fallback message from url and available message.
            const baremsg = t("client.bare", { url, message: e.message })

            // uses the fallback unless it finds one in a `data` payload
            e.message = t(e.response.data?.message ?? baremsg)

            // force details as a custom property of axios errors
            e["details"] = e.response.data?.stacktrace ?? e.response.data ?? baremsg

        }
        else

            // builds a message in the lack of a response.
            e.message = `${e.request ? t("client.noresponse", { url }) : url ? t("client.norequest", { url }) : t("client.norequest_nourl")}: ${e.message}`

        throw e;
    }

}
