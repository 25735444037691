import { useL } from 'apprise-frontend-core/intl/multilang'
import { utils } from 'apprise-frontend-core/utils/common'
import { CategoryReference, TagCategory } from './category/model'
import { useCategoryStore } from './category/store'
import { Tag, TagReference } from './tag/model'
import { useTagStore } from './tag/store'



export const useTagCache = () => {

    const l = useL()

    const tags = useTagStore()
    const cats = useCategoryStore()

    const codecache: Record<CategoryReference, Record<string, Tag>> = {}
    const idcache: Record<CategoryReference, Record<TagReference, Tag>> = {}
    const catcache: Record<CategoryReference, TagCategory> = {}




    const catself = (category: CategoryReference) => ({

        // tag id -> tag
        id2tag: (tag: any | undefined) => {

            if (tag === undefined)
                return tag

            if (!idcache[category])
                idcache[category] = utils().index(tags.allTagsOfCategory(category)).by(t => t.id)

            return idcache[category][tag]

        }

        ,

        // tag code -> id || code
        code2tag: (code: string | undefined) => {

            if (code === undefined)
                return undefined


            if (!codecache[category]) {
                codecache[category] = utils().index(tags.allTagsOfCategory(category)).by(t => t.code?.toLowerCase?.() ?? t.code)
            }

            return codecache[category][code.toLowerCase?.() ?? code]

        }

        ,

        // tag code -> id || code
        code2id: (code: string | undefined, fallback: string = code!) => {

                return self.code2tag(code).of(category)?.id ?? fallback

            }
        

        ,

        // tag id -> code || id
        id2code: (id: any | undefined, fallback: string = id!) =>  {

                return self.id2tag(id).of(category)?.code ?? fallback

            }


        ,

        // tag id -> name || id
        id2name: (id: TagReference | undefined, fallback: TagReference = id!) => 

                l(self.id2tag(id).of(category)?.name) ?? fallback


    })


    const self = {

        of: catself,

        // tag id -> tag
        id2tag: (tag: any | undefined) => ({
            of: (category: CategoryReference) => self.of(category).id2tag(tag)
        })
        ,

        // tag code -> id || code
        code2tag: (code: string | undefined) => ({
            of: (category: CategoryReference) => self.of(category).code2tag(code)
        })

        ,

        // tag code -> id || code
        code2id: (code: any | undefined, fallback: string = code ? `${code}` : code) => ({
            of: (category: CategoryReference) => self.of(category).code2id(code,fallback)
        })

        ,

        // tag id -> code || id
        id2code: (id: TagReference | undefined, fallback: string = id!) => ({
            of: (category: CategoryReference) => self.of(category).id2code(id,fallback)
        })


        ,

        // tag id -> name || id
        id2name: (id: TagReference | undefined, fallback: TagReference = id!) => ({
            of: (category: CategoryReference) => self.of(category).id2name(id,fallback)
        })

        ,

        // cat id -> name || id
        id2catname: (category: CategoryReference, fallback: CategoryReference = category) => {

            const cat = self.id2cat(category)

            return cat ? l(cat.name) : fallback

        }

        ,

        id2cat: (category: CategoryReference) => {

            if (!catcache[category])
                catcache[category] = cats.lookupCategory(category)!


            return catcache[category]
        }


    }


    return self

}