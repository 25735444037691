import { Language } from './language'



// as it slots in the global configuration file.
export type RemoteIntlConfiguration = {

    intl: Partial<IntlConfiguration>
}

export type IntlConfiguration = {

    path: string
    defaultLanguage: Language
    supportedLanguages: Language[]
    requiredLanguages: Language[]
}


export const defaultIntlConfiguration: IntlConfiguration = {


    path: 'locale/{{lng}}_translation.json',
    defaultLanguage: 'en',
    supportedLanguages: ['en'],
    requiredLanguages: ['en']

}




