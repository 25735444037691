import { classname, StyledContainer } from 'apprise-ui/component/model'
import * as React from 'react'
import './placeholder.scss'


type Props = React.PropsWithChildren<StyledContainer & Partial<{
    visible: boolean
}>>

export const Placeholder = (props: Props) => {

    const {children, className, innerClassName, style, innerStyle, visible=true} = props

    return <div style={style} className={classname('apprise-placeholder-container', className)}>
        <div className={classname('placeholder', visible && 'placeholder-visible', innerClassName)} style={innerStyle}>
            {children}
        </div>
    </div>

}