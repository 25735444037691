import { useLanguage, useT } from 'apprise-frontend-core/intl/language'
import { useL } from 'apprise-frontend-core/intl/multilang'
import { useWorkbookWriter } from 'apprise-frontend-parse/workbookWriter'
import { TagCategory } from 'apprise-frontend-tags/category/model'
import { downloadParam } from 'apprise-frontend-tags/category/routing'
import { useCategoryStore } from 'apprise-frontend-tags/category/store'
import { tagType } from 'apprise-frontend-tags/constants'
import { useCustomTagProperties } from 'apprise-frontend-tags/customprop/api'
import { Tag, useTagModel } from 'apprise-frontend-tags/tag/model'
import { Button } from 'apprise-ui/button/button'
import { ChoiceBox } from 'apprise-ui/choicebox/choicebox'
import { useRoutableDrawer } from 'apprise-ui/drawer/drawer'
import { Form } from 'apprise-ui/form/form'
import { TableContext } from 'apprise-ui/table/context'
import { unavailableData, useTableData } from 'apprise-ui/table/provider'
import { Explainer, ExplainerPanel } from 'apprise-ui/utils/explainer'
import { DownloadIcon } from 'apprise-ui/utils/icons'
import format from 'date-fns/format'
import { useContext, useMemo, useState } from 'react'
import { useTagStore } from './store'

export const useDownloadDrawer = (props?: Partial<{

    category: TagCategory,
    filename: string
}

>) => {

    const t = useT()
    const l = useL()

    const tags = { ...useTagModel(), ...useTagStore() }

    const { category, filename } = props ?? {}

    const alltags = category ? tags.allTagsOfCategory(category.id) : tags.allTags()

    // eslint-disable-next-line
    const sortedTags = useMemo(() => [...alltags].sort(tags.codeComparator), [alltags])

    const { Drawer, openSet } = useRoutableDrawer(downloadParam)

    // selected data first, then filtered if none selected, then all if none filtered or if table not mounted yet.
    const rows = useTableData<Tag>(tagType)
    const rowsInFocus = rows === unavailableData ? sortedTags : rows.selected.length ? rows.selected : rows.data

    const count = rowsInFocus.length

    const defaultFilename = [

        l(category?.name),

        `${t('tag.plural').toLowerCase()} (${count})`,

        format(Date.now(), "P")

    ].filter(n => !!n).join('_').replaceAll(' ', '-')


    const filenameOrDefault = filename ?? defaultFilename

    const { layout } = useContext(TableContext).get()[tagType] ?? {}

    const allLangs = useLanguage().supported()

    const multilang = allLangs.length > 1

    const { download, mode, modeSet } = useDownload({ data: rowsInFocus, layout, category, filename: filenameOrDefault, mode: 'current' })

    const drawer = multilang ? <Drawer icon={<DownloadIcon />} width={450} noReadonly title={t('tag.download_title', { count })} {...props}>
        <DownloadPanel download={download} mode={mode} changeMode={modeSet} />
    </Drawer> : null

    const btn = <Button noReadonly icon={<DownloadIcon />} onClick={() => multilang ? openSet(true) : download()} >{t('tag.download', { count })}</Button>

    return { drawer, btn }
}


type DownloadPanelProps = {

    data: Tag[]
    category?: TagCategory
    layout?: string[]
    filename: string

}


type ModeType = 'current' | 'all'


const useDownload = (props: DownloadPanelProps & { mode: ModeType }) => {

    const t = useT()

    const { data, mode: initialMode, category, filename } = props

    const lng = useLanguage().current()

    const allLangs = useLanguage().supported()

    const [mode, modeSet] = useState<ModeType>(initialMode)

    const cats = useCategoryStore()

    const customprops = useCustomTagProperties()

    const writer = useWorkbookWriter()

    const download = () => {

        const excel = writer.sheetOf(data as Tag[]).text(t('tag.code_col')).render(t => t.code)

        if (mode === 'all') {
            allLangs.forEach(l => excel.text(`${t('tag.tag_col')}_${l}`).render(t => t.name[l]))
            allLangs.forEach(l => excel.text(`${t('tag.description_col')}_${l}`).render(t => t.description[l]))
        } else {
            excel.text(t('tag.tag_col')).render(t => t.name[lng])
            excel.text(t('tag.description_col')).render(t => t.description[lng])
        }


        if (!category) {

            if (mode === 'all') {
                allLangs.forEach(l => excel.text(`${t('tag.tag_category_col')}_${l}`).render(t => cats.safeLookupCategory(t.category)?.name?.[l]))
            } else {
                excel.text(t('tag.tag_category_col')).render(t => cats.safeLookupCategory(t.category)?.name?.[lng])
            }
        }

        if (category) {
            customprops.propertiesFor(category.id).map(customprops.lookupType).filter(v => v.PrintColumn).forEach(c => {

                const { value, title, type = 'text' } = c.PrintColumn!

                excel.column(title).type(type).render(t => value(t))

            })
        }

        excel.text(t('ui.lifeycle_state')).render(tag => t(tag.lifecycle.state === 'active' ? 'tag.active' : 'tag.inactive'))

        excel.as(t('tag.plural')).save(`${filename}.xlsx`)
    }

    return { mode, modeSet, download }

}


const DownloadPanel = (props: { download: () => any, mode: ModeType, changeMode: (mode: ModeType) => void }) => {

    const t = useT()

    const lng = useLanguage().current()

    const { download, changeMode, mode } = props

    return <ExplainerPanel className='download-panel'>

        <Explainer icon={<DownloadIcon />}>{t('tag.download_explainer', { lng })}</Explainer>

        <Form centered>


            <ChoiceBox radio value={mode} onChange={v => changeMode(v!)}>
                <ChoiceBox.Option value='current' title={t('tag.download_current_language', { lng })} />
                <ChoiceBox.Option value='all' title={t('tag.download_all_langs')} />
            </ChoiceBox>


            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 25 }}>
                <Button className='download-btn' type='primary' icon={<DownloadIcon />} onClick={download}>
                    {t("tag.download_now")}
                </Button>
            </div>


        </Form>

    </ExplainerPanel>

}