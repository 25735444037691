import { CSSProperties, ReactNode } from "react";




// common property models.

export type Styled = Partial<{

    className: string
    style: CSSProperties

}>


export type StyledContainer = Styled & Partial<{

    innerClassName: string
    innerStyle: React.CSSProperties

}>

export type Wide = Partial<{

    width: number | string 
    minWidth: number | string                       

}>

export type Tall = Partial<{

    height: number | string,
    minHeight: number | string                       

}>

export type Size = 'small' | 'large' | 'normal'



export const antsizeOf = (size:Size|undefined) =>  size==='normal' || !size ? undefined: size

export type Sized<T extends Size = Size> = Partial<{

    size: T
}>



export type Disabled = Partial<{

    enabled: boolean
    disabled: boolean
}>


export type Iconed = Partial<{

    icon: ReactNode

}>

export type Ranged = Partial<{

    min: number
    max: number
    
    range: {
        min: number
        max: number
    }
}>


export type Debugged = Partial<{

    debug: boolean
}>

export type Clicked = Partial<{

    onClick: (e?: React.MouseEvent) => void
}>


// implemented by components that adopt a memoization strategy on behalf of clients-
export type Memoized = Partial<{

    // change to trigger a refresh.
    touch: any

    // disable the strategy.
    noMemo:boolean

}>


export type Resettable = Partial<{

    resetSignal: boolean

}>

// utils

export const pruned = ($: (string | undefined | boolean)[]) => $.filter(c => c && c!==true)
export const join = ($: (string | undefined | boolean)[]) => $.join(' ') || undefined
export const classname = (...$: (string | undefined | boolean)[]) => join(pruned($))!