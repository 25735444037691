import * as React from 'react';
import { EventConnectionContext } from './context';



export const useEventBus = () => {

    const connectionstate = React.useContext(EventConnectionContext)

    const self = {

        publish: <T extends any> (topic: string, data: T) => {

            console.log(`publishing ${topic} event`,data)

            const connection = connectionstate.get().connection
            const codec = connectionstate.get().codec

            connection && !connection.isClosed() && connection?.publish(topic, codec.encode(data))
        }

    }


    return self

}