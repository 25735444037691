import { useComponentProps } from 'apprise-ui/component/component'
import { classname, Disabled, Styled } from 'apprise-ui/component/model'
import { Readonly } from 'apprise-ui/readonly/model'
import { useReadonlyProps } from 'apprise-ui/readonly/readonly'
import * as React from "react"
import { useDrag } from "react-dnd"
import { defaultItemType } from "./model"

type DraggableProps<T> = Styled & Disabled & Readonly & Partial<{

    type: string
    item?: T

    children?: React.ReactNode | ( ({isDragging}:{isDragging:boolean})=> React.ReactNode)
}>

export const Draggable = <T =any>(props: DraggableProps<T>) => {

    const { item, type= defaultItemType, className, style, disabled } = useComponentProps(props)

    const { readonly } = useReadonlyProps(props)

    const inactive = disabled || readonly

    const [childprops, drag] = useDrag({

        canDrag: !inactive,

        item: {data:item},

        type,

        collect: dnd => ({

            isDragging: !!dnd.isDragging()

        }),
    })

    const ref = React.useRef<HTMLDivElement>(null)

    drag(ref)

    const {isDragging } = childprops

    const children = typeof props.children === 'function' ? props.children (childprops) : props.children

    const classes = classname('draggable',className,isDragging && 'dragging')

    return <div ref={ref} style={style} className={classes} >
        {children}
    </div>

}


export type DragProps = {

    isDragging: boolean
}
