import { useCall } from 'apprise-frontend-core/client/call'
import { useCrud } from 'apprise-ui/utils/crudtask'
import { permissionPlural } from './constants'
import { PermissionDto, usePermissions } from './model'
import { ActionDto } from 'apprise-frontend-core/authz/action'

const svc = "admin"
export const permissionApi = "/permission"

export type PermissionFilter = Partial<{

    subjects: string[]
    actions: ActionDto[]
    types: string[]
    resources: string[]
}>


export type ChangesDto = {

    granted?: PermissionDto[]
    revoked?: PermissionDto[]
}



export const usePermissionStore = () => {

    const call = useCall()
    const permissions = usePermissions()

    const crud = useCrud({ plural: permissionPlural })

    return {

        search: crud.fetchAllWith(async (filter: PermissionFilter) => {

            const fetched = (await call.at(`${permissionApi}/search`, svc).post<PermissionDto[]>(filter))

            const interned = fetched.map(permissions.intern)

            const reconciled = permissions.reconcile(interned)

            return reconciled

        })
            .with(config => config.show('permission.loading'))
            .done()

        ,

        update: crud.saveManyWith(async (changes: ChangesDto) =>

            call.at(permissionApi, svc).put<void>(changes))

            .done()

    }
}