import { useCall } from 'apprise-frontend-core/client/call'
import { usePreload } from 'apprise-frontend-core/client/preload'
import { Multilang } from 'apprise-frontend-core/intl/multilang'
import { Tenant, useNewTenant, useTenantModel } from './model'



const svc="admin"
export const tenantApi = "/tenant"
export const tenantRefApi = "/tenantrefs"

export type TenantRef = {

    id: string
    name: Multilang,
    description: Multilang
    code: string
  
}

export const useTenantCalls = () => {

    const call = useCall()
    const {intern,extern} = useTenantModel()

    const newTenant = useNewTenant()

    const preload = usePreload()


    const self = {

         // come with no permissions, so won't need to be interned.
        fetchAllTenants: () => preload.get<Tenant[]>(tenantApi) ?? call.at(tenantApi,svc).get<Tenant[]>()

        ,

        fetchAllRefs:  () : Promise<Tenant[]> => (preload.get<Tenant[]>(tenantRefApi) ?? call.at(tenantRefApi,svc).get<Tenant[]>()).then(refs => refs.map(ref => ({...newTenant(), ...ref})))

        ,

        fetchOne: (id: string) =>  call.at(`${tenantApi}/${id}`,svc).get<Tenant>().then(intern)

        ,

        add: (tenant:Tenant) =>  call.at(tenantApi,svc).post<Tenant>(extern(tenant)).then(intern)
    
        ,
    
        update: (tenant:Tenant) => call.at(`${tenantApi}/${tenant.id}`,svc).put<Tenant>(extern(tenant)).then(intern)
    
        , 

        delete: (id:string) => call.at(`${tenantApi}/${id}`,svc).delete()
    }

    return self;

}
