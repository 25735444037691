
import { UserIconFull } from 'apprise-frontend-iam/user/constants';
import { DateIcon, EditIcon, UploadIcon } from 'apprise-ui/utils/icons';
import { CSSProperties } from 'react';
import { IconBaseProps } from 'react-icons';
import { AiFillStop } from 'react-icons/ai';
import { BiCalendar } from 'react-icons/bi';
import { BsTable } from 'react-icons/bs';
import { FiArrowRightCircle } from 'react-icons/fi';
import { GiCycle } from 'react-icons/gi';
import { GoGlobe } from 'react-icons/go';
import { MdCancel, MdSupervisedUserCircle } from 'react-icons/md';
import { RiUserFollowFill } from 'react-icons/ri';


export const submissionType = 'submission'

export const submissionSingular = "sub.singular"
export const submissionPlural = "sub.plural"


export const submittedColor: CSSProperties['color'] = 'dodgerblue'
export const publishedColor: CSSProperties['color'] = 'deepskyblue'
export const awaitingColor: CSSProperties['color'] = 'darkorange'
export const approvedColor: CSSProperties['color'] = 'lightseagreen'
export const draftColor: CSSProperties['color'] = 'lightseagreen'
export const rejectedColor: CSSProperties['color'] = 'orangered'
export const revokedColor: CSSProperties['color'] = 'orange'

export const SubmissionStateIcon = FiArrowRightCircle
export const SubmissionTargetIcon = BiCalendar
export const SubmissionPeriodIcon = GiCycle
export const SubmissionDateIcon = DateIcon
export const SubmissionIcon = UploadIcon

export const SubmissionOfficialIcon = (_: IconBaseProps) => <UserIconFull color={_.color ?? 'lightgrey'} {..._} />

export const SubmissionDraftIcon = (_: IconBaseProps) => <EditIcon color={_.color ?? draftColor} {..._} />
export const SubmissionSubmittedIcon =(_: IconBaseProps) => < SubmissionIcon color={_.color ?? submittedColor} {..._} />
export const SubmissionPublishedIcon = (_: IconBaseProps) => <GoGlobe color={_.color ?? publishedColor} {..._} />
export const SubmissionRevokedIcon = (_: IconBaseProps) => <AiFillStop color={_.color ?? revokedColor} {..._} />
export const SubmissionRejectedIcon = (_: IconBaseProps) => <MdCancel color={_.color ?? rejectedColor} {..._} />
export const SubmissionAwaitingIcon = (_: IconBaseProps) => <MdSupervisedUserCircle color={_.color ?? awaitingColor} {..._} />
export const SubmissionApprovedIcon = (_: IconBaseProps) => <RiUserFollowFill color={_.color ?? approvedColor} {..._} />

export const AggregateTableIcon = (_: IconBaseProps) => <BsTable {..._} />

export const generalTab = 'general'
export const operationTab = 'operations'
export const tableOneTab = 'tableone'
export const tableFourTab = 'tablefour'
export const tableFiveTab = 'tablefive'
export const tableNineTab = 'tablenine'

export const submissionResourceCategory = 'TGC-submission-resource'
export const submissionTargetCategory = 'TGC-submission-target'
export const submissionPeriodCategory = 'TGC-submission-period'



export const operationsFilterContext = 'submission-ops'
export const operationsStatusContext = 'submission-ops-status'