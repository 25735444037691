import * as Antd from 'antd'
import { Component, useDeferredComponentProps } from 'apprise-ui/component/component'
import { classname, Debugged, Disabled } from 'apprise-ui/component/model'
import { Readonly } from 'apprise-ui/readonly/model'
import { useDeferredReadonlyProps } from 'apprise-ui/readonly/readonly'
import * as React from 'react'
import { DownIcon } from '../utils/icons'
import { Button, ButtonProps } from './button'
import "./styles.scss"


export type Props = React.PropsWithChildren<Debugged & Partial<{

    type: 'default' | 'ghost'

    noHighlight: boolean
    highlight?: ButtonProps['type'] | ((props: Partial<ButtonProps>) => boolean)

}>>


export const ButtonMenu = ($: Props) => {

    // recognize button-like children: Linked and with a child content to show as option. 
    // if they're also Iconed, then we make use of it.
    const buttonLike = (c: any) => c?.children

    // extract all relevant properties
    const relevantProps = ($: any): Partial<ButtonProps> => ({
        icon: $.icon,
        disabled: $.disabled,
        enabled: $.enabled,
        readonly: $.readonly,
        noReadonly: $.noReadonly,
        linkTo: $.linkTo,
        onClick: $.onClick,
        type: $.type,
        size: $.size,
        children: $.children
    })

    // menu entries from chilren
    const children = React.Children.toArray($.children)
    const childprops = children.map((c: any) => c.props).filter(buttonLike).map(relevantProps)
    const toHighlight = (props: any) => typeof $.highlight === 'function' ? $.highlight(props) : props.type === ($.highlight ?? 'primary')
    const highlighted = $.noHighlight ? -1 : childprops.findIndex(toHighlight)

    let highlightedProps

    if (highlighted >= 0)
        highlightedProps=childprops.splice(highlighted, 1)[0]

    const { getComponentProps } = useDeferredComponentProps()
    const { getReadOnlyProps } = useDeferredReadonlyProps()

    const isDisabled = (c: Disabled & Readonly) => getComponentProps(c).disabled || getReadOnlyProps(c).readonly

    const menu = <Antd.Menu items={ childprops.map((c, key) =>(
        
        {key, label: <Button className='buttonmenu-entry'  {...c} type='ghost'>{c.children}</Button>}
        
    ))}/>

    // if all entries are disabled, we style accordingly the dropdown itsef.
    const allDisabled = !childprops.some(e => !isDisabled(e))

    // but Antd doesn't open a disabled dropdown, whereas we want to open the menu in all cases (readonly included).
    // so we don't make the dropdown disabled, we style the caret icon instead.

    const dropdown = <Antd.Dropdown trigger={['click']} overlayClassName="buttonmenu-entries" className='buttonmenu-caret' overlay={menu}>
        <span>{<DownIcon/>}</span>
    </Antd.Dropdown>

    
    return <Component name="buttonmenu" className={classname(`${allDisabled ? 'buttonmenu-disabled' : ''}`,`buttonmenu-${$.type}`)} noReadonly {...$}>

        {highlighted >= 0 ?

            <div className={classname('apprise-row','buttonmenu-highlight', `buttonmenu-highlight-${highlightedProps?.size}`)}>
                {children[highlighted]}
                {dropdown}
            </div>

            :

            dropdown
        }
    </Component>

}
