import { createContext, PropsWithChildren } from 'react';



export const MockHorizonContext = createContext<boolean>(false)


export const MockHorizon = (props: PropsWithChildren<{}>) => {

    const {children} = props

    return <MockHorizonContext.Provider value={true}> 
        {children}
    </MockHorizonContext.Provider>
}