import { fallbackStateOver, State } from "apprise-frontend-core/state/api"
import { StateProvider } from "apprise-frontend-core/state/provider"
import { CategoryReference } from 'apprise-frontend-tags/category/model'
import React, { createContext, PropsWithChildren, useContext } from "react"
import { Tag, TagReference } from './model'
import { TaggedModule } from './modules'



// cache


export type TagCache = {

    all: Tag[]
}

export const initialTagCache: TagCache = {

    all: [],
}

export const TagCacheContext = React.createContext<State<TagCache>>(undefined!)


// routing


export type ExternalTagCache = {

    all: Record<CategoryReference, Record<TagReference, Tag>>
    allCodes: Record<CategoryReference, Record<string, Tag>>

}

const initialExternalTagCache: ExternalTagCache = {

    all: {},
    allCodes: {}
}


export const ExternalTagCacheContext = createContext<State<ExternalTagCache>>(fallbackStateOver(initialExternalTagCache))



// modules

export const TagModuleContext = React.createContext<State<TagModuleState>>(undefined!)

export const initialTagModuleState: TagModuleState = {

    all: [],
}


export type TagModuleState = {

    all: TaggedModule[]

}


// routing


export type TagRoutingState = {

    nextTag: Tag

}

export const TagRoutingContext = createContext<State<Partial<TagRoutingState>>>(undefined!)

const initialRoutingState: Partial<TagRoutingState> = {}


export const useTagContext = () => {

    const state = useContext(TagCacheContext)

    if (!state)
        throw new Error("tag module is not mounted or activated.")

    return state
}

export const TagProvider = (props: PropsWithChildren<{}>) => {

    return <StateProvider initialState={initialExternalTagCache} context={ExternalTagCacheContext}>
        <StateProvider initialState={initialTagCache} context={TagCacheContext}>
            <StateProvider initialState={initialTagModuleState} context={TagModuleContext}>
                <StateProvider initialState={initialRoutingState} context={TagRoutingContext}>
                    {props.children}
                </StateProvider>
            </StateProvider>
        </StateProvider>
    </StateProvider>
}