import { useT } from 'apprise-frontend-core/intl/language'
import { useL } from 'apprise-frontend-core/intl/multilang'
import { utils } from 'apprise-frontend-core/utils/common'
import { useCategoryFilter } from 'apprise-frontend-tags/category/filter'
import { TagCategory } from 'apprise-frontend-tags/category/model'
import { useCategoryRouting } from 'apprise-frontend-tags/category/routing'
import { useCategoryStore } from 'apprise-frontend-tags/category/store'
import { tagType } from 'apprise-frontend-tags/constants'
import { useCustomTagProperties } from 'apprise-frontend-tags/customprop/api'
import { TagLabel, TagTypeLabel } from 'apprise-frontend-tags/tag/label'
import { Tag, useTagModel } from 'apprise-frontend-tags/tag/model'
import { Label } from 'apprise-ui/label/label'
import { useSelection } from 'apprise-ui/table/selection'
import { SortSpec } from 'apprise-ui/table/sorting'
import { Table } from 'apprise-ui/table/table'
import { useTableUtils } from 'apprise-ui/table/utils'
import { cloneElement, Fragment, useMemo } from 'react'
import { CategoryLabel } from '../category/label'
import { useCategoryUtils } from '../category/utils'
import { useTagTypeFilter } from './filter'
import { useTagModules } from './modules'
import { useTagStore } from './store'

type TagListProps = Partial<{

    category: string | TagCategory

}>

export const TagTable = (props: TagListProps) => {

    const t = useT()
    const l = useL()

    const { category } = props

    const cats = { ...useCategoryStore(), ...useCategoryUtils(), ...useCategoryRouting() }
    const store = useTagStore()
    const model = useTagModel()
    const modules = useTagModules()

    const { compareStringsOf, compareMultilangOf, Column } = useTableUtils<Tag>()

    const cat = category ? typeof category === 'string' ? cats.safeLookupCategory(category) : category : undefined

    const customprops = useCustomTagProperties()

    const allTags = store.allTags()

    const categoryId = cat?.id

    const unfilteredTags = useMemo(() => allTags.filter(t => categoryId ? t.category === categoryId : true), [allTags, categoryId])


    const { typeFilter, typeFiltered } = useTagTypeFilter({

        categories: unfilteredTags,
        context: tagType
    })

    const { CategoryFilter, categoryFilteredTags } = useCategoryFilter({

        initial: unfilteredTags,
        filtered: typeFiltered

    })

    const data = categoryFilteredTags

    const initialSort: SortSpec[] = [{ key: 'cat', mode: 'asc' }, { key: 'name', mode: 'asc' }]

    const allModules = modules.all()

    // eslint-disable-next-line
    const typemap = useMemo(() => utils().index(allModules).mappingBy(m => m.type, m => t(m.singular)), [allModules])


    return <Table selection={useSelection()} name={tagType} mountDelay context={tagType} data={data} total={unfilteredTags.length} initialSort={initialSort} >

            <Table.Filter name="type">
                {typeFilter}
            </Table.Filter>

            <Table.Filter name="category">
                {CategoryFilter}
            </Table.Filter>

            <Column pinned defaultLayout width={350} name="name" text={t => t.name} sort={model.comparator} title={t('tag.tag_col')} render={t =>
                category ?
                    <TagLabel linkTo={cats.tagInCategoryRoute(categoryId!, t.id)} tag={t} />
                    :
                    <TagLabel tag={t} />} />

            <Column defaultLayout={!category} width={200} name="cat" filter="category" text={t => (cat ?? cats.lookupCategory(t.category))?.name} sort={(o1, o2) => cats.compareRef(o1.category, o2.category)} title={t('tag.tag_category_col')} render={t => <CategoryLabel tag={t} />} />

            <Column defaultLayout name="description" width={350} text={t => t.description} sort={compareMultilangOf(t => t.description)} title={t('tag.description_col')} render={t => l(t.description)} />

            <Column defaultLayout={!category} width={160} name="type" filter="type" text={t => typemap[t.type]} sort={compareStringsOf(t => t.type)} title={t('tag.type_col')} render={t => <TagTypeLabel type={t.type} />} />

            <Column defaultLayout width={80} name="code" sort={compareStringsOf(t => t.code)} title={t('tag.code_col')} render={t => t.code && <Label noIcon mode='tag' title={t.code} />} />

            {cat &&

                <Fragment>{
                    customprops.propertiesFor(cat.id).map(customprops.lookupType).filter(v => v.Column).map(({ Column, id }) => cloneElement(Column!(), { key: id }))



                }</Fragment>
            }


        </Table>

}

