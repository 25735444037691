import { useContext } from 'react'
import { InltContext, useIntlConfig } from './state'


export type Language = "fr" | "en" | "ar" | "zh" | "es" | "ru"

export const allLanguages: Language[] = [ "en", "fr", "es", "zh", "ar", "ru"]

export const languageOf = (lang:Language) => `intl.${lang}`

export const useT = () => useLanguage().t

export const useLanguage = () => {

    const state = useContext(InltContext)

    const config = useIntlConfig()

    const self = {

        t: (key:string | string[],...args:any[]) => key ? state.get().t(key,...args) : key

        ,

        // switches language in the implementaton and and updates state to trigger a rerender.
        // may pull new translations over the network.
        // so must chain promise or rerender may come before tfunction is ready.
        change: (language: Language) =>  
        
            state.get().changeLanguage(language).then(()=>state.set(s => s.language = language))

        ,

        current: () => state.get().language

        ,

        default: () => config.defaultLanguage

        ,

        supported: () => config.supportedLanguages

        ,

        required: () => config.requiredLanguages

        ,

        // all supported languages
        all: () => allLanguages

        ,

        // returns the key that translates the name of the language.
        of: (lang: Language) => `intl.${lang}`


    }

    return self

}
