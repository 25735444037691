import * as Ant from "antd"
import { Linked } from 'apprise-ui/link/model'
import { Readonly } from 'apprise-ui/readonly/model'
import { useReadonlyProps } from 'apprise-ui/readonly/readonly'
import { Tipped } from 'apprise-ui/tooltip/model'
import * as React from "react"
import { Component, useComponentProps } from "apprise-ui/component/component"
import { classname, Clicked, Disabled, Iconed, Sized, Styled, StyledContainer } from "../component/model"
import "./styles.scss"



export type ButtonProps = StyledContainer & Styled & Sized & Disabled & Readonly & Linked & Tipped & Iconed & Clicked & Partial<{

    type: 'primary' | 'danger' | 'ghost' | 'normal' | 'link'

    iconPlacement: 'left' | 'right'

    dot: boolean

    children: React.ReactNode

    noLabel: true    

    href: string
    rel: string
    target: string
}>


export const Button = (props: ButtonProps) => {

    const { href, rel, target, dot, icon, className, innerStyle, onClick, size,  innerClassName, noLabel, children=noLabel ? '' : 'label', iconPlacement = 'right', type = "normal", ...rest } = props


    const { disabled } = useComponentProps(rest)
    

    // readonly state will map onto disabled for buttons.
    const { readonly } = useReadonlyProps(props)
   
    const antsize = size === 'normal' ? undefined : size
    const anttype = type === 'danger' || type === 'normal' ? undefined : type

    let contents = <div className='apprise-row' >
        {iconPlacement === 'left' && icon}
        {children && (icon ? <span>{children}</span> : children)}
        {iconPlacement === 'right' && icon}
    </div>

    if (dot)
        contents = <Ant.Badge offset={[5, 2]} dot>{contents}</Ant.Badge>

    const classes = classname(className,`apprise-button-${type}`)

    const disabledOrReadonly = disabled || readonly

    return <Component name="button" componentClassName={classes} {...rest} disabled={disabledOrReadonly}  >
        <Ant.Button style={innerStyle} className={innerClassName}
            disabled={disabledOrReadonly}
            danger={type === 'danger'} type={anttype}
            size={antsize}
            href={href} rel={rel} target={target}
            onClick={onClick}>
                {contents}
        </Ant.Button>
    </Component>
}


export const ButtonRow = ($: React.PropsWithChildren<{}>) => <Component name="button-row" {...$}>{$.children}</Component>
