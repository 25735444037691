import { useT } from 'apprise-frontend-core/intl/language'
import { Button } from 'apprise-ui/button/button'
import React from 'react'
import { BiUndo } from "react-icons/bi"
import { Fielded } from './model'
import { AdditionalProperties } from './field'


type ResetHelperProps<T extends any = any> = {

    value?: T | undefined
    onReset?: () => any

}

export const useResetHelper = <T extends any>( props: Fielded<T> & AdditionalProperties, $?: ResetHelperProps<T>) => {

    const t = useT()

    const { 

        value=props.children,
        onReset = () => {
            props.onChange?.(undefined!)
            props.flushDebouncedChange()
            
        }
    
    } = $ ?? {}

    const { defaultValue, readonly, focusDecorations = [] } = props

    const resettableOrDefault = !readonly && defaultValue !== undefined && (value === undefined ? defaultValue : value) !== defaultValue

    props.debug && console.log("reset-helper", { resettableOrDefault, readonly, value, defaultValue })

    if (resettableOrDefault)
        focusDecorations.push(

            <Button noReadonly type='ghost' className='suffix-reset-default-btn' tipDelay={1.2} tipPlacement='bottomRight' tip={t('ui.reset_default')} onClick={onReset}>
                <BiUndo />
            </Button>
        )

}