import { useConfig } from 'apprise-frontend-core/config/api'



export type RemoteUserConfiguration = { user: Partial<UserConfiguration> }

export type UserConfiguration = {

    loadSummaries: boolean

}

export const defaultUserConfiguration : UserConfiguration = {

    loadSummaries: false
}

export const useUserConfiguation = () => {

    const config = useConfig<RemoteUserConfiguration>()

    return{ ...defaultUserConfiguration, ...config.user }
}
