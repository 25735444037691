import { Checkbox as AntCheckbox, Radio } from "antd"
import { CheckboxOptionType } from 'antd/lib/checkbox'
import { utils } from 'apprise-frontend-core/utils/common'
import { classname, Disabled, Styled, Wide } from 'apprise-ui/component/model'
import { useChangeHelper } from 'apprise-ui/field/changehelper'
import { Field, useFieldProps } from 'apprise-ui/field/field'
import { ChangeTracked, Fielded } from 'apprise-ui/field/model'
import { useReadonlyHelper } from 'apprise-ui/field/readonlyhelper'
import { useResetHelper } from 'apprise-ui/field/resethelper'
import { ReactNode, useRef, useState } from 'react'
import './styles.scss'

export type CheckboxValue = string | number | boolean

export type Union = CheckboxValue | CheckboxValue[]


export type ChoiceboxProps<T extends Union> = Fielded<T> & ChangeTracked<T> & Styled & Wide & Partial<{

    inline: boolean

    children: JSX.Element | JSX.Element[]
    radio: boolean

    value: T
}>



export const ChoiceBox = <T extends CheckboxValue> (props: ChoiceboxProps<T>) => <PolyChoiceBox {...props} />


export const MultiChoiceBox = <T extends CheckboxValue> (props: ChoiceboxProps<T[]>) => <PolyChoiceBox<T[]> multi {...props} />


type PolyProps<T extends Union> = ChoiceboxProps<T> & {
    multi?: boolean
}

const PolyChoiceBox = <T extends Union>(clientprops: PolyProps<T>) => {

    const props = useFieldProps(clientprops)

    const { multi, radio, inline, onChange } = props

    // only single value may have radio semantic.
    const checkmode = multi || !radio

    const options = utils().elementsIn(props.children!)
        .filter(utils().isElementOf(ChoiceBox.Option))
        .map(c => c?.props as OptionProps)
        .map((option, i): CheckboxOptionType => ({

            label: option.noTitle ? undefined : option.title ?? `option ${i}`,
            value: option.value,
            disabled: option.disabled || option.enabled === false

        }))


    // controls the applicability of defaults as fallback for missing value.
    const defaultingMode = useRef(true)

    // forces rendering in corner case of deselecting a default when model is already undefined.
    const [, forceRender] = useState(false)

    // harmonises defaults to arrays, and for radios forces a default from first option.
    const normalisedDefault = arrayof(props.defaultValue) ?? (radio ? arrayof(options[0]?.value) : undefined)

    // harmonises value to array.
    const normalisedValue = arrayof(props.value) as CheckboxValue[]

    const normalisedPastValue = arrayof(clientprops.pastValue) as CheckboxValue[]

    // falls back to default in defaulting mode. 
    const valueOrDefault = normalisedValue ?? (defaultingMode.current ? normalisedDefault : undefined)

    const currentValue = valueOrDefault as any

    const { pastMode, pastValue } = useChangeHelper(props, { currentValue, pastValue: normalisedPastValue })

    const latestValue = pastMode ? pastValue : currentValue

    const change = (v: CheckboxValue[], resetDefault?: boolean) => {

        // cannot disable focus with CSS, so options can still be activated through keyboard. opt out in code then. 
        if (props.readonly)
            return

        // unless we're explicitly resetting the default, 
        // exit defaulting mode because the user has just made a non-default choice.
        defaultingMode.current = !!resetDefault

        // tricky corner case: forces a render on `undefined` to deselect the default. 
        //  react would skip the transition `undefined` -> `undefined` otherwise.
        if (missing(v) && missing(normalisedValue))
            forceRender(v => !v)


        else {

            // in single mode, picks first new element (or removes an existing element)
            const newValue = multi ? v : v?.find(vv => !normalisedValue?.includes(vv))

            onChange?.(newValue as T)

        }

    }

    useReadonlyHelper(props)


    useResetHelper({...props, defaultValue: props.defaultValue ? normalisedDefault : undefined as any}, {

        onReset: () => change(undefined!, true),

        value: currentValue
    })


const classes = classname(props.className, inline ? 'choicebox-inline' : 'choicebox-vertical')

const commonProps = {

    style: props.innerStyle,
    className: props.innerClassName,
    options,
    disabled: props.disabled
}


// console.log("value", valueOrDefault, "reset", defaultingMode.current)

const content = checkmode ?

    <AntCheckbox.Group {...commonProps}
        onChange={change} // always an array.
        value={latestValue} />

    :

    <Radio.Group {...commonProps}
        onChange={e => change([e.target.value])}  // // never an array, so wrap it.
        value={latestValue?.[0]} />




return <Field name='choicebox' {...props} className={classes} >
    {content}
</Field>
}


// transforms to Array where required, but preserves undefined
const arrayof = (v: any) => v === undefined ? undefined : Array.isArray(v) ? v : [v]
const missing = (v: any) => (v?.length ?? 0) === 0


export type OptionProps = Disabled & Partial<{

    title: ReactNode
    noTitle: boolean

}> & {

    value: CheckboxValue
}

ChoiceBox.Option = function Check(_: OptionProps) { return null }
