import { usePreload } from 'apprise-frontend-core/client/preload';
import { Optional } from 'apprise-frontend-core/utils/common';
import { categoryPlural, categorySingular } from 'apprise-frontend-tags/constants';
import { TagReference } from 'apprise-frontend-tags/tag/model';
import { useCrud } from 'apprise-ui/utils/crudtask';
import { useContext } from 'react';
import { tagcategoryapi, useCategoryCalls } from './calls';
import { CategoryReference, TagCategory, useCategoryModel } from './model';
import { CategoryCacheContext } from './provider';


export const useCategoryStore = () => {

    const state = useContext(CategoryCacheContext)

    const preload = usePreload()

    const crud = useCrud({ singular: categorySingular, plural: categoryPlural })

    const model = useCategoryModel()
    const calls = useCategoryCalls()

    const self = {


        allCategories: () => state.get().all

        ,

        allCategoriesOf: (type: Optional<string>) => type ? self.allCategories().filter(c => c.type === type) : []

        ,

        allActiveCategoriesOf: (type: Optional<string>) => type ? self.allCategoriesOf(type).filter(c => c.lifecycle.state === 'active') : []

        ,

        lookupCategory: (category: Optional<CategoryReference>) =>  category ? self.allCategories().find(t => t.id === category) : undefined

        ,

        safeLookupCategory: (category: Optional<CategoryReference>) => self.lookupCategory(category) ?? model.noCategory(category)

        ,

        fetchAllCategories: crud.fetchAllWith(async () => {

            const all = await (preload.get<TagCategory[]>(tagcategoryapi) ?? calls.fetchAllCategories())

            state.set(s => s.all = all)

            return all

        }).done()

        ,

        saveCategory: crud.saveOneWith(async (category: TagCategory) => {

            const isNew = !category.lifecycle.created

            const saved = isNew ? await calls.addCategory(category) : await calls.updateCategory(category)

            state.set(s => s.all = isNew ? [saved, ...s.all] : s.all.map(t => t.id === saved.id ? saved : t))

            return saved

        }).done()

        ,

        saveCategories: crud.saveManyWith(async (categories: TagCategory[]) => {

            const saved = await calls.updateCategories(categories)

            state.set(s => s.all =  s.all.map(c => saved.find(cc => cc.id === c.id) ?? c))

            return saved

        }).done()

        ,

        removeCategory: crud.removeOneWith(async (category: TagCategory, onConfirm?: () => void) => {

            await calls.deleteCategory(category)

            state.set(s => s.all = s.all.filter(c => c.id !== category.id))

            onConfirm?.()

        }).withConsent()

        ,

        allDefaults: () => self.allCategories().flatMap(c => c.properties.field.default).filter(e => !!e) as TagReference[]

    }

    return self

}