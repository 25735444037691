import * as React from 'react';
import { useEventConnection } from "./connection";
import { EventsProps } from "./events";
import { EventConnectionContext } from './context';
import { useFeedback } from 'apprise-ui/utils/feedback';
import { useT } from 'apprise-frontend-core/intl/language';
import { AllertIcon } from 'apprise-ui/utils/icons';

export const EventInitialiser = (props: EventsProps) => {

    const { mock, debug, children } = props

    const t = useT()

    const connection = useEventConnection()

    const state = React.useContext(EventConnectionContext)

    const { showNotification } = useFeedback()


    const initialise = async (mode: 'connect' | 'reconnect') => {

        const initialised = await connection.init(mode)

        state.set(s => s.connection = initialised!)

        return initialised

    }

    const initiliseAndMonitor = async () => {

        await initialise('connect')

        let notified = { mode: 'connected' }

        setInterval(async () => {

            const { connection: currentConnection } = state.get()

            if (!currentConnection || currentConnection.isClosed()) {

                debug && console.log("found disconnected: reconnecting attempt...")

                try {

                    const newConnection = await initialise('reconnect')

                    if (newConnection && notified.mode === 'disconnected') {

                        debug && console.log("notifying reconnection is successful..")

                        showNotification(t("bus.connection_established"))
                        notified.mode = 'connected'
                    }

                    if (!newConnection && notified.mode === 'connected') {

                        debug && console.log("notifying reconnection is not successful..")

                        showNotification(t("bus.connection_failure"), { icon: <AllertIcon /> })
                        notified.mode = 'disconnected'
                    }

                }
                catch (e) {

                    debug && console.log("error when attempting to reconnect....")

                }
            }

        }, 5000)

    }

    React.useEffect(() => {

        mock || initiliseAndMonitor()

        //eslint-disable-next-line
    }, [])


    return <React.Fragment>{children}</React.Fragment>


}