
import { Badge } from 'antd';
import { useT } from 'apprise-frontend-core/intl/language';
import { Button } from 'apprise-ui/button/button';
import { classname } from 'apprise-ui/component/model';
import { useRoutableDrawer } from 'apprise-ui/drawer/drawer';
import { Header } from "apprise-ui/header/header";
import { TextBox } from 'apprise-ui/textbox/textbox';
import { ClearFilters } from 'apprise-ui/utils/filter';
import { SearchIcon } from 'apprise-ui/utils/icons';
import { FiColumns } from 'react-icons/fi';
import { useTableApi } from "./api";
import { TableColumnPanel } from './columnpanel';
import { CounterProps, Table, TableElement, TableProps } from "./table";
import partition from 'lodash/partition'
import { Fragment } from 'react';

const ColumnIcon = FiColumns

export type BarProps<E extends TableElement> = TableProps<E> & {
    filteredData: E[],
    filterActive: boolean
}

export const TableBar = <E extends TableElement>(props: BarProps<E>) => {

    const t = useT()

    const {
        name,
        layout = name ? 'custom' : 'fixed',
        filterBadge: filterBadgeSide = 'left',
        filteredData = [],
        total,
        filterActive,
        context,
        noFilter,
        filterProps
    } = props

    const api = useTableApi(props)

    const { Drawer, openSet } = useRoutableDrawer('column')

    const currentFilters = api.currentFilters()

    const { placeholder = t('ui.table.default_placeholder'), filter, } = filterProps ?? {}

    const [namedFilters, pinnedFilters] = partition(api.filters(), ({ name }) => name)

    const filters = [...pinnedFilters, ...currentFilters.map(f => namedFilters.find(ff => ff.name === f))].filter(f => f).map(f => f.children)

    const others = api.others().map(f => f.children)

    const controls = api.controls()

    if (!noFilter)
        filters.unshift(
            <TextBox noReadonly prefix={<SearchIcon />} placeholder={placeholder}{...props.filterProps}>
                {filter}
            </TextBox>
        )

    const counter = api.counter() ?? (filters.length > 0 ? <Table.Counter /> : undefined)

    if (counter)
        filters[filterBadgeSide === 'left' ? 'unshift' : 'push'](
            <Counter {...counter.props} count={filteredData.length} total={total} filterActive={filterActive} />
        )

    if (name && layout !== 'fixed')
        controls.push(
            <Button noReadonly onClick={() => openSet(true)} icon={<ColumnIcon />} tip={t(`ui.table.column_btn_tip`)} tipDelay={1}>
                {t(`ui.table.column_btn`)}
            </Button>
        )


    const noBar = props.noBar || (!controls.length && !filters.length && !others.length)

    if (noBar)
        return null


    return <Header className={classname('table-header')}>

        {filters.map((filter: JSX.Element, i: number) =>

            <Header.Left key={i} >
                {filter}
            </Header.Left>

        )}

        {context && filterActive &&

            <Header.Left >
                <ClearFilters context={context} />
            </Header.Left>
        }


        {controls.map((control: JSX.Element, i: number) =>

            <Header.Right key={i}>
                {control}
            </Header.Right>
        )}

        {others.map((other: JSX.Element, i: number) =>

            <Fragment key={i}>
                {other}
            </Fragment>
        )}

        {name && layout !== 'fixed' &&

            <Drawer noBusyGuard width={450} title={t('ui.table.column_drawer')} icon={<ColumnIcon />}>
                <TableColumnPanel {...props} />
            </Drawer>
        }

    </Header >
}

type ExtendedCounterProps = CounterProps & Partial<{

    count: number
    total: number
    filterActive: boolean

}>

export const Counter = (props: ExtendedCounterProps) => {

    const { mode = 'simple', count, total = count, children, filterActive, overflow = 9999, relativeOverflow = 999999 } = props

    const simple = <Badge className={classname('filter-badge', filterActive && 'filter-active')} overflowCount={overflow} showZero={count === 0} count={mode === 'total' ? total : count} />

    const relative = <div className='apprise-row'>
        <Badge className='filter-badge' overflowCount={overflow} showZero={count === 0} count={count} />
        <div className='badge-separator' />
        <Badge className='total-badge' overflowCount={relativeOverflow} showZero={total === 0} count={total} />
    </div>

    return <div className='table-counter' >
        {mode === 'relative' ? relative : simple}
        {children && <div className='counter-label'>{children}</div>}
    </div >

}