
import { utils } from 'apprise-frontend-core/utils/common'
import { Component } from 'apprise-ui/component/component'
import { Styled } from 'apprise-ui/component/model'
import * as React from 'react'
import "./styles.scss"



export type Props = Styled & React.PropsWithChildren<{}>

export const Header = (props: Partial<Props>) => {
    
    const children = utils().elementsIn(props.children)

    const lefties = children.filter(utils().isElementOf(Header.Left)).map((r,i)=><div key={i} className='header-left-part'>{r}</div>)
    const righties = children.filter(utils().isElementOf(Header.Right)).map((r,i)=><div key={i} className='header-right-part'>{r}</div>)

    const other = children.flatMap(utils().arrayOf).filter(e=>!utils().isElementOf(Header.Left)(e) && !utils().isElementOf(Header.Right)(e))

    return <Component name='header' {...props}>

        {lefties.length > 0 &&

            <div className='header-part header-left'>
                {lefties}
            </div>
        }

        <div className='header-part header-other'>
            {other}
        </div>

        {righties.length > 0 &&
            <div className='header-part header-right'>
                {righties}
            </div>
        }

    </Component>

}

// child naming functions
Header.Left = function Left(props: React.PropsWithChildren<{}>) { return <>{props.children}</> }
Header.Right = function Right(props: React.PropsWithChildren<{}>) { return <>{props.children}</> }



