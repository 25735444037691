import { State } from 'apprise-frontend-core/state/api';
import { StateProvider } from 'apprise-frontend-core/state/provider';
import { createContext, PropsWithChildren } from 'react';
import { ParsingOptions, WorkBook, XLSX$Utils } from 'xlsx';

export type AsyncSupport = {

    xlsx: {

        utils: XLSX$Utils
        read: (data: any, opts?: ParsingOptions) => WorkBook;
    }
}

const initial = { xlsx: undefined! } as AsyncSupport


export const ParseAsyncContext = createContext<State<AsyncSupport>>(undefined!)

export const ParseProvider = (props:PropsWithChildren) => {

    const {children} = props

    return <StateProvider initialState={initial} context={ParseAsyncContext}>
        {children}
    </StateProvider>
}