import { StateProvider } from 'apprise-frontend-core/state/provider'
import { utils } from 'apprise-frontend-core/utils/common'
import { PropsWithChildren, useContext, useMemo } from 'react'
import { useCall } from './call'
import { PreloadContext } from './context'





export type PreloaderProps = PropsWithChildren<Partial<{

    loaders: Preloader[]
}>>



export type Preloader = {

    name: string
    task: PreloadTask
}

export type PreloadTask = (call: ReturnType<typeof useCall>) => Promise<any>


export const Preload = (props: PreloaderProps) => {

    const { children, loaders = [] } = props

    const call = useCall()

    const parent = useContext(PreloadContext)

    const tasks = useMemo(() => {

        const externalLoaders = (document as any).apprise_preload ?? {}

        const parentLoaders = { ...parent?.get().loaders, ...externalLoaders }

        const theseloaders = loaders.filter(l => !parentLoaders[l.name])

        //console.log({ externalLoaders, parentLoaders, theseloaders })

        return {

            // merges parent tasks, if any, with current tasks.
            loaders: { ...parentLoaders, ...utils().index(theseloaders).mappingBy(l => l.name, l => l.task(call)) }

        }
        // eslint-disable-next-line
    }, [])


    return <StateProvider initialState={tasks} context={PreloadContext}>
        {children}
    </StateProvider>


}


export const usePreload = () => {

    const state = useContext(PreloadContext)


    const self = {


        get: <T extends Object>(name: string) =>

            state.get().loaders?.[name]?.finally(() =>{

                state.set(s => delete s.loaders[name])

            }) as Promise<T>


    }

    return self
}