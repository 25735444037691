import { useLogged } from 'apprise-frontend-iam/login/logged';
import { useRouting } from 'apprise-ui/utils/routing';
import { useContext } from 'react';
import { useNewUser } from './model';
import { UserRoutingContext, UserRoutingState } from './provider';

export const userRoute = "/user"
export const accountParam = "drawer-account"




export const useUserRouting = () => {

    const state = useContext(UserRoutingContext)

    const newUserIn = useNewUser()

    const logged = useLogged()

    const routing = useRouting()

    const self = {

        listRoute: () => userRoute

        ,

        detailRoute: (id: string) => `${userRoute}/${id}`

        ,

        innerDetailRoute: (id: string) => `${userRoute}/${id}${routing.query()}` 

        ,

        newDetailRoute: () => `${userRoute}/new`

        ,


        routeToList: () => routing.routeTo(self.listRoute())

        ,

        routeToDetail: (id: string) => routing.routeTo(self.detailRoute(id))

        ,

        routeToNewDetail: (directives: Partial<UserRoutingState> = {}) => Promise.resolve(state.reset(directives)).then(() => routing.routeTo(self.newDetailRoute()))

        ,

        nextUser: () => state.get().nextUser ?? newUserIn(logged.tenant)

    }

    return self
}