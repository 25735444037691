

import { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';


export const useDeferredRouting = () => {

    const history = useHistory()

    const nextRoute = useRef<string|undefined>()

    useEffect(()=> {

        const route = nextRoute.current

        if (route) {
            nextRoute.current=undefined
            history.push(route)
        }
    })

    const  routeAfterRenderTo = (route: string) => nextRoute.current = route

    return { routeAfterRenderTo }

}

export const useRouting = () => {

    const history = useHistory()
    const location = useLocation()

    const self = {

        routeTo: (route: string, state?:string) => history.push(route,state)

        ,

        routeQueryTo: (change: (params: QueryParams) => void) => `${location.pathname}${self.updateQueryWith(change)}`

        ,

        updateQueryWith: (change: (params: QueryParams) => void) => updateQuery(location.search).with(change)

        ,

        queryParams: () => paramsIn(location.search)

        ,

        query: () => location.search
        
        ,

        url: () => `${location.pathname}${location.search}`
        

        ,

        location
    }

    return self;
}


export const useLazyRouting = () => {

    const routing = useRouting()
    const locationRef = useRef(routing)

    useEffect(()=> {

        locationRef.current = routing

    })

    return {get : () => locationRef.current }

}

export type QueryParams = Record<string, string | undefined>


export const paramsIn = (query: string): QueryParams =>
    
    Array.from(new URLSearchParams(query)).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {} as QueryParams)


export const updateQuery = (query: string) => ({
    with: (change: (params: QueryParams) => void) => {

        const map = Array.from(new URLSearchParams(query)).reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {} as QueryParams)

        change(map)

        const filteredMap = Object.keys(map).reduce((acc, k) => map[k] ? { ...acc, [k]: map[k]! } : acc, {} as Record<string, string>)

        const stringparams = new URLSearchParams(filteredMap).toString()


        return stringparams?.length > 0 ? `?${stringparams}` : stringparams

    }

})