import { SubmissionSettings } from '#settings/submissions'
import { submittedColor } from '#submission/constants'
import { useT } from 'apprise-frontend-core/intl/language'
import { TenantIcon } from 'apprise-frontend-iam/tenant/constants'
import { CategoryLabel, CategoryLabelProps } from 'apprise-frontend-tags/category/label'
import { TagLabel, TagLabelProps } from 'apprise-frontend-tags/tag/label'
import { classname } from 'apprise-ui/component/model'
import { Label, LabelProps } from 'apprise-ui/label/label'
import { NumberIcon, TextIcon } from 'apprise-ui/utils/icons'
import { CSSProperties, ReactNode } from 'react'
import { IconBaseProps } from 'react-icons'
import { AiOutlineEuroCircle } from 'react-icons/ai'
import { BiLayer, BiTargetLock } from 'react-icons/bi'
import { BsBarChartFill, BsCurrencyEuro, BsPeopleFill } from 'react-icons/bs'
import { GiProgression } from 'react-icons/gi'
import { HiCreditCard } from 'react-icons/hi'
import { IoPeopleCircleOutline } from 'react-icons/io5'
import { MdOutlinePieChartOutline } from 'react-icons/md'
import { RiGroup2Fill, RiShieldCheckFill, RiSwitchFill } from 'react-icons/ri'


export const operationType = 'operation'
export const indicatorType = 'indicator'

export const operationSectorCategory = 'TGC-sector'
export const operationObjectiveCategory = 'TGC-operation-objective'
export const operationBeneficiaryCategory = 'TGC-beneficiary'
export const operationBeneficiaryGenderCategory = 'TGC-beneficiary-gender'

export const operationOperationTypeCategory = "TGC-operation-type"
export const operationProgressCategory = "TGC-operation-progress"
export const operationDomainCategory = "TGC-operation-domain"
export const operationInterventionCategory = "TGC-intervention"
export const operationTypeCategory = "TGC-operation-type"
export const operationSupportCategory = "TGC-operation-support"
export const operationBooleanCategory = "TGC-boolean"
export const operationCountryCategory = "TGC-country"
export const operationPriorityCategory = "TGC-priority"

export const operationNutsCategory = "TGC-operation-nuts"

export const operationCfrCategoryFor = (alphacode: string) => `${operationCfrCategory}-${alphacode}`

export const operationCfrCategory = "TGC-operation-cfr"

export const unitOfMeasureCategory = "TGC-unit-of-measure"
export const indicatorTypeCategory = "TGC-operation-indicator"


export const OperationIcon = (_: IconBaseProps) => <HiCreditCard color={_.color ?? submittedColor} {..._} />
export const OperationIdIcon = NumberIcon
export const OperationCCIIcon = NumberIcon
export const OperationDescriptionIcon = TextIcon
export const OperationObjectiveIcon = BiTargetLock
export const OperationSectorIcon = MdOutlinePieChartOutline
export const OperationLocationIcon = NumberIcon
export const OperationProgressIcon = GiProgression
export const BeneficiaryNameIcon = TextIcon
export const BeneficiaryCodeIcon = NumberIcon
export const BeneficiaryGenderIcon = RiGroup2Fill
export const BeneficiaryTypeIcon = BiLayer
export const OperationPeopleIcon = BsPeopleFill
export const OperationPartnersIcon = IoPeopleCircleOutline
export const OperationMoneyIcon = AiOutlineEuroCircle
export const OperationBooleanIcon = RiSwitchFill

export const OperationFacetIcon = BiLayer // generic
export const OperationCfrIcon = NumberIcon
export const OperationGTIncreaseIcon = NumberIcon
export const OperationFleetSegmentIcon = OperationFacetIcon

export const OperationFinancialLabel = (_: LabelProps) => <Label icon={<OperationMoneyIcon />} {..._} />


export const OperationFinancialValue = (props: Omit<LabelProps, 'title'> & { 
    
        value: number, 
        icon?: ReactNode
        error?: boolean, 
        aggregation?: boolean | SubmissionSettings['aggregationErrorMode']}) => {

    const t = useT()
    const { value, error: clienterror, aggregation=false, icon = <BsCurrencyEuro color={'cadetblue'} />, tip:clienttip } = props

    const error = clienterror ?? (value!==undefined && isNaN(value))
    const style: CSSProperties = error && aggregation ? { textTransform: 'uppercase' } : { fontWeight: 500 }
    const title = error && aggregation === 'strong' ? t('sub.financial_error') : value?.toFixed(2)
    const mode = error && aggregation === 'strong' ? 'tag' : 'normal'
    const tip = error  && aggregation ? t('sub.financial_error_aggregate')  : clienttip 

    return <Label noIcon={value === undefined} className={ classname(error && aggregation ? 'financial-value-error' : 'financial-value') } mode={mode} icon={icon} tip={tip} title={<span style={style}>{title}</span>}  {...props} />

}

export const OperationDateValue = (_: Omit<LabelProps, 'title'> & { date: string | undefined }) => <Label noIcon title={_.date ? new Date(_.date).toLocaleDateString() : undefined} {..._} />

export const OperationBooleanValue = (_: Omit<TagLabelProps, 'tag'> & { value: string | undefined }) => <TagLabel titleStyle={{ minWidth: 20, textAlign: 'center' }} mode='tag' bare noIcon tag={_.value} {..._} />



export const OperationIndicatorIcon = BsBarChartFill
export const OperationValidationIcon = RiShieldCheckFill
export const OperationValidIcon = (props: IconBaseProps) => <OperationValidationIcon color='lightseagreen' {...props} />
export const OperationValidWithWarningIcon = (props: IconBaseProps) => <OperationValidationIcon color='orange' {...props} />
export const OperationInvalidIcon = (props: IconBaseProps) => <OperationValidationIcon color='orangered' {...props} />

export const OperationObjectiveLabel = (_: CategoryLabelProps) => { 

    const t = useT()

     return <CategoryLabel bare icon={<OperationObjectiveIcon />} category={operationObjectiveCategory} title={t('sub.table_objective')} {..._} />
    
}

export const OperationPriorityLabel = (_: CategoryLabelProps) => <CategoryLabel bare icon={<NumberIcon />} category={operationPriorityCategory} {..._} />
export const OperationProgressLabel = (_: CategoryLabelProps) => <CategoryLabel bare icon={<OperationProgressIcon />} category={operationProgressCategory} {..._} />
export const OperationTypeLabel = (_: CategoryLabelProps) => <CategoryLabel bare icon={<OperationFacetIcon />} category={operationTypeCategory} {..._} />
export const OperationSectorLabel = (_: CategoryLabelProps) => <CategoryLabel bare icon={<OperationSectorIcon />} category={operationSectorCategory}  {..._} />
export const OperationDomainLabel = (_: CategoryLabelProps) => <CategoryLabel bare icon={<OperationFacetIcon />} category={operationDomainCategory} {..._} />
export const OperationSupportLabel = (_: CategoryLabelProps) => <CategoryLabel bare icon={<OperationFacetIcon />} category={operationSupportCategory} {..._} />
export const OperationInterventionLabel = (_: CategoryLabelProps) => <CategoryLabel bare icon={<OperationFacetIcon />} category={operationInterventionCategory} {..._} />

export const BeneficiaryTypeLabel = (_: CategoryLabelProps) => <CategoryLabel bare icon={<BeneficiaryTypeIcon />} category={operationBeneficiaryCategory} {..._} />
export const BeneficiaryGenderLabel = (_: CategoryLabelProps) => <CategoryLabel bare icon={<BeneficiaryGenderIcon />} category={operationBeneficiaryGenderCategory} {..._} />

export const OperationIdentifierLabel = (_: LabelProps) => {

    const t = useT()

    return <Label icon={<OperationIdIcon />} title={t('op.id')} tip={t('op.id_tip')} {..._} />

}

export const OperationPeopleLabel = (_: LabelProps) => {

    const t = useT()

    return <Label icon={<OperationPeopleIcon />} title={t('op.people')} tip={t('op.people_tip')} {..._} />

}

export const OperationCfrLabel = (_: LabelProps) => {

    const t = useT()

    return <Label icon={<OperationCfrIcon />} title={t('op.cfr')} tip={t('op.cfr_tip')} {..._} />

}


export const OperationGTIncreaseLabel = (_: LabelProps) => {

    const t = useT()

    return <Label icon={<OperationGTIncreaseIcon />} title={t('op.gtIncrease')} tip={t('op.gtIncrease_tip')} {..._} />

}

export const OperationFleetSegmentLabel = (_: LabelProps) => {

    const t = useT()

    return <Label icon={<OperationFleetSegmentIcon />} title={t('op.fleetSegment')} tip={t('op.fleetSegment_tip')} {..._} />

}

export const OperationPartnersLabel = (_: LabelProps) => {

    const t = useT()

    return <Label icon={<OperationPartnersIcon />} title={t('op.partners')} tip={t('op.partners_tip')} {..._} />

}


export const OperationCCILabel = (_: LabelProps) => {

    const t = useT()

    return <Label icon={<OperationCCIIcon />} title={t('op.cci')} tip={t('op.cci_tip')} {..._} />
}

export const OperationMSLabel = (_: LabelProps) => {

    const t = useT()

    return <Label icon={<TenantIcon />} title={t('op.ms')} {..._} />
}

export const OperationDescriptionLabel = (_: LabelProps) => {

    const t = useT()

    return <Label icon={<OperationDescriptionIcon />} title={t('op.desc')} tip={t('op.desc_tip')} {..._} />
}


export const OperationLocationLabel = (_: LabelProps) => {

    const t = useT()

    return <Label icon={<OperationLocationIcon />} title={t('op.location')} tip={t('op.location_tip')} {..._} />
}

export const BeneficiaryNameLabel = (_: LabelProps) => {

    const t = useT()

    return <Label icon={<BeneficiaryNameIcon />} title={t('op.beneficiary_name')} tip={t('op.beneficiary_name_tip')} {..._} />
}

export const BeneficiaryCodeLabel = (_: LabelProps) => {

    const t = useT()

    return <Label icon={<BeneficiaryCodeIcon />} title={t('op.beneficiary_code')} tip={t('op.beneficiary_code_tip')} {..._} />
}

export const BeneficiaryLeadLabel = (_: CategoryLabelProps) => {

    const t = useT()

    return <CategoryLabel bare title={t('op.beneficiaryLead')} tip={t('op.beneficiaryLead_tip')} icon={<OperationBooleanIcon />} category={operationBooleanCategory} {..._} />
}

export const BeneficiarySupportedLabel = (_: CategoryLabelProps) => {

    const t = useT()

    return <CategoryLabel bare icon={<OperationBooleanIcon />} title={t('op.previouslySupported')} tip={t('op.previouslySupported_tip')} category={operationBooleanCategory} {..._} />

}

export const OperationLandingLabel = (_: CategoryLabelProps) => {

    const t = useT()

    return <CategoryLabel bare icon={<OperationBooleanIcon />} title={t('op.landingObligation')} tip={t('op.landingObligation_tip')} category={operationBooleanCategory} {..._} />

}

export const OperationSSCFLabel = (_: CategoryLabelProps) => {

    const t = useT()

    return <CategoryLabel bare icon={<OperationBooleanIcon />} title={t('op.sscf')} tip={t('op.sscf_tip')} category={operationBooleanCategory} {..._} />

}

export const OperationClimateChangeLabel = (_: CategoryLabelProps) => {

    const t = useT()

    return <CategoryLabel bare icon={<OperationBooleanIcon />} title={t('op.climateChange')} tip={t('op.climateChange_tip')} category={operationBooleanCategory} {..._} />

}

export const OperationNonDiscriminationLabel = (_: CategoryLabelProps) => {

    const t = useT()

    return <CategoryLabel bare icon={<OperationBooleanIcon />} title={t('op.nonDiscrimination')} tip={t('op.nonDiscrimination_tip')} category={operationBooleanCategory} {..._} />

}

export const OperationGenderEqualityLabel = (_: CategoryLabelProps) => {

    const t = useT()

    return <CategoryLabel bare icon={<OperationBooleanIcon />} title={t('op.genderEquality')} tip={t('op.genderEquality_tip')} category={operationBooleanCategory} {..._} />

}

export const OperationDisabilityLabel = (_: CategoryLabelProps) => {

    const t = useT()

    return <CategoryLabel bare icon={<OperationBooleanIcon />} title={t('op.disability')} tip={t('op.disability_tip')} category={operationBooleanCategory} {..._} />

}


export const OperationSmallScaleCoastalFisheryLabel = (_: CategoryLabelProps) => {

    const t = useT()

    return <CategoryLabel bare icon={<OperationBooleanIcon />} title={t('op.sscf')} tip={t('op.sscf_tip')} category={operationBooleanCategory} {..._} />

}

export const OperationIndicatorLabel = (_: CategoryLabelProps) => {

    const t = useT()

    return <CategoryLabel bare title={t('ind.singular')} icon={<OperationIndicatorIcon />} category={indicatorTypeCategory} {..._} />

}

export const OperationIndicatorsLabel = (_: CategoryLabelProps) => {

    const t = useT()

    return <CategoryLabel bare title={t('ind.plural')} icon={<OperationIndicatorIcon />} category={indicatorTypeCategory} {..._} />

}