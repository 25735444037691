

import { useConfig } from 'apprise-frontend-core/config/api'
import { StateProvider } from 'apprise-frontend-core/state/provider'
import { utils } from 'apprise-frontend-core/utils/common'
import { useRenderGuard } from 'apprise-frontend-core/utils/renderguard'
import { useEffect, useMemo, useState } from 'react'
import { CallProps, ClientInterceptors, RemoteClientConfiguration } from './config'
import { useClientSession } from './session'
import { ClientContext, initialClientState, useClient } from './state'



export type ClientProps = React.PropsWithChildren<{

    config?: Partial<CallProps>

    interceptors?: Partial<ClientInterceptors>

}>

// activates call management support: mount state and initialises it. 

export const Client = (props: ClientProps) => {

    return <StateProvider initialState={initialClientState} context={ClientContext}>
        <Initialiser {...props} />
    </StateProvider>
}

//  merges client configuration and remote configuration, then render children.

const Initialiser = (props: ClientProps) => {

    const { config: clientconfig = {}, interceptors = {}, children } = props

    const client = useClient()
    const session = useClientSession()

    const [active, activeSet] = useState(false)

    
     // won't be ready until loaded
    const remoteConfig = useConfig<RemoteClientConfiguration>().client

    const config = useMemo(() =>{

        return  utils().merge(clientconfig, interceptors, remoteConfig ?? {})
    }
    // eslint-disable-next-line
    , [remoteConfig])


    //  updates in sync with shared config, typically when the latter is remotely loaded.
    useEffect(() => {

        // activation is already a sync point, so we run after that.
        if (active) {

            console.log("re-initialising client with remote configuration...")

            // can't simply reset active toggle or component will unmount and remount.
            client.init(config)  
        }

        // eslint-disable-next-line
    }, [remoteConfig])


    const activate = async () => {

        await session.create()

        client.init(config)

        activeSet(true)

    }

    // activates before rendering children.
    const { content } = useRenderGuard({

        when: active,

        render: children,

        orRun: activate,

    })

    return content
}
