
// cache

import { StateProvider } from "apprise-frontend-core/state/provider";
import { Codec, JSONCodec, NatsConnection } from 'nats.ws';
import * as React from 'react';
import { EventConnectionContext } from './context';

export type EventConnectionState = {

    connection: NatsConnection
    codec: Codec<any>

}

export const initialConnectionState: EventConnectionState = {

    connection: undefined!,
    codec: JSONCodec()
}




export const EventProvider = (props: React.PropsWithChildren<{}>) => {

    return <StateProvider initialState={initialConnectionState} context={EventConnectionContext}>
            {props.children}
        </StateProvider>
}