import { useLogin } from 'apprise-frontend-iam/login/logged'
import produce from "immer"
import { useLoggedStore } from '../login/store'
import { UserPreferences } from './model'
import { useTenancyOracle } from 'apprise-frontend-iam/authz/tenant'
import { useState } from 'react'
import { Configurator } from 'apprise-ui/utils/asynctask'


export type PreferenceUpdater <T=UserPreferences>  = ( _: T ) => any
export const guestPreferencesKey = 'guest-prefs'
export const maxSearchQuery = 2;

// mediates r/w access to the preferences of the logged user.

export const usePreferences = <T extends UserPreferences=UserPreferences>() => {

    const isGuest = useTenancyOracle().isGuest()
    const guestPrefs = useGuestPreferences<T>()
    const loggedPrefs = useLoggedPreferences<T>()

    return isGuest ? guestPrefs : loggedPrefs
}

export const useLoggedPreferences = <T extends UserPreferences=UserPreferences>() => {

    const login = useLogin()
    const logged = useLoggedStore()

    const self = {

        get: () => login.logged().details.preferences as UserPreferences & T

        ,

        saveWith: (producer: PreferenceUpdater<T> , configurator?: Configurator) => {

            const preferences = produce( self.get(), (prefs:T) => void (producer(prefs)) )

            const loggedUser = login.logged()

            const update = { ...loggedUser, details: { ...loggedUser.details, preferences } }

            return configurator ? logged.updateTask.with(configurator).done()(update) : logged.update(update)  


        }
    }

    return self
}

export const useGuestPreferences = <T extends UserPreferences=UserPreferences>() => {

    const [, refresh] = useState(0);

    const self = {

        get: () => JSON.parse(localStorage.getItem(guestPreferencesKey) || '{}') as UserPreferences & T

        ,

        saveWith: async (producer: PreferenceUpdater<T> ) => {

            const preferences = produce( self.get(), (prefs:T) => {
                producer(prefs)

                if (prefs.search.queries.length > maxSearchQuery) {
                    prefs.search.queries = prefs.search.queries.slice(0, maxSearchQuery);
                }
            })

            localStorage.setItem(guestPreferencesKey, JSON.stringify(preferences))

            refresh(i => i + 1);
        }
        
    }

    return self
}
