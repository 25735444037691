import { useT } from 'apprise-frontend-core/intl/language'
import { useL } from 'apprise-frontend-core/intl/multilang'
import { utils } from 'apprise-frontend-core/utils/common'
import { CategoryLabel } from 'apprise-frontend-tags/category/label'
import { cardinalityLabels, keyOf, TagCategory, useCategoryModel } from 'apprise-frontend-tags/category/model'
import { tagType } from 'apprise-frontend-tags/constants'
import { useTagTypeFilter } from 'apprise-frontend-tags/tag/filter'
import { useTagModules } from 'apprise-frontend-tags/tag/modules'
import { useTagStore } from 'apprise-frontend-tags/tag/store'
import { Button } from 'apprise-ui/button/button'
import { useRoutableDrawer } from 'apprise-ui/drawer/drawer'
import { Label } from 'apprise-ui/label/label'
import { SortSpec } from 'apprise-ui/table/sorting'
import { Table } from 'apprise-ui/table/table'
import { useTableUtils } from 'apprise-ui/table/utils'
import { NumberIcon } from 'apprise-ui/utils/icons'
import React, { useMemo } from 'react'
import { AiOutlineCheck } from 'react-icons/ai'
import { FaSort } from 'react-icons/fa'
import { Reorder } from './reorder'
import { useCategoryRouting } from './routing'
import { useCategoryStore } from './store'



export const CategoryTable = () => {

    const t = useT()
    const l = useL()

    const { Drawer: ReorderDrawer, openAt: openReorderDrawerAt, match } = useRoutableDrawer('reorder')

    const cats = { ...useCategoryModel(), ...useCategoryStore(), ...useCategoryRouting() }
    const store = useTagStore()

    const modules = useTagModules()

    const unfilteredCategories = cats.allCategories()

    const filterContext = `${tagType}-categories`

    const { typeFilter, typeFiltered, selectedType, selectType } = useTagTypeFilter({

        categories: unfilteredCategories,
        context: filterContext

    })

    const data = typeFiltered

    const initialSort: SortSpec[] = [{ key: 'type', mode: 'asc' }, { key: 'name', mode: 'asc' }]

    React.useEffect(() => {
        if (match !== undefined && match !== selectedType)
            selectType(match);
        // eslint-disable-next-line
    }, [match, selectedType])

    // eslint-disable-next-line
    const counts = useMemo(() => cats.allCategories().reduce((acc, next) => ({ ...acc, [next.id]: store.allTagsOfCategory(next.id).length ?? 0 }), {}), [data])

    const { compareStringsOf, compareNumbersOf, Column } = useTableUtils<TagCategory>()

    const canReorder = modules.all().length < 2 || !!selectedType

    const reorderButton = <Button
        tip={t(`tag.category_reorder_${canReorder ? 'enabled' : 'disabled'}_tip`)}
        tipDelay={1}
        enabled={canReorder}
        onClick={() => openReorderDrawerAt(selectedType!)}
    >
        <Label enabled={canReorder} icon={<FaSort />} title={t('tag.category_reorder')} />

    </Button>

    const allModules = modules.all()

    // eslint-disable-next-line
    const typemap = useMemo(() =>  utils().index(allModules).mappingBy(m=> m.type, m => t(m.singular)),[allModules])

    return <React.Fragment>

        <Table name={filterContext} data={data} total={unfilteredCategories.length} context={`${tagType}-categories`} initialSort={initialSort}>

            <Table.Control>
                {reorderButton}
            </Table.Control>

            <Table.Filter name="type">
                {typeFilter}
            </Table.Filter>

            <Column pinned name="name" width={220} title={t("tag.name_col")} text={c => c.name} render={c => <CategoryLabel category={c} />} sort={cats.categoryComparator} />

            <Column defaultLayout name="type" filter="type" width={120} text={c => typemap[c.type]}  sort={compareStringsOf(c => t(c.type ?? ''))} title={t("tag.type_col")} render={c => typemap[c.type]} />

            <Column defaultLayout name="desc" width={350} text={c => c.description} title={t("tag.description_col")} render={c => l(c.description)} />

            <Column defaultLayout name="tags" width={80} sort={compareNumbersOf(c => counts[c.id])} title={t("tag.tags_col")} render={c => <Label mode='tag' icon={<NumberIcon />} title={counts[c.id]} />} />

            <Column name="card" width={130} title={t("tag.cardinality_col")} render={c => t(cardinalityLabels[keyOf(c.cardinality)])} />

            <Column name="field" width={70} title={t('tag.field_col')} render={c => c.properties.field?.enabled && <AiOutlineCheck color='green' />} />

        </Table>

        <ReorderDrawer width={400} icon={<FaSort />} title={t('tag.category_reorder_title')}>
            <Reorder type={match} name={t(modules.lookup(match)?.plural!)} />
        </ReorderDrawer>


    </React.Fragment>
}
