
import { useActionRegistry, useActions } from 'apprise-frontend-core/authz/action'
import { useLoggedOracleFactory } from 'apprise-frontend-core/authz/oracle'
import { useInterceptor } from 'apprise-frontend-core/client/interceptor'
import { MockHorizonContext } from 'apprise-frontend-core/client/mockhorizon'
import { LanguageObserver } from 'apprise-frontend-core/intl/langobserver'
import { State, fallbackStateOver } from 'apprise-frontend-core/state/api'
import { StateProvider } from 'apprise-frontend-core/state/provider'
import { useRenderGuard } from 'apprise-frontend-core/utils/renderguard'
import { EventSubscriber } from 'apprise-frontend-events/subscriptions'
import { useLoggedOracleFactoryImpl } from 'apprise-frontend-iam/authz/factory'
import { useTenancyOracle } from 'apprise-frontend-iam/authz/tenant'
import { useLogged, useLogin } from 'apprise-frontend-iam/login/logged'
import { UserLabel } from 'apprise-frontend-iam/user/label'
import { usePreferences } from 'apprise-frontend-iam/user/preferences'
import { usePreferencesPlugin } from 'apprise-ui/preferences'
import { useUsernameResolverPlugin } from 'apprise-ui/usernameresolver'
import { createContext, useContext, useEffect, useMemo } from 'react'
import { userLogoutTopic } from '../user/constants'
import { useUserLogoutListener } from '../user/events'
import { User, UserDto } from '../user/model'
import { LoggedUserLoader } from './loader'






export type LoginState = {

    logged: User
    allowGuest: boolean
    authenticated: boolean
}


const initialLoginState: LoginState = {

    logged: undefined!,
    allowGuest: false,
    authenticated: false

}


export const LoginContext = createContext<State<LoginState>>(fallbackStateOver(initialLoginState))


export type LoginProps = React.PropsWithChildren<Partial<{

    mock: boolean
    mockLogged: boolean | string | UserDto
    mockAuth: boolean

    allowGuest: boolean

}>>

// initialises the model and loads users, and mounts the state if required.
export const Login = (props: LoginProps) => {

    const belowHorizon = useContext(MockHorizonContext)

    const { mock = belowHorizon, mockAuth, allowGuest = false } = props

    const state: LoginState = useMemo(() => ({

        ...initialLoginState,
        allowGuest,
        authenticated: !(mockAuth ?? mock)

    }), [mock, mockAuth, allowGuest])

    return <StateProvider initialState={state} context={LoginContext} >
        <Preinitialiser {...props}>
            <LoggedUserLoader mockLogged={mock}>
                <LanguageChangeObserver />
                <ActionChangeObserver />
                <LoginInitializer {...props} />
            </LoggedUserLoader>
        </Preinitialiser>
    </StateProvider>


}

export const LoginInitializer = (props: LoginProps) => {

    const logoutListener = useUserLogoutListener()

    const { children } = props

    return <Initialiser>
        <EventSubscriber name={userLogoutTopic} subscriptions={[logoutListener]}>
            {children}
        </EventSubscriber>
    </Initialiser>



}


// captures 401 and 403 errors and handles guest mode.

export const Preinitialiser = (props: LoginProps) => {

    const { children, allowGuest = false } = props

    const interceptors = useInterceptor()

    const login = useLogin()

    const activate = async () => {

        if (!login.isAuthenticated())
            return

        interceptors.addOnError(error => {

            const loginRequired = error.response?.status === 401 || error.response?.status === 403

            if (loginRequired) {

                const isLogged = !!login.logged()

                // 1. if we allow guests and none is logged yet, then ignore (one guest will be logged).
                // this is the case of first page load. The backend let us in, but we no user is logged.  

                if (allowGuest && !isLogged)
                    return

                // 2.  if we don't allow guest then inform user and redirect to logout.
                //  do same also when we have let a guest, but requests keep failing. when does this happen? 
                //  token has expired and cannot be refreshed.

                login.logout({ askConsent: isLogged, useAuth: true })

            }
        })

    }


    const { content } = useRenderGuard({

        render: children,
        orRun: activate
    })

    return content


}


const LanguageChangeObserver = () => {

    const current = useLogged().details.preferences.language

    return <LanguageObserver language={current} />
}


const Initialiser = (props: LoginProps) => {

    const { children } = props

    const logged = useTenancyOracle()

    const factory = useLoggedOracleFactory()
    const factoryImpl = useLoggedOracleFactoryImpl()

    const { get, saveWith } = usePreferences()

    const preferencesPlugin = usePreferencesPlugin()
    const usernameResolverPlugin = useUsernameResolverPlugin()


    const activate = async () => {

        // adapts user preference for access from UI components.
        preferencesPlugin.register({ isEnabled: () => !logged.isGuest(), get, set: saveWith })

        usernameResolverPlugin.register({ resolve: username => <UserLabel noReadonly bare user={username} /> })

        // register an oracle factory that resolves against the logged user.
        factory.register(factoryImpl)



    }




    const { content } = useRenderGuard({

        render: children,
        orRun: activate
    })

    return content


}


// monitors future action registration to update logged user.
const ActionChangeObserver = () => {

    const actions = useActions()
    const registered = useActionRegistry().all()

    const state = useContext(LoginContext)

    useEffect(() => {

        state.setQuietly(s => s.logged = { ...s.logged, permissions: s.logged.permissions.map(actions.intern) })

        // eslint-disable-next-line
    }, [registered])

    return null;
}