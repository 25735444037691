
import { useMode } from 'apprise-frontend-core/config/api'
import { classname, Debugged, Disabled, Ranged, Sized, Styled } from "apprise-ui/component/model"
import { Link } from 'apprise-ui/link/link'
import { Linked } from 'apprise-ui/link/model'
import { Readonly } from 'apprise-ui/readonly/model'
import { Tipped } from 'apprise-ui/tooltip/model'
import { Tip } from 'apprise-ui/tooltip/tip'
import * as React from 'react'
import './styles.scss'



export type CommonProps = Debugged & Styled & Sized<any> & Disabled & Readonly & Linked & Tipped & Partial<{

    name: string
    componentClassName: string

}>

//  wraps children in zero or more elements with cross-cutting semantics, based on properties.
//  also based on properties, synthesises classes and places them on the top-level wrapper.

export const Component = (props: CommonProps & { children: any }) => {

    const { name = 'anon', style, className: clientClass, componentClassName, ...rest } = props

    const { size ='normal', tip, linkTo, noLink} = rest

    const componentClasses: (string | undefined)[] = [clientClass, fq(name), componentClassName]

    const { disabled } = useComponentProps(rest)

    if (disabled)
        componentClasses.push(fq('disabled'))  //  disabled class for custom styling.

    if (size)
        componentClasses.push(fq(`size-${size}`))   //  size class for custom styling.

    let wrapper = rest.children

    if (tip && !disabled)
        wrapper = <Tip  {...rest}>{wrapper}</Tip>

    if (linkTo && !noLink && !disabled)
        wrapper = <Link {...rest}>{wrapper}</Link>

    
    
    return <div className={classname(...componentClasses)} style={style}>{wrapper}</div>

}


// derives a set of properties on behalf of the child, which is the intended client of the hook.
// the child is responsible for using them below its own hierarchy.

export const useComponentProps = <T extends Record<string, any>>(props: T): T => {

    return useDeferredComponentProps().getComponentProps(props)

}

export const useDeferredComponentProps = () => {

    const mode = useMode()

    const getComponentProps =  <T extends Record<string, any>>(props: T): T => {

    const isDisabled = props.disabled || (props.enabled === false) 

    const debug = props.debug && !mode.production

    // normalise range object to spread props, or vicersa.
    const rangeprops: Ranged = props.range ?

        { min: props.range.min, max: props.range.max }              // spread to match range object.

        : (props.min !== undefined || props.max !== undefined) ?
            { range: { min: props.min, max: props.max } }              
            : {}

        return { ...props, debug, disabled: isDisabled, ...rangeprops }
    }


    return { getComponentProps }
} 





//  helpers

const fq = (name: string | undefined) => name ? `apprise-${name.toLowerCase()}` : undefined





