import { Styled } from "apprise-ui/component/model"
import React from "react"


type ScrollerContext = {
  ref: React.MutableRefObject<HTMLDivElement>
  offsetTop: number
}

export const ReactScrollerContext = React.createContext<ScrollerContext>(null!)


export type ScrollerProps = React.PropsWithChildren<Styled & { offsetTop: number }>

export const Scroller = (props: ScrollerProps) => {

  const { children, offsetTop = 0, className, style  } = props

  const ref = React.useRef<HTMLDivElement>(null!)

  const value = React.useMemo(() => ({ ref, offsetTop }), [offsetTop])

  return <ReactScrollerContext.Provider value={value}>

    <div ref={ref} style={{ overflow: 'auto', height: '100%', ...style }} className={className}>

      { children }

    </div>

  </ReactScrollerContext.Provider>
}

export const useScroller = () => {

  const { ref, offsetTop } = React.useContext(ReactScrollerContext)

  return { element: ref.current, offsetTop }
}
