
import { useInclusionList } from 'apprise-frontend-core/config/api'
import { Language } from 'apprise-frontend-core/intl/language'
import { Multilang, useMultilang } from 'apprise-frontend-core/intl/multilang'
import { Optional, useUtils } from 'apprise-frontend-core/utils/common'
import { Lifecycled } from 'apprise-frontend-core/utils/lifecycle'
import { UserReference } from 'apprise-frontend-iam/user/model'
import { Note } from 'apprise-frontend-iam/user/notebox'
import { tenantType } from './constants'
import { useTenancyOracle } from '../authz/tenant'
import { useTenantStore } from './store'

export type TenantLifecycleState = "active" | "inactive"

export type Tenant = TenantDto

export type TenantDto = Lifecycled<TenantLifecycleState> & {

  id: string,
  code?: string,
  name: Multilang
  guarded: boolean
  description: Multilang
  tags: string[]
  preferences: TenantPreferences
}

export type TenantReference = string

export type NextTenantDirectives = Partial<{

  model: Tenant
  onAdded: (added: Tenant) => any

}>

export type TenantPreferences = Partial<{

  language: Language,
  location: string
  note: Note
  focalPoint: UserReference

}> & { [key: string]: any }    // extensible by client modules.


// the type of domain objects that are in the scope of a tenant.
export type TenantResource = {

  tenant: TenantReference

}

export const noTenant = "*"

export const hasTenant = (obj: any): obj is TenantResource => {
  return obj.tenant
}


export const useNewTenant = () => {

  const { isIncluded } = useInclusionList(tenantType)

  return (): Tenant => ({

    id: undefined!,
    name: {},
    guarded: false,
    description: {},
    tags: [],
    preferences: {},
    lifecycle: { state: isIncluded('active') ? 'active' : 'inactive' }
  })
}

export const unknownTenantId = 'unknown'

export const useLoggedTenant = () => {
  
  const oracle = useTenancyOracle()
  const currentTenant = useTenantStore().lookup(oracle.tenant())

  return oracle.hasNoTenant() ? undefined : currentTenant

}


export const useTenantModel = () => {

  const newTenant = useNewTenant()

  const multilang = useMultilang()
  const utils = useUtils()

  const self = {

    // tenants can always be searched by name and code, even if the code is not shown.
    // this witnesses to the familiarity of codes in the domain.
    textOf: (t: Optional<Tenant>) => utils.join( t ? [t.name, t.code] : [])

    ,

    unknownTenant : (id?: Optional<TenantReference>): Tenant => ({

      ...newTenant(),
    
      name: { en: id || undefined! },
      id: unknownTenantId,
      preferences: {}
    
    })

    ,

    intern: (dto: TenantDto) => dto

    ,

    extern: (t: Tenant) => t

    ,

    clone: (t: Tenant): Tenant => ({ ...utils.deepclone(t), id: undefined!, lifecycle: { state: 'inactive' } })


    ,

    comparator: (o1: Optional<Tenant>, o2:  Optional<Tenant>) => multilang.comparator(o1 ? o1.name : undefined, o2 ? o2.name : undefined )



  }

  return self

}


