import { useT } from 'apprise-frontend-core/intl/language';
import { useNewUser, User } from 'apprise-frontend-iam/user/model';


export const errorHandled = "errorHandled"
export const logoutBaseUrl = '/oauth2/logout'
export const publicPrefix = '/public'

export const guest='apprise-guest'

export const useGuestUser = (): User => {

    const create = useNewUser()

    const t = useT()
    const user = create('none');

    user.username = guest
    user.details.firstname = t('user.login_guest')

    return user

}