
import { InfosysConfig } from '#config';
import { useConfig } from 'apprise-frontend-core/config/api';
import { useConfigState } from 'apprise-frontend-core/config/state';
import { EventSubscriber } from 'apprise-frontend-events/subscriptions';
import { PropsWithChildren } from 'react';


export type InfosysLifecycleEvent = {

    version: string
}

export const VersionUpgradeObserver = (props: PropsWithChildren<{}>) => {

    const { children } = props

    const config = useConfigState()
    const current = useConfig<InfosysConfig>()

    const checkUpgrade = (e: InfosysLifecycleEvent) => {

        if ( e.version === undefined || current.version === e.version)
            return
       
        const newconfig ={...config.remoteConfig(), latest:false}

        console.log("lifecycle event", e, "config:", {current: config.remoteConfig(), new:newconfig} )
       
        config.addRemoteConfig(newconfig) 

    }

    return <EventSubscriber name={`console-lifecycle-listener`} subscriptions={[{topic: 'console', onEvent: checkUpgrade}]}>
        {children}
    </EventSubscriber>


}
