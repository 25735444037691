import { useMocks } from 'apprise-frontend-core/client/mocks';
import { useService } from 'apprise-frontend-core/client/service';
import { useLanguage } from 'apprise-frontend-core/intl/language';
import { useIntlMocks } from 'apprise-frontend-core/intl/mocks';
import { useLifecycleMocks } from 'apprise-frontend-core/utils/lifecycle';
import { useLogged } from 'apprise-frontend-iam/login/logged';
import MockAdapter from 'axios-mock-adapter/types';
import shortid from 'shortid';
import { tenantApi } from './calls';
import { tenantType } from './constants';
import { hasTenant, noTenant, Tenant, TenantDto, TenantLifecycleState, TenantResource, useNewTenant } from './model';
import { useTenancyOracle } from '../authz/tenant';


export const useTenantMockery = () => {

    const service = useService()
    const logged = useLogged()
    const tenantmocks = useTenantMocks()
    const tenancy = useTenancyRule()



    return (mock: MockAdapter) => {

        console.log("mocking tenants...")

        const svc = service.get(tenantType)
        const api = `${svc.prefix}${tenantApi}`
        const regapi = RegExp(`${api}/.+$`)

        const tenants = tenantmocks.tenantStore()

        mock.onGet(api).reply(() => [200, tenants.all().filter(tenancy)])

        mock.onGet(regapi).reply(({ url }) => [200, tenants.oneWith(url!.split('/').pop())])

        mock.onPost(api).reply(({ data }) => {

            const now = Date.now()
            const model = JSON.parse(data) as Tenant

            model.id = `T-${shortid()}`
            
            model.lifecycle.created = now
            model.lifecycle.lastModified = now
            model.lifecycle.lastModifiedBy = logged.username

            return [200, tenants.add(model)]
        })

        mock.onPut(regapi).reply(({ data }) => {

            const model = JSON.parse(data) as Tenant

            model.lifecycle.lastModified = Date.now()
            model.lifecycle.lastModifiedBy = logged.username

            return [200, tenants.update(model)]
        })

        mock.onDelete(regapi).reply(({ url }) => {

            tenants.delete(url!.split('/').pop())

            //setPermissions(permissions().filter(({ subject }) => subject !== model.username))

            // model.permissions.map(action => ({ subject: model.username, action })).forEach(p => permissions().push(p))

            return [204]

        })

    }

}



export const useTenantMocks = () => {

    const mocks = useMocks()
    const lang = useLanguage()
    const newTenant = useNewTenant()

    const { mockMultilang } = useIntlMocks()
    const { pastLifecycle } = useLifecycleMocks()


    const self = {


        tenantStore: () => mocks.getOrCreateStore<TenantDto>("tenants")

        ,

        mockTenants: () => ({

            australia: {...self.mockTenant("Australia"), code:'AU'},
            mozambique: {...self.mockTenant("Mozambique"), code: "MZ"}

        })

        ,

        mockTenant: (name: string): TenantDto => {

            const id = `T-${name.toLowerCase().replace(' ', '')}`

            return {
                ...newTenant(),
                id,
                name: mockMultilang(name),
                code: name.toUpperCase(),
                description: mockMultilang(`${name}'s description`),
                lifecycle: pastLifecycle<TenantLifecycleState>('active'),
                preferences: {
                    language: mocks.randomIn(lang.supported()),
                    note: mocks.randomBoolean(.2) ? { [noTenant]: `${name}'s note`, [id]: `${name}'s party note` } : {}
                }

            }

        }
    }

    return self

}

//  returns a visibility check for mocked tenants and resources, based on the logged user.
//  a mock is visibile if:
//  case 1) the user has no tenant.
//  case 2) the resource is in the user's tenant, or in a tenant managed by the user.
//  case 3) the resource _is_ a tenant, and a) it's the user's own tenant, or b) it is managed by the user.
//  do not invoke for mock resources that aren't tenants or don't belong to tenants.

export const useTenancyRule = () => {

    const oracle = useTenancyOracle()

    return (resource: TenantResource | Tenant): boolean => {

        // case 1)
        if (oracle.hasNoTenant())
            return true

        // case 2)
        if (hasTenant(resource))
            return oracle.tenant() === resource.tenant || oracle.isManagerOf(resource.tenant, 'direct')

        // case 3)
        return oracle.tenant() === resource.id || oracle.isManagerOf(resource.id, 'direct')

    }
}