import { Modal, Spin } from 'antd'
import { useClientSession } from 'apprise-frontend-core/client/session'
import { useT } from 'apprise-frontend-core/intl/language'
import { Bridge } from 'apprise-frontend-core/utils/bridge'
import { classname } from 'apprise-ui/component/model'
import { Error } from 'apprise-ui/utils/error'
import capitalize from 'lodash/capitalize'
import * as React from 'react'
import { RiRecordCircleFill } from 'react-icons/ri'
import { Button } from '../button/button'
import "./styles.scss"


export const bridge: Partial<Bridge> = {

    Indicator: ({ msg, waiting, noBleed, children }) => {

        const t = useT()

        // keeps track of the last non-null msg and uses it as fallback after the task is finished and before the spinner stops.
        const ref = React.useRef(msg)

        React.useEffect(() => {

            if (msg)
                ref.current = msg

        }, [msg])

        // note: antd doesn't support generic react nodes for messages, so in this app messages can only be strings.
        return <Spin wrapperClassName={classname('bridge-indicator', noBleed || 'fullbleed-indicator')}
            delay={50}
            spinning={waiting}
            tip={msg as string ?? ref.current ?? t("bridge.default_load_message")}
            size='small'>
            {children}
        </Spin>
    }

    ,

    placeholder: {

        error: <Error />

    }

    ,

    renderError: (e, msg, rethrow) => {

        Modal.error({ title: capitalize(msg), content: capitalize(e.message) })

        if (rethrow)
            throw e

    }

    ,

    renderDialog: props => {

        const { title, msg, okLabel, canceLabel, onOk, onCancel } = props

        return Modal.confirm({ width: 450, title, content: msg, okText: okLabel, cancelText: canceLabel, onOk, onCancel })

    }

    ,

    MockRecordButton: ({ onClick }) => {

        const t = useT()
        const session = useClientSession()
        const persist = session.persistenceActive()

        const btnStyle = { color: persist ? 'orangered' : 'lightgray', filter: persist ? 'drop-shadow(0 0 5px rgba(255,0,0,.8) )' : 'none' }

        return <Button noReadonly noLabel type="ghost" tipDelay={1} tipPlacement='left' tip={t('client.record_btn_tip')} 
                       icon={<RiRecordCircleFill style={btnStyle} />} onClick={onClick} />
    }

}
