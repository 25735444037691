import { useT } from 'apprise-frontend-core/intl/language'
import { Task, useAsyncTask } from './asynctask'
import { AskConsentProps, useFeedback } from './feedback'

// wraps useAsyncTask() to promote consistency and redeuce further boilerplate in standard crud operations.


export type CrudProps = Partial<{

    singular: string
    plural: string
}>

export const useCrud = (props: CrudProps = {}) => {

    const t = useT()
    const tasks = useAsyncTask();

    const feedback = useFeedback()

    const singular = t(props.singular ?? '<singular>').toLowerCase()
    const plural = t(props.plural ?? '<plural').toLowerCase()


    const self = {

        fetchAllWith: <P extends Array<any>, R>(task: Task<P, R[]>) => tasks.make(task, {

            log: (`fetching ${plural}...`),
            show: t('client.fetch_all', {plural}),
            throw: t('client.fetch_all_error', { plural })

        })

        ,

        fetchOneWith: <P extends Array<any>, R>(task: Task<P, R>) => tasks.make(task, {

            show: t('client.fetch_one', {singular}),
            throw: t('client.fetch_one_error', { singular })

        })

        ,

        saveOneWith: <P extends Array<any>, R>(task: Task<P, R>) => {


            const base = tasks.make(task, {

                log: (`saving ${singular}...`),
                show: t('client.save_one', {singular}),
                throw: (t("client.save_one_error", { singular })),
                notify: true

            })

            const self = {

                // can be done() here.
                ...base,

                // or cusotmise config (wraps original to allow chain up withConsent()).
                with: (...args: Parameters<typeof base.with>) => ({ ...base.with(...args), ...self })

                ,

                withConsent: (props: Partial<AskConsentProps>) => (...args:P) => feedback.ask(props).thenRun(()=>base.done()(...args))
            }

            return self

        }

        ,

        saveManyWith: <P extends Array<any>, R>(task: Task<P, R>) => tasks.make(task, {

            log: (`saving ${plural}...`),
            show: t('client.save_many', {plural}),
            throw: (t("client.save_many_error", { plural })),
            notify: true

        })


        ,

        removeOneWith: <P extends Array<any>, R>(task: Task<P, R>) => {

            const base = tasks.make(task, {

                log: (`removing ${singular}...`),
                show: t('client.remove_one', {singular}),
                throw: t("client.remove_one_error", { singular }),
                notify: true

            })


            const self = {

                // can be done() here.
                ...base,

                // or cusotmise config (wraps original to allow chain up withConsent()).
                with: (...args: Parameters<typeof base.with>) => ({ ...base.with(...args), ...self })

                ,

                withConsent: (props?: Partial<AskConsentProps>) => (...args: P) => feedback.askRemovalConsent(singular, props).thenRun(() => base.done()(...args))
            }

            return self
        }


        ,


        removeManyWith: <P extends Array<any>, R>(task: Task<P, R>) => {


            const base = tasks.make(task, {

                log: (`removing ${plural}...`),
                show: t('client.remove_many', {plural}),
                throw: t("client.remove_many_error", { plural }),
                notify: true

            })

            const self = {

                // can be done() here.
                ...base,

                // or cusotmise config (wraps original to allow chain up withConsent()).
                with: (...args: Parameters<typeof base.with>) => ({ ...base.with(...args), ...self })

                ,

                withConsent: (props?: Partial<AskConsentProps>) => (...args: P) => feedback.askRemovalManyConsent(plural, props).thenRun(() => base.done()(...args))
            }

            return self

        }


    }

    return self
}