
import { DotIcon } from 'apprise-ui/utils/icons'
import { AiOutlineTag, AiOutlineTags } from "react-icons/ai"
import { TaggedModule } from './tag/modules'


export const tagType = 'tag'
export const categoryType = 'tagcategory'

export const TagIcon = AiOutlineTag
export const CategoryIcon = AiOutlineTags

export const TagLabelIcon = DotIcon

export const tagSingular =  'tag.singular'
export const tagPlural = 'tag.plural'

export const categorySingular =  'tag.category_singular'
export const categoryPlural = 'tag.category_plural'


export const systemModule: TaggedModule = {

    type: 'system',
    singular: 'tag.system_singular',
    plural: 'tag.system_plural'
}

export const contextCategory = "TGC-system-context"
export const defaultContextTag = "TG-system-defaultcontext"

