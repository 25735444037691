import { Table as AntTable, Empty } from 'antd'
import { useT } from 'apprise-frontend-core/intl/language'
import { Multilang } from 'apprise-frontend-core/intl/multilang'
import { OneOrMore, Optional, utils } from 'apprise-frontend-core/utils/common'
import { useRenderGuard } from 'apprise-frontend-core/utils/renderguard'
import { Button } from 'apprise-ui/button/button'
import { ButtonMenu } from 'apprise-ui/button/buttonmenu'
import { Component, useComponentProps } from 'apprise-ui/component/component'
import { Clicked, Debugged, Memoized, Styled, classname } from 'apprise-ui/component/model'
import { SidebarTransitionAware } from 'apprise-ui/page/sidebar'
import { Readonly } from 'apprise-ui/readonly/model'
import { TextboxProps } from 'apprise-ui/textbox/textbox'
import { Tip } from 'apprise-ui/tooltip/tip'
import { ContextualFilterProps } from 'apprise-ui/utils/filter'
import { useScrollRestoration } from 'apprise-ui/utils/scrollrestoration'
import * as React from 'react'
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'
import Skeleton from 'react-loading-skeleton'
import Autosizer from 'react-virtualized-auto-sizer'
import { useVT } from 'virtualizedtableforantd4'
import { LayoutScrollContext, LayoutScrollProvider } from '../utils/scroll'
import { useTableApi } from './api'
import { TableBar } from './bar'
import { useTableFilters } from './filter'
import { Selection } from "./selection"
import { SortMode, SortSpec, useSort } from './sorting'
import "./styles.scss"


export type TableElement = Record<string, any>

export type TableProps<E extends TableElement=TableElement> = React.PropsWithChildren<Debugged & Styled & Memoized & Readonly & Partial<ContextualFilterProps & {

    name: string;

    layout: 'fixed' | 'custom' | string[]

    noBar: boolean

    headerEvents: HeaderEvents
    noHeader: boolean

    preserveScroll: boolean
    noEmptyPlaceHolder: boolean
    emptyPlaceholder: React.ReactNode
    emptyPlaceholderHeight: number

    noFilter: boolean
    filterProps: FilterProps<E>
    filterBadge: 'left' | 'right'

    fixedHeight: boolean
    rowHeight: number
    mountDelay: true | number

    selection: Partial<Selection<E>>

    rowId: string | ((t: E) => React.Key)
    rowClassName: string | ((record: E, index: number) => string)
    rowEvents: RowEvents<E>


    data: E[]
    total?: number

    initialSort: SortSpec[]

    scrollToRow: (e: E) => boolean

}>>

export type FilterProps<E extends TableElement> = TextboxProps & Partial<{

    count: number
    filter: string
    filterWhat: TextExtractor<E>
    filterWith: (filter: string, what: string) => boolean
    onFilteredDataChange: (filtered: E[]) => any

}>

const defaultMountDelay = 50
const defaultRowHeight = 55
const defaultEmptyPlaceholderHeight = 140



// table frontend, delegates to <InnerTable>.

// 1) integrates <Autosizer> to compute dimensions of scroll area.
// 2) implements a memoization strategy.
// 3) holds state shared between header and table (filter data).

export const Table = <E extends TableElement>($: TableProps<E>) => {

    // name is extracted from TableProps in order to NOT override Component Name
    const { name, ...rest } = $;

    const props = useComponentProps({ ...$ })

    const { data = [], total, emptyPlaceholder, noEmptyPlaceHolder, emptyPlaceholderHeight, filterBadge = 'left', fixedHeight, noMemo, touch, noHeader, rowHeight = defaultRowHeight } = props

    const { filtereddata, filterProps } = useTableFilters(props)


    const [initialDataLength, initialDataLengthSet] = React.useState<number>(total ?? data.length)

    //syncs total if it changes after first render.
    //eslint-disable-next-line
    React.useEffect(() => {

        if (total && total !== initialDataLength)
            initialDataLengthSet(total)

    })

    const filterActive = initialDataLength !== data.length || data.length !== filtereddata.length

    const headerHeight = noHeader ? 0 : rowHeight

    const placeholderHeight = emptyPlaceholderHeight ?? (emptyPlaceholder || noEmptyPlaceHolder ? 0 : defaultEmptyPlaceholderHeight)

    const length = filtereddata.length

    const fullheight = React.useMemo(() => rowHeight * length, [rowHeight, length])

    const contentHeight = fixedHeight ? (filtereddata.length === 0 ? placeholderHeight : fullheight) + headerHeight : 'flex'

    const table = <div style={contentHeight === 'flex' ? { flex: 1 } : { minHeight: contentHeight, height: contentHeight }} className='table-data'>

        <Autosizer disableWidth>

            {({ height }) => {

                //  subtract header (must remain constant!) and 
                const scrollHeight = height ? height - headerHeight : 0

                props.debug && console.log("header height", headerHeight, "parent height", height, "data height", contentHeight, "scroll height", scrollHeight)

                return <SidebarTransitionAware height={height}>

                    <MemoizedInnerTable  {...props}

                        rowId={props.rowId ?? (t => t['id'])}

                        data={filtereddata}

                        height={scrollHeight}

                        fullheight={fullheight}

                        // with noMemo, table is constantly touched.
                        touch={noMemo ? Date.now() : touch} />

                </SidebarTransitionAware>


            }}

        </Autosizer>
        
    </div>

    // when configured, mounts the table body with a given delay to help smoothen page transitions.
    const { content: body } = useRenderGuard({

        when: props.mountDelay ? undefined : true,
        render: table,
        orRun: () => Promise.resolve().then(utils().wait(props.mountDelay === true ? defaultMountDelay : props.mountDelay ?? 0)),
        andRender: <Skeleton count={30} />
    })


    return (

        <LayoutScrollProvider>

            <Component name='table' style={contentHeight === 'flex' ? { height: '100%', flexGrow: 1 } : {}} {...rest}>

                <TableBar {...props} filterActive={filterActive} filteredData={filtereddata} filterProps={filterProps} filterBadge={filterBadge} />

                {body}

            </Component>

        </LayoutScrollProvider>
    )
}

export type InnerTableProps<E extends TableElement> = TableProps<E> & {

    // dimensions of data part only, excludes the header.
    width?: number,
    height: number,
    fullheight: number
}



//  inner table interfaces with Antd's target and integrates it with an extension that virtualise the rows.
//  the table is memoised and refreshes only if the data changes, or if a dedicated property does ('touch')
const MemoizedInnerTable = React.memo(function InnerTable<E extends TableElement>(props: InnerTableProps<E>) {

    const t = useT()

    const {

        name,
        preserveScroll,

        height,
        fullheight,

        rowClassName,
        rowId,
        rowHeight,
        rowEvents,
        headerEvents,

        noHeader,

        scrollToRow,
        noEmptyPlaceHolder,
        emptyPlaceholder = t('ui.table.empty'),

        data = []

    } = props

    // low overscan trades off scroll smoothness for a faster render: apprise rows are graphics-heavy.
    // eslint-disable-next-line
    const [vt, _, VTRef] = useVT(() => ({ overscanRowCount: 1, scroll: { y: height } }), [height])

    const { sort, onSortChange, specs } = useSort(props)

    // eslint-disable-next-line
    const sortedData = React.useMemo(() => sort(data), [data, specs])       // sort if data changes (from outside), or specs (from inside)

    const api = useTableApi(props)

    const rowClasses = (e: E, i: number) => classname('table-row', typeof rowClassName === 'string' ? rowClassName : rowClassName?.(e, i))!

    const emptyText = noEmptyPlaceHolder ? <></> : typeof emptyPlaceholder === 'string' ? <Empty style={{ height }} description={emptyPlaceholder} className='table-no-data' image={Empty.PRESENTED_IMAGE_SIMPLE} /> : emptyPlaceholder

    // number of rows less is such that we're not filling the available height, so scrollbars are undesirable.
    // but we cannot simply unset the `scroll` the property of the table, as there are side-effects with width/hidden settings.
    // so we hide the scroll bar in CSS computing a selector here.
    const shouldScroll = fullheight > height

    const actionBtns = api.actionButtons()
    const { filtereddata } = useTableFilters(props)
    const idx = scrollToRow ? filtereddata.findIndex(scrollToRow) : undefined


    // scroll down if it has to.
    // (    
    // would want to scroll at any height, but there appears to be a bug in virtualization library
    // that breaks calculations when the number of elements doesn't exceed a full height.
    // )
    React.useEffect(() => {

        if (idx && idx * (rowHeight ?? defaultRowHeight) > height)
            VTRef?.current?.scrollToIndex(idx)

    }, [idx, rowHeight, height, VTRef])

    const scrollRestorationRef = React.useRef<HTMLDivElement>(null)

    useScrollRestoration({ name: preserveScroll && !scrollToRow ? name : undefined, scrollable: scrollRestorationRef.current?.querySelector('.ant-table-body') })


    return <div ref={scrollRestorationRef} style={{ height }}>

        <AntTable rowClassName={rowClasses} rowKey={rowId} className={classname(shouldScroll || 'table-noscroll')}

            showHeader={!noHeader}

            dataSource={sortedData}

            locale={{ emptyText }}

            // pagination is fundamentally at odds with virtualisation.
            pagination={false}

            rowSelection={api.selectionConfig()}

            // install virtual row manager.
            components={vt}

            onRow={rowEvents}

            onHeaderRow={headerEvents}

            // makes data area scrollable 
            // (we use CSS to disable it when not required, as setting undefined breaks width/hidden settings).
            scroll={{ y: height }}>


            {
                api.currentLayout().map((colprops, i) => ({

                    ...colprops,

                    className: classname(specs[colprops.name ?? i] && 'sorted-col', colprops.name),

                    title: <ColumnTitle onClick={e => onSortChange(colprops.name ?? i, e?.shiftKey)} mode={specs[colprops.name ?? i]} column={colprops} />

                }))
                    .map(api.adaptProps)
                    .map(({ key, ...rest }, i) =>

                        <AntTable.Column key={key ?? i} {...rest} />

                    )

            }

            {actionBtns &&

                <AntTable.Column {...api.adaptProps({

                    name: "actions",
                    className: 'table-btns-column',
                    align: 'center',
                    width: 60,
                    render: (_, r, i) =>

                        <ButtonMenu type='ghost' noHighlight>
                            {actionBtns.props?.children?.(r, i)}
                        </ButtonMenu>

                })} />

            }


        </AntTable>

    </div>


},
    (oldprops, newprops) =>   // re-render on resize events, when data changes, or on demand (touch).

        oldprops.width === newprops.width &&
        oldprops.height === newprops.height &&
        oldprops.selection?.selected === newprops.selection?.selected &&
        oldprops.data === newprops.data &&
        oldprops.layout === newprops.layout &&
        oldprops.touch === newprops.touch) as <E extends TableElement> (props: InnerTableProps<E>) => JSX.Element        // reasserts type as useMemo doesn't seem to preserve it.



// value of column defaults to row's, they can be specified if they diverge.
// if omitted, they will be inferred  from render or sort, ensuring at least they're consistent.
export type ColumnProps<C extends any = TableElement, E extends any = C> = Partial<{

    name: string;

    title: React.ReactNode
    path: string | string[]
    help: React.ReactNode

    filter: string | string[]
    text: TextExtractor<E>

    defaultLayout: boolean
    pinned: boolean
    
    hidden: boolean
    width: string | number
    align: 'left' | 'center' | 'right'

    render: (value: C, row: E, index: number) => React.ReactNode

    sort: 'external' | true | ((a: C, b: C) => number | undefined)
    sortable: boolean

    classnameFor: (value: C, row: E, index: number) => string | false | undefined
    styleFor: (value: C, row: E, index: number) => React.CSSProperties

    noMemo: boolean
    renderIf: (row: E, oldRow: E) => boolean

    className: string

}>

export type TextExtractor<E extends any = TableElement> = (_: E) => OneOrMore<Optional<string | Multilang>>

export const Column = <C extends any = TableElement, E extends any = C>(_: ColumnProps<C, E>) => {

    return null         //  nothing to render, this is just a property container.

}

type ButtonProps<E extends TableElement> = {

    children: (row: E, index: number) => false | JSX.Element | (JSX.Element | false)[]
}

Table.Buttons = function Buttons<E extends TableElement>(_: ButtonProps<E>) {

    return null;        // just a property carrier

}

Table.Control = function Control(_: React.PropsWithChildren<{}>) {

    return null        // just a property carrier

}

Table.Other = function Control(_: React.PropsWithChildren<{}>) {

    return null        // just a property carrier

}

Table.Filter = function Filter(_: React.PropsWithChildren<Partial<{
    name: string
}>>) {

    return null        // just a property carrier

}


Table.Counter = (_: CounterProps) => {

    return null // just a property carrier
}

type TitleProps<E extends TableElement> = Clicked & {

    column: ColumnProps<E>
    mode: SortMode
}

// replaces ant's own sorters, for greater control over events as the requirement for multisort.
export const ColumnTitle = <E extends TableElement>(props: TitleProps<E>) => {

    const t = useT()

    const { column, onClick, mode } = props

    const sortTip = mode === 'asc' ? 'ui.table.mode_desc' : mode === 'desc' ? 'ui.table.mode_none' : 'ui.table.mode_asc'

    const { title, help, sort, sortable = !!sort } = column

    const cell = <Tip noTip={!help} tip={help} >
        <TableCell>
            {title === undefined ? `col` : title}
        </TableCell>
    </Tip>

    const sorter = <Button noReadonly className='sorter-button' type='ghost' onClick={onClick}>
        <Tip tipDelay={1.5} tip={<div>{t(sortTip)} <div className='table-click-and-shift'>{t('ui.table.mode_shift')}</div></div>}>
            <div className={'apprise-header-sorter'}>
                <AiFillCaretUp className={classname('sorter-up', mode === 'asc' && 'sorter-active')} />
                <AiFillCaretDown className={classname('sorter-down', mode === 'desc' && 'sorter-active')} />
            </div>
        </Tip>
    </Button>

    return sortable ?

        <div className={'apprise-row sorted-header'} onClick={onClick}>
            {cell}
            {sorter}
        </div>

        :

        cell


}


export const TableCell = (props: React.PropsWithChildren<Styled>) => {

    const scrollApi = React.useContext(LayoutScrollContext);

    const ref = React.useRef<HTMLDivElement>(null)

    const [intersected, setVisible] = React.useState(false)

    const isVisible = intersected

    // this alternative lazy renders only horizontally. 
    // const isVisible = scrollApi?.root?.offsetWidth > (ref.current ? ref.current.getBoundingClientRect().left : 0) /* inside the width of the table (to disable virtual vertical scroll) */

    React.useLayoutEffect(() => {

        const element = ref.current

        if (element)
            scrollApi?.subscribe({ element: element, setVisible })

        return () => {

            if (element)
                scrollApi?.unsubscribe(element)
        }

        // eslint-disable-next-line
    }, [scrollApi]);



    return (
        <div ref={ref} style={props.style} className={classname('apprise-table-cell', props.className)}>
            {/* this can be flex-aligned inside parent */}
            <div className='apprise-table-cell-content-container'>
                {/* this is grecefully clipped to keep row-height constant */}
                <div className='apprise-table-cell-content'>
                    {isVisible ? props.children : <span style={{ filter: 'blur(2px) opacity(.3)' }}>#@placeholder</span>}
                </div>
            </div>
        </div>
    )
}

type RowEvents<E extends TableElement> = (record: E, index: number | undefined) => Partial<{
    onClick: () => void,
    onDoubleClick: () => void,
}>

type HeaderEvents = (col) => Partial<{
    onClick: () => void,
    onDoubleClick: () => void,
}>


const siderRowHeight = 20

Table.Sider = <E extends TableElement>(props: TableProps<E> & { name: string }) => {

    const { name = 'side-table', noHeader = true, mountDelay, rowClassName = 'row-sider', filterBadge = 'right', emptyPlaceholder, ...rest } = props

    // a string placeholder renders as a row.
    const placeholderProps = typeof emptyPlaceholder === 'string' ?

        { emptyPlaceholder: <div className='sider-empty-placeholder'>{emptyPlaceholder}</div>, emptyPlaceholderHeight: siderRowHeight }

        :

        {}


    return <Table name={name} layout='fixed' noFilter={false} noHeader={noHeader} className='table-sider sidebar-scrollbar dark-custom-scrollbar' rowClassName={rowClassName} rowHeight={siderRowHeight} filterBadge={filterBadge} {...placeholderProps} {...rest} />


}

export type CounterProps = React.PropsWithChildren<Partial<{

    mode: 'simple' | 'relative' | "total"
    overflow: number
    relativeOverflow: number

}>>

