import { utils } from 'apprise-frontend-core/utils/common'
import { Component } from 'apprise-ui/component/component'
import { classname, Styled, Tall, Wide } from 'apprise-ui/component/model'
import { FieldContext } from 'apprise-ui/field/field'
import { ChangeTracked, Fielded } from 'apprise-ui/field/model'
import * as React from 'react'
import "./styles.scss"

export type Props = Styled & Wide & Tall & React.PropsWithChildren<Partial<{

    horizontal: boolean
    centered: boolean
    fieldDefaults: Fielded<any> &  ChangeTracked<any>
    

}>>

export const Form = (props: Props) => {

    const { horizontal, centered, width,height, children,fieldDefaults ={}, ...rest } = props

    const inheritedFieldDefaults = utils().merge(fieldDefaults, {

        horizontal,
        centered
    })

    const style = {...rest.style,width,height} 
    const classes = classname(`form-${horizontal ? 'horizontal' :  centered ? 'centered' : 'vertical'}`)

    return (

        <Component style={style} name="form" {...rest} componentClassName={classes} >
            <FieldContext.Provider value={inheritedFieldDefaults} >
                {children}
            </FieldContext.Provider>
        </Component>
    )
}