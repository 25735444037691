
import { AxiosError, AxiosRequestConfig } from "axios"
import { Service } from './service'



export type RemoteClientConfiguration = { client: Partial<ClientConfiguration> }

export type ClientConfiguration = CallProps & ClientInterceptors

export type CallProps = {

    // backend services.
    services: Record<string,Service>

    // default request config.
    request: AxiosRequestConfig

    mocks: Partial<{

        debug: boolean
        responseDelay: number
    }>
}


export type ClientInterceptors = {

    onError: ErrorInterceptor[]
    onRequest: RequestInterceptor[]

}

 
export type ErrorInterceptor = (_: AxiosError<any>) => never | void
export type RequestInterceptor = (_: AxiosRequestConfig<any>) => void




export const defaultClientConfiguration: ClientConfiguration = {

    request: {
        timeout: 10000
    },

    services: {
        be: { "prefix": "", label: "Backend" }
    },
    mocks: {
        responseDelay: 200
    }
    ,

    onRequest: [],
    onError:[]
}
