import { useT } from 'apprise-frontend-core/intl/language'
import { CategoryTable } from 'apprise-frontend-tags/category/table'
import { CategoryIcon, TagIcon } from 'apprise-frontend-tags/constants'
import { TagTable } from 'apprise-frontend-tags/tag/table'
import { Button } from 'apprise-ui/button/button'
import { Page } from 'apprise-ui/page/page'
import { SidebarContent } from 'apprise-ui/page/sidebar'
import { Tab, useTabs } from 'apprise-ui/tabs/tab'
import { Titlebar } from 'apprise-ui/titlebar/titlebar'
import { Topbar } from 'apprise-ui/topbar/topbar'
import { AddIcon } from 'apprise-ui/utils/icons'
import * as React from 'react'
import { useCategoryRouting } from './category/routing'
import { useTagOracle } from './oracle'
import { useDownloadDrawer } from './tag/download'
import { useTagRouting } from './tag/routing'

export const allCategoriesTab = 'categories'
export const alltagsTabs = 'tags'

export const AllTagsAndCategoriesList = () => {

    const t = useT()

    const catRouting = useCategoryRouting()
    const tagRouting = useTagRouting()
    const oracle = useTagOracle()

    const download = useDownloadDrawer()

    const { tabs, selectedTabContent, selectedTab } = useTabs([
        
        <Tab id={allCategoriesTab} label={t("tag.allcategories_tab")} icon={<CategoryIcon />}>
            <CategoryTable />
        </Tab>,
        
        <Tab id={alltagsTabs} label={t("tag.alltags_tab")} icon={<TagIcon />}>
            <TagTable />
        </Tab>
    ])

    const addTagBtn = <Button type='primary' icon={<AddIcon />} enabled={oracle.canAdd()} onClick={()=>tagRouting.routeToNewTag()} >
        {t("tag.add", { singular: t('tag.singular') })}
    </Button>

    const addCategoryBtn = <Button type='primary' icon={<AddIcon />} enabled={oracle.canRemove()} onClick={()=>catRouting.routeToNewCategory()} >
        {t("tag.category_add", { singular: t('category.singular') })}
    </Button>

    const addBtn = selectedTab === allCategoriesTab ? addCategoryBtn : addTagBtn

    return <Page>
      
        <Titlebar title={t("tag.list_title")} />

        <Topbar tabs={tabs}>
            {addBtn}
        </Topbar>

        <SidebarContent>
            {addBtn}
            <br />
            {selectedTab===alltagsTabs && download.btn}
        </SidebarContent>

        {selectedTabContent}

        {download.drawer}

    </Page>
}