import { Switch } from 'antd'
import { Sized, Styled, Wide } from 'apprise-ui/component/model'
import { useChangeHelper } from 'apprise-ui/field/changehelper'
import { Field, useFieldProps } from 'apprise-ui/field/field'
import { ChangeTracked, Fielded } from 'apprise-ui/field/model'
import { useReadonlyHelper } from 'apprise-ui/field/readonlyhelper'
import { useResetHelper } from 'apprise-ui/field/resethelper'
import noop from 'lodash/noop'
import * as React from 'react'
import "./styles.scss"


export type SwitchBoxProps = Fielded<boolean> & ChangeTracked<boolean> & Styled & Wide & Sized<'small' | 'normal'> & Partial<{

    children: boolean

    checkedLabel: React.ReactNode
    uncheckedLabel:React.ReactNode

 }>

export const SwitchBox = (clientprops: SwitchBoxProps) => {

    const props = useFieldProps(clientprops)

    const { disabled, defaultValue, children, innerStyle, checkedLabel, uncheckedLabel, size = 'default' } = props

    const currentValue = children ?? defaultValue

    const { pastMode, pastValue } = useChangeHelper(props)

    const value = pastMode ? pastValue : currentValue

    useReadonlyHelper(props)

    useResetHelper(props)

    const initial = React.useRef(children)

    // avoids spurious changes on 'undo' from undefined.
    const onChange = (val: boolean) => props.onChange?.(initial.current!==undefined || val!==defaultValue ? val : undefined)

    props.debug && console.log("switchbox",{currentValue,initial})

    const checkedLabelWrapper = <div className='innerlbl'>{checkedLabel}</div>
    const uncheckedLabelWrapper = <div className='innerlbl'>{uncheckedLabel}</div>

    return <Field name="switchbox" {...props}>
        <Switch size={ size === 'normal' ? 'default' : size } checkedChildren={checkedLabelWrapper} unCheckedChildren={uncheckedLabelWrapper} disabled={disabled} checked={value} style={innerStyle} defaultChecked={defaultValue} onChange={props.readonly ? noop : onChange} />
    </Field>
}