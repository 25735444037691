import { useCall } from 'apprise-frontend-core/client/call'
import { tagType } from '../constants'
import { TagCategory } from '../category/model'

export const tagcategoryapi = "/tagcategory"

export const useCategoryCalls = () => {

    const call = useCall()

    const self = {

      
        
        fetchAllCategories: () => call.at(tagcategoryapi,tagType).get<TagCategory[]>()

        ,

        addCategory: (category:TagCategory)  =>  call.at(tagcategoryapi,tagType).post<TagCategory>(category)
        
        ,  

        updateCategory: (category:TagCategory)  => call.at(`${tagcategoryapi}/${category.id}`,tagType).put<TagCategory>(category)
       
        ,

        updateCategories: (categories:TagCategory[])  => call.at(`${tagcategoryapi}`,tagType).put<TagCategory[]>(categories)
       
        ,  

        deleteCategory: (category:TagCategory)  =>  call.at(`${tagcategoryapi}/${category.id}`,tagType).delete()

    }

    return self;

}
