import { useMode } from "apprise-frontend-core/config/api"
import * as idb from 'idb-keyval'
import { PropsWithChildren, useContext, useState } from "react"
import { useComponentBridge } from '../utils/bridge'
import { storesKey } from "./mocks"
import { ClientContext } from './state'
import { StateProvider } from 'apprise-frontend-core/state/provider'

export const useRecordButton = () => {

  const { MockRecordButton } = useComponentBridge()

  const mode = useMode()

  const session = useClientSession()

  const persist = session.persistenceActive()

  const [, forceRender] = useState(0)

  if (!mode.development)
    return null

  const toggle = () => {
    session.activatePersistence(!persist)
    forceRender(r => ++r)
  }

  return <MockRecordButton onClick={toggle} />
}





export const sessionKey = 'apprise-session'
export const persistenceKey = 'apprise-persistance'

export const useClientSession = () => {

  const state = useContext(ClientContext)

  const self = {

    create: async () => {

      // registers an event listener in case persistence is or becomes active during this session.
      window.addEventListener('beforeunload', () => {

        if (self.persistenceActive())
          sessionStorage.setItem(storesKey, JSON.stringify(state.get().mocks.stores))

      })

      const persistentMode = state.get().persistentMode


      const sessionExists = persistentMode && self.get()

      // restores session storage if we have a persistent session, clear it otherwise.
      if (sessionExists) {

        if (self.persistenceActive()) {

          const stores = JSON.parse(sessionStorage.getItem(storesKey) ?? '{}')

          await idb.entries().then(entries => {

            entries.forEach(([name, store]) => {
              if (stores[name.toString()]) {
                console.log(`replacing ${name} store from index db...`)
                stores[name.toString()] = store
              }
            })

            state.setQuietly(s => s.mocks.stores = stores)

          })

          return

        }

        else
          sessionStorage.removeItem(storesKey)

      }


      console.log("creating client session...")

      // store the runtime id as the session id.
      if (persistentMode)
        sessionStorage.setItem(sessionKey, state.get().runtimeId)


    }

    ,

    get: () => state.get().persistentMode ? sessionStorage.getItem(sessionKey) : state.get().runtimeId

    ,

    // we have a session but it was created by a previous runtime.
    alreadyExists: () => state.get().persistentMode && self.get() !== state.get().runtimeId

    ,

    persistenceActive: () => JSON.parse(sessionStorage.getItem(persistenceKey) || 'false')

    ,

    activatePersistence: (state: boolean) => sessionStorage.setItem(persistenceKey, JSON.stringify(state))


  }

  return self
}


export const TestSession = (props: PropsWithChildren<{
  name?: string
}>) => {

  const parent = useContext(ClientContext)

  const { name = 'test', children } = props

  return <StateProvider context={ClientContext} initialState={{ ...parent.get(), persistentMode: false, runtimeId: name }}>
    {children}
  </StateProvider>

}