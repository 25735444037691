import { useContext } from 'react';
import { Action } from './action';
import { AuthzContext, AuthzOracleFactory } from './context';




// an interface for authorization questions about the logged user: can they carry out this action?
// an implementation must be registered (cf. useOracleFactory), typically by a IAM module.

export type AuthzOracle = {

    isAdmin: () => boolean
    
    /** can perform an action, optionally in the tenant of a target resource. */
    // Note: the answer may be scoped to a tenant, often the tenant of the target resource.
    // tenant managers may do anything in their own tenants, and multi tenant managers may co anything in other tenants too.
    // similarly, regional admins may do anything in some tenants and not others.
    // by default, we assume the scope is the user's own tenant, and this covers tenant managers, multi-managers, and global admins.
    // to correctly handle regional admins, however, the tenant must be explicitly passed in.
    can: (_:Action, tenantScope?:string) => boolean

     /** can perform at least one action among many. */
    canAny: (_:Action[], tenantScope?:string) => boolean


     /** can perform or more actons, optionally in the tenant of a target resource. */
    canAll: (_:Action[], tenantScope?:string) => boolean

    /** can perform an action over some unspecified resource. */
    canForSome: (_:Action) => boolean

}


// returns the current oracle implementation.
export const useLoggedOracle = () => useContext(AuthzContext).get().oracleFactory()



// register an oracle implementation. 
export const useLoggedOracleFactory = () => {

    const state = useContext(AuthzContext)

    return  {

        register: (factory: AuthzOracleFactory) => state.set(s => s.oracleFactory = factory)
    }


}




