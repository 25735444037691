
import { useClientSession } from 'apprise-frontend-core/client/session'
import { Subscription } from 'nats.ws'
import { Fragment, useContext, useEffect, useRef } from 'react'
import { EventConnectionContext } from './context'




export type EventSubscription<E=any> = {

    topic: string

    onEvent: (_: E) => void
}



//


export type SubscriberProps = React.PropsWithChildren<Partial<{

    name: string
    subscriptions: EventSubscription[]

}>>

// subscribes once for events about given topics, as soon as a connection is established.
// unsubscribes from everything before unmounting.

export const EventSubscriber = (props: SubscriberProps) => {

    const { children, name, subscriptions = [] } = props

    const { connection, codec } = useContext(EventConnectionContext).get()

    const session = useClientSession()

    const handles = useRef<Subscription[]>([])

    useEffect(() => {

        if (!connection || connection.isClosed())
            return


        console.log(`subscribing ${name} to:`, subscriptions.map(s => s.topic))

        handles.current = subscriptions.map(({ topic, onEvent }) => {

            const handle = connection.subscribe(topic)

            const waitOnMessage = async () => {

                for await (const m of handle) {

                    const event = codec.decode(m.data) as { originSession: string }

                    if (!event)
                        continue

                    const sameSession = event.originSession && session.get() === event.originSession

                    
                    if (sameSession)
                        continue

                    try {

                        console.log(`${name} received`, event)

                        onEvent(event)
                    }
                    catch (e) {
                        console.error("can;'t process event:", e)
                    }

                }
            }

            waitOnMessage()

            return handle

        })


        // we don't expect changes to code or subscriptions, so we don't support them.
        // eslint-disable-next-line
    }, [connection])

    // 2. unsubscribe on unmount.
    useEffect(() =>

        () => {

            console.log(`unsubscribing ${name} from events`)
            handles.current.forEach(h => { h.unsubscribe() })

        }

        , [name])

    return <Fragment>
        {children}
    </Fragment>

}