

export type Item = {

    data: string

}


export const defaultItemType="default"
export const defaultPath="/"
