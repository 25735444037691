import { utils } from 'apprise-frontend-core/utils/common';
import { createContext, useContext } from 'react';
import { fallbackStateOver, State } from '../state/api';
import { defaultIntlConfiguration, IntlConfiguration } from "./config";
import { Language, useLanguage } from './language';




export type TranslationFunction = (msg: string | string[], ...args: any[]) => string
export type LanguageSwitcher = (msg: string, ...args: any[]) => Promise<any>

export type IntlState = {

    // an integration point for a translation function.
    t: TranslationFunction

    // an integration point for setting the current language.
    changeLanguage: LanguageSwitcher

    // the current language
    language: Language

    // internal configuration: used when there's no configuration support, or until there is.
    config: IntlConfiguration

}

// fill is for an implementation that doesn't exist or hasn't kicked in yet. 
export const initialIntlState: IntlState = {

    t: (msg: string | string[]) => utils().arrayOf(msg)[0]  // does nothing.
    ,
    changeLanguage: () => Promise.resolve()   // does nothing.
    ,
    language: undefined!  
    ,
    config: defaultIntlConfiguration

}

export const InltContext = createContext<State<IntlState>>(fallbackStateOver(initialIntlState))


export const useIntlConfig = () => {

    const state = useContext(InltContext) 

    return state.get().config

}

export const useIntl = () => {

    const state = useContext(InltContext) 
    
    const lang = useLanguage()

    const self = {

        // overlays configuration onto default configuration, stores it, and sets the configured language. 
        init: (config: Partial<IntlConfiguration>) => {

            const merged = utils().merge(state.get().config, config ?? {})

            state.set(s => s.config = merged)

            lang.change(merged.defaultLanguage)
        }

        ,

        // stores translaton function and language switcher implementations.
        register: (tfun: TranslationFunction, switcher: LanguageSwitcher) => state.setQuietly( s => {

            s.t = tfun
            s.changeLanguage = switcher
        
        })


    }

    return self
}

