

import { Action, useActions } from 'apprise-frontend-core/authz/action';
import { standardActions } from 'apprise-frontend-core/authz/constants';
import { AuthzOracle } from 'apprise-frontend-core/authz/oracle';
import { noTenant } from 'apprise-frontend-iam/tenant/model';
import { User } from 'apprise-frontend-iam/user/model';
import { tenantActions } from './oracle';




/** 
 *  returns an authz oracle to answer questions based on the permisssion of an arbitrary user. 
 *  plugged as the AuthzOracle for the logged user, can also be used directly for users other than the loggd one.
*/
export const useUserOracle = () => {

    const actions = useActions()

    return {

        given: (user: User): AuthzOracle => {

            const perms = user?.permissions ?? []

            const oracle = {


                isAdmin: () => oracle.can(standardActions.do_anything)

                ,


                // cf. documentation on AuthzOracle.
                can: (action: Action, tenantScope = user.tenant) => {

                    // passthrough rules: tenant managers can do anything in the scope of that tenant.
                    // even if they don't have the specific permissions, they're like global admins there.
                    // these include multi-tenant managers like regional admins and multimanagers.
                    // but it applies only for actions over tenant resources.
                    const passthrough = action.actionType !== 'admin' && (

                        // but what if there is no tenant in scope? 
                        // this happens with regional admins and actions like "add resource":
                        // 1. we can't get a tenant from the user (it's tenantless). 
                        // 2. we can't get one from the resource yet (we haven't created it yet). 
                        // Yet we shouldn't block the regional admin: so we check thtat the user manages at least some tenant, ie. the 
                        // action is 'plausible'. 
                        // BUT the application will need to guard the subequent actions of the admin, eg. filter out tenants it doesn't manage. 
                        tenantScope === noTenant ?

                        oracle.canForSome(tenantActions.manage)
                        :
                        actions.impliedByAny(actions.specialise(tenantActions.manage, tenantScope), perms))

                    return passthrough || actions.impliedByAny(action, perms)

                }

                ,

                canAny: (actions: Action[], tenantScope?: string) => actions.some(a => oracle.can(a, tenantScope))

                ,

                canAll: (actions: Action[], tenantScope?: string) => !actions.some(a => !oracle.can(a, tenantScope))

                ,

                canForSome: (action: Action) => actions.matchAny(action, perms)


            }

            return oracle
        }
    }

}

