import { useActions } from 'apprise-frontend-core/authz/action';
import { ActionDto } from 'apprise-frontend-core/authz/action';
import { standardActions } from 'apprise-frontend-core/authz/constants';
import { useMocks } from 'apprise-frontend-core/client/mocks';
import { useService } from 'apprise-frontend-core/client/service';
import { useLanguage } from 'apprise-frontend-core/intl/language';
import { useLifecycleMocks } from 'apprise-frontend-core/utils/lifecycle';
import { usePermissionMocks } from 'apprise-frontend-iam/permission/mockery';
import { useTenancyRule } from 'apprise-frontend-iam/tenant/mockery';
import { noTenant, Tenant } from 'apprise-frontend-iam/tenant/model';
import { tenantActions } from 'apprise-frontend-iam/authz/oracle';
import MockAdapter from 'axios-mock-adapter/types';
import { loggedApi, useRefApi, userApi } from './calls';
import { userType } from './constants';
import { useNewUser, User, UserDto, UserLifecycleState } from './model';

export const useLoggedUserMockery = () => {

    const service = useService()
    const usermocks = useUserMocks()


    return (mock: MockAdapter) => {

        console.log("mocking logged user...")

        const svc = service.get(userType)
        const userLoggedApi = `${svc.prefix}${loggedApi}`

        mock.onGet(userLoggedApi).reply(() => [200, usermocks.logged()])


    }

}

export const useUserMockery = () => {

    const service = useService()
    const usermocks = useUserMocks()
    const perm = usePermissionMocks()
    const tenancy = useTenancyRule()


    return (mock: MockAdapter) => {

        console.log("mocking users...")

        const svc = service.get(userType)
        const api = `${svc.prefix}${userApi}`
        const stubapi = `${svc.prefix}${useRefApi}`
        const regapi = RegExp(`${api}/.+$`)

        //const newUserId = () => `U-${shortid()}`

        const users = usermocks.userStore()
        const logged = usermocks.logged()

        mock.onGet(api).reply(() => [200, users.all().filter(tenancy)])

        mock.onGet(stubapi).reply(() => [200, users.all().filter(u => u.tenant === noTenant).map(({ username, details }) => ({ username, name: `${details.firstname} ${details.lastname}` }))])

        mock.onGet(regapi).reply(({ url }) => {

            const user = users.oneWith(url!.split('/').pop())

            if (!user)
                return [404]

            const permissions = perm.store().all().filter(p => p.subject === user.username).map(p => p.action)

            const augmentedUser = { ...user, permissions } // mustn't change stored mock directly.

            return [200, augmentedUser]

        })

        mock.onPost(api).reply(({ data }) => {

            const now = Date.now()
            const model = JSON.parse(data) as User

            model.lifecycle.created = now
            model.lifecycle.lastModified = now
            model.lifecycle.lastModifiedBy = logged.username

            return [200, users.add(model)]
        })

        mock.onPut(regapi).reply(({ data }) => {

            const model = JSON.parse(data) as User

            model.lifecycle.lastModified = Date.now()

            model.lifecycle.lastModifiedBy = logged.username

            return [200, users.update(model)]
        })

        mock.onDelete(regapi).reply(({ url }) => {

            users.delete(url!.split('/').pop())

            return [204]

        })

    }

}



export const useUserMocks = () => {

    const mocks = useMocks()
    const lang = useLanguage()
    const permissionmocks = usePermissionMocks()
    const { specialise } = useActions()
    const { manage } = tenantActions
    const newUserIn = useNewUser()


    const { pastLifecycle } = useLifecycleMocks()

    // a store for the single logged user, we keep also previous logins in it and access only the most recent.
    const loggedStore = () => mocks.getOrCreateStore<UserDto>("logged", { id: u => u.username })


    const self = {


        logged: () => loggedStore().all()[0] ?? self.mockCoordinators().apprise

        ,

        setLogged: (user: string | UserDto) =>

            loggedStore().add(typeof user === 'string' ? self.userStore().get(user) : user)


        ,

        userStore: () => mocks.getOrCreateStore<UserDto>("users", {

            id: u => u.username,

            callbacks: {

                beforeChange: u => ({ ...u, permissions: [] }),
                onChange: permissionmocks.updateWith,
                onDelete: permissionmocks.deleteWith
            }

        })



        ,

        mockCoordinators: () => ({

            apprise: self.mockUser("Apprise Support", [standardActions.do_anything]),
            johndoe: self.mockUser("John Doe", [standardActions.do_anything]),
            janedoe: self.mockUser("Jane Doe", [standardActions.do_anything]),
            joeaverage: self.mockUser("Joe Average"),
            alainuntel: self.mockUser("Alain Untel", [standardActions.do_anything]),
            luluunetelle: self.mockUser("Lulu Unetelle", [standardActions.do_anything]),
            marcelduchmol: self.mockUser("Marcel Duchmol")

        })

        ,

        mockTeam: (tenant: Tenant, max: number = 3) =>

            [
                self.mockUser(`${tenant.name.en} Manager 1`, [specialise(manage, tenant.id)], tenant.id),
                self.mockUser(`${tenant.name.en} Manager 2`, [specialise(manage, tenant.id)], tenant.id),
                ...Array.from({ length: mocks.randomNumberBetween(1, max) }).map((_, i) =>

                    self.mockUser(`${tenant.name.en} user ${i + 1}`, [], tenant.id)

                )
            ]





        ,

        mockUser: (name: string, permissions: ActionDto[] = [], tenant: string = noTenant): UserDto => ({

            ...newUserIn(tenant),

            username: `${name.toLowerCase().replace(/\s/g, '-')}`,
            lifecycle: pastLifecycle<UserLifecycleState>('active'),
            permissions,
            details: {
                firstname: name.split(' ')[0],
                lastname: name.split(' ').slice(1).join(' '),
                email: `${name.split(' ')[0]?.toLowerCase()}.${name.split(' ')[1]?.toLowerCase()}@apprisecraft.com`,
                preferences: {
                    language: mocks.randomIn(lang.supported()),
                    note: mocks.randomBoolean(.2) ? { [noTenant]: `${name}'s note`, [tenant]: `${name}'s party note` } : {}

                }
            },
        })

    }

    return self

}

