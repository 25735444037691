import { fallbackStateOver, State } from 'apprise-frontend-core/state/api';
import { createContext } from 'react';


export type PreloadState = {
 
    loaders: Record<string,Promise<any>>
}

const noState: PreloadState = { loaders: {}  }


export const PreloadContext = createContext<State<PreloadState>>(fallbackStateOver(noState))