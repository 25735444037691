import Text from 'antd/lib/typography/Text'
import Title from 'antd/lib/typography/Title'
import { Component } from 'apprise-ui/component/component'
import { Styled } from 'apprise-ui/component/model'
import { PageModelContext } from 'apprise-ui/page/page'
import * as React from 'react'
import "./styles.scss"


export type Props = React.PropsWithChildren<Styled & {

    title: React.ReactNode
    secondary: React.ReactNode
}>

export const Titlebar = ($: Partial<Props>) => {

    const { title = 'Title', secondary, children } = $

    const ctx = React.useContext(PageModelContext)

    React.useEffect(() => {

        if (typeof title === 'string')
            document.title = `${ctx.title} - ${title}`

    // eslint-disable-next-line
    }, [title])

    React.useEffect(() => {

        return () => {
            document.title = ctx.title!
        }

    // eslint-disable-next-line
    }, [])

    return <Component name='titlebar' {...$}>

        <div className='titlebar-main'><Title>{title}</Title></div>
        <div className='titlebar-secondary'><Text>{secondary}</Text></div>
        {children &&

            <div className='titlebar-info'>
                {children}
            </div>
        }

    </Component>
}

