import { useConfig } from 'apprise-frontend-core/config/api'
import { IntlConfiguration } from './config'
import { Multilang } from './multilang'
import { useIntlConfig } from './state'




export const useIntlMocks = () => {

    // takes required langs first from global config, in case we have just loaded it.
    // (intl config won't have synced yet). but we fallback to inlt config in case global
    // didn't contain any intl config.
    const {intl:intlconfig} = useConfig<{intl:IntlConfiguration}>()
    const intl = useIntlConfig()

    const langs = intlconfig?.requiredLanguages ?? intl.requiredLanguages

    const self = {

        mockMultilang: (en: string): Multilang => langs.filter(l=>l!=='en').reduce((acc, l) => ({ ...acc, [l]: `${en} [${l}]` }), {en})

    }

    return self
}
