import { utils } from 'apprise-frontend-core/utils/common';
import { AskConsentProps, useFeedback } from 'apprise-ui/utils/feedback';
import { paramsIn, updateQuery } from 'apprise-ui/utils/routing';
import * as React from 'react';
import { Prompt, useHistory } from "react-router";
import { useLocation } from 'react-router-dom';


// intercepts route changes conditonally, and allows it only if the user consents to it.
// by default, it assumes we're guarding for unsaved changed, but all the confirmation dialog can be fully customised.

// can be used as a parent or a sibling.

type Props = React.PropsWithChildren<Partial<AskConsentProps & {

    when: boolean
    ignoreQueryParams?: boolean | string | string[]
}>>

export const RouteGuard = (props: Props) => {

    const history = useHistory()

    const location = useLocation()

    const { when, ignoreQueryParams=true, children } = props

    const { askDiscardConsent } = useFeedback()

    return <>{

        <Prompt when={when} message={targetLocation => {

            const targetRoute = `${targetLocation.pathname}${targetLocation.search}`

            const ignores = typeof ignoreQueryParams === 'boolean' ?  [] : utils().arrayOf(ignoreQueryParams) 

            // current search changed by setting all ignored params to value @ target route
            const ignoresSearch =  ignores.length === 0 ? location.search : updateQuery(location.search).with(params => {

                const targetParams = paramsIn(targetLocation.search)
                ignores.forEach(i => params[i] = targetParams[i])

            })

            const samePath = location.pathname === targetLocation.pathname

            const routesAreSameExceptForIgnoredParams = samePath && (ignoreQueryParams === true || utils().deepequals(paramsIn(targetLocation.search),paramsIn(ignoresSearch)))

            if (targetLocation.state === 'pass' || routesAreSameExceptForIgnoredParams)
                return true

            askDiscardConsent(props).thenRun(() => history.push(targetRoute, 'pass'))

            return false

        }} />


    }

        {children}

    </>

}