import { useActions } from 'apprise-frontend-core/authz/action';
import { Action, ActionDto } from 'apprise-frontend-core/authz/action';


export type PermissionDto = {

    subject: string
    action: ActionDto

}

export type Permission = {

    subject: string
    action: Action

}

export type ChangesDto = {

    granted?: PermissionDto[]
    revoked?:PermissionDto[]
}


export const usePermissions = () => {

    const actions = useActions()


    const self = {

        idOf: (p: Permission) => `${p.subject}:${actions.idOf(p.action)}`

        ,

        idmapOf: (ps: Permission[]): Record<string, boolean> => ps.reduce((acc, p) => ({ [self.idOf(p)]: true, ...acc }), {})

        ,

        intern: ({ subject, action }: PermissionDto): Permission => ({ subject, action: actions.intern(action) })

        ,

        extern: ({ subject, action }: Permission): PermissionDto => ({ subject, action: actions.extern(action) })

        ,


        externChanges: (current: Permission[], initial: Permission[]): ChangesDto => {

            const currentMap = self.idmapOf(current);
            const initialMap = self.idmapOf(initial);

            const granted = current.reduce((acc, p) => initialMap[self.idOf(p)] ? acc : [p, ...acc], [] as Permission[])
            const revoked = initial.reduce((acc, p) => currentMap[self.idOf(p)]  ? acc : [p, ...acc], [] as Permission[])

            return { ...(granted.length > 0 ? { granted: granted.map(p => self.extern(p)) } : {}), ...(revoked.length > 0 ? { revoked: revoked.map(p => self.extern(p)) } : {}) }

        },

        reconcile: (permissions: Permission[]): Permission[] => {

            // sort upfront from most generic to most specific, so that reconciliation can be treated in 'streaming' fashion
            const sorted = [...permissions].sort((p1, p2) => actions.comparator(p1.action, p2.action))

            //  simply add one by one if not already implied by previous ones
            const outcome = sorted.reduce((acc, p) => actions.impliedByAny(p.action, acc.filter(pp => p.subject === pp.subject).map(p => p.action)) ? acc : [p, ...acc], [] as Permission[])

            //console.log("to reconcile:",permissions,"reconciled:", outcome);

            return outcome;

        }

    }

    return self;



}