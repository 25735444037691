import * as Antd from "antd"
import { useMultilang } from 'apprise-frontend-core/intl/multilang'
import { Component } from "apprise-ui/component/component"
import { classname, Styled } from "apprise-ui/component/model"
import * as React from "react"
import { Tipped } from './model'
import "./styles.scss"


// wraps the router dependency

type Props = React.PropsWithChildren<Tipped & Styled>

export const Tip = (props: Partial<Props>) => {

    const ml = useMultilang()

    const { tip, noTip, children, tipType = 'default', tipWidth, tipDelay = 0.5, tipPlacement, tipClassName, tipMode='dark', ...rest } = props

    const noop = noTip || !tip


    return noop ?

        <React.Fragment>{children}</React.Fragment>

        :

        // don't send full props up! because component adds a <Tip> this would loop forever.
        <Component name="tooltip" {...rest} >
            <Antd.Tooltip overlayStyle={{ minWidth: tipWidth, maxWidth: tipWidth ? undefined: 400, textAlign: 'center' }}
                openClassName='tooltip-inner-open'
                overlayClassName={classname('tooltip-inner', `tooltip-${tipType}`, `tooltip-${tipMode}`, tipClassName)}
                mouseEnterDelay={tipDelay}
                placement={tipPlacement}
                title={ml.test(tip) ? ml.l(tip) : tip}>
                <div style={{display:'inherit'}}>
                    {children}
                </div>
            </Antd.Tooltip>

        </Component>

}
