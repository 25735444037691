import { any } from 'apprise-frontend-core/authz/constants';
import { StoreProps, useMocks } from 'apprise-frontend-core/client/mocks';
import { useService } from 'apprise-frontend-core/client/service';
import { tenantType } from 'apprise-frontend-iam/tenant/constants';
import { UserDto } from 'apprise-frontend-iam/user/model';
import MockAdapter from 'axios-mock-adapter/types';
import { permissionType } from './constants';
import { PermissionDto } from './model';
import { ChangesDto, permissionApi, PermissionFilter } from './store';

export const usePermissionMockery = () => {


    const service = useService()
    const permissions = usePermissionMocks()

    return (mock: MockAdapter) => {

        console.log("mocking permissions...")

        const svc = service.get(permissionType)
        const api = `${svc.prefix}${permissionApi}`

        mock.onPost(`${api}/search`).reply(({ data }) => {

            const filter = JSON.parse(data) as PermissionFilter

            const filtered = permissions.store().all()

                //.filter( p=> lookup(p.subject)) // tenancy: only users that have passed the rule of tenancy
                .filter(
                    // tenant actions always included so that passthrough policy can be evaluated   
                    p => p.action.type === tenantType || p.action.type === any ||

                        ( //applies filter
                            (!filter.subjects || filter.subjects.includes(p.subject))
                            && (!filter.resources || filter.resources.includes(p.action.resource!))
                            && (!filter.types || filter.types.includes(p.action.type))
                        )
                )

            return [200, filtered]
        })

        mock.onPut(`${api}`).reply(({ data }) => {

            const request = JSON.parse(data) as ChangesDto

            permissions.store().deleteManyObjects(...request.revoked ?? [])

            permissions.store().addMany(...request.granted ?? [])

            return [200]
        })

    }
}

export const usePermissionMocks = () => {

    const mocks = useMocks()

    const self = {

       
        store: (props?:StoreProps<PermissionDto>) => mocks.getOrCreateStore<PermissionDto>("permissions", {

            id:  p => `${p.subject}:${p.action.type}:${p.action.labels}`,

            ...props
        })
        
        ,

       
        updateWith: (users: UserDto[]) => {

            self.deleteWith(users,'quietly')

            const permissions = users.flatMap(u => u.permissions.map(action => ({ subject: u.username, action })))

            console.log(`synced ${permissions.length} permission(s) from ${users.length} user(s)`)

            self.store().addMany(...permissions)

        }

        ,

        deleteWith: (users: UserDto[],quietly?:'quietly') => {

            const ids = users.map(u => u.username)

            self.store().deleteMatching(({ subject }) => ids.includes(subject))

            quietly || console.log(`removed all permissions about ${users.length} user(s)`)
         
        }
    }

    return self
}