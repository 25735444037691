import { useMode } from 'apprise-frontend-core/config/api';
import { Optional } from 'apprise-frontend-core/utils/common';
import { useCrud } from 'apprise-ui/utils/crudtask';
import { useContext } from 'react';
import { useTenancyOracle } from '../authz/tenant';
import { useTenantCalls } from './calls';
import { tenantPlural, tenantSingular } from './constants';
import { TenantChangeEvent } from './events';
import { Tenant, TenantReference, useTenantModel } from './model';
import { TenantCacheContext } from './provider';


export const useTenantStore = () => {

    const state = useContext(TenantCacheContext)

    const oracle = useTenancyOracle()
    const calls = useTenantCalls()

    const model = useTenantModel()
    const mode = useMode()

    const crud = useCrud({ singular: tenantSingular, plural: tenantPlural })

    const self = {


        all: () => state.get().all

        ,

        allSorted: () => [...state.get().all].sort(model.comparator)


        ,

        allRefs: () => state.get().allRefs


        ,

        lookup: (id: Optional<TenantReference>, useRefFallback = true) => id ? [...self.all(), ...useRefFallback ? self.allRefs() : []].find(t => t.id === id) : undefined

        ,

        safeLookup: (id: Optional<TenantReference>) => self.lookup(id) ?? model.unknownTenant(id)

        ,

        fetchAll: crud.fetchAllWith(async () => {

            const [tenants, refs] = await Promise.all(

                oracle.isGuest() ? [[], calls.fetchAllRefs()] :
                    oracle.isTenantUser() ? [calls.fetchAllTenants(), calls.fetchAllRefs()] :
                        [calls.fetchAllTenants()]

            )



            state.set(s => {

                s.all = tenants
                s.allRefs = refs?.sort(model.comparator) ?? []
            })

            return tenants

        }).done()

        ,

        fetchOne: crud.fetchOneWith(calls.fetchOne).done()

        ,

        save: crud.saveOneWith(async (tenant: Tenant) => {

            const isNew = !tenant.lifecycle.created

            const saved = isNew ? await calls.add(tenant) : await calls.update(tenant)

            state.set(s => s.all = isNew ? [saved, ...s.all] : s.all.map(t => t.id === saved.id ? saved : t))

            return saved

        }).done()

        ,


        push: (event: TenantChangeEvent) => {

            const pushed = event.tenant

            state.set(s => {

                switch (event.type) {

                    case 'add': s.all = [...self.all(), pushed]; break;
                    case 'remove': s.all = s.all.filter(t => t.id !== pushed.id); break;
                    case 'change': s.all = s.all.map(t => t.id === pushed.id ? pushed : t); break;

                }

            })


        }

        ,

        removeSilently: crud.removeOneWith(async (tenant: string) => {

            await calls.delete(tenant)

            state.set(s => s.all = s.all.filter(c => c.id !== tenant))


        }).done()

        ,


        removeMany: crud.removeManyWith(async (list: TenantReference[], onConfirm?: () => void) => {

            if (!mode.development)
                throw Error("this is a dev-only operation")

            list.forEach(calls.delete) // best effort

            state.set(s => s.all = s.all.filter(t => !list.includes(t.id)))

            onConfirm?.()

        }).withConsent() //dev-only operation

    }

    return self


}