import { useActions } from 'apprise-frontend-core/authz/action'
import { useCall } from 'apprise-frontend-core/client/call'
import { usePreload } from 'apprise-frontend-core/client/preload'
import { noTenant } from 'apprise-frontend-iam/tenant/model'
import { useNewUser, User, UserDto } from './model'
import { useUserConfiguation } from './config'



const svc="admin"
export const userApi = "/user"
export const useRefApi = "/userrefs"
export const loggedApi = "/logged"

export const useUserBinder = () => {

    const actions = useActions()

    const self = {

        intern : (dto: UserDto): User => ({ ...dto, permissions: actions.reconcile(dto.permissions.map(actions.intern)), fetched:true }),
        extern : (u: User) => ({ ...u, permissions: u.permissions.map(actions.extern) })
    
    }

    return self 
}

export type UserRef = {

    username: string
    name: string
  
}

export const useUserCalls = () => {

    const newUser = useNewUser()
    
    const call = useCall()

    const {intern,extern} = useUserBinder()
    
    const config = useUserConfiguation()
    
    const preload = usePreload()
    
    const self = {

        // come with no permissions, so won't need to be interned.
        fetchAll: () =>(
            
                preload.get<User[]>(userApi) ?? call.at(`${userApi}?permissions=${!config.loadSummaries}`,svc).get<User[]>()
            
        )
        .then(fetched => config.loadSummaries ? fetched : fetched.map(intern) )

        ,

        fetchAdmins: () => (preload.get<UserRef[]>(useRefApi) ??  call.at(useRefApi,svc).get<UserRef[]>()).then(users => users.map(({ username, name }) => {

            const base = newUser(noTenant);

            base.lifecycle.state = 'active'
            base.username = username
         
            const [firstName, ...lastnames] = name.split(' ')

            base.details.firstname = firstName
            base.details.lastname = lastnames.join(' ')

            return base

        }))

        ,

        fetchOne: (username: string) =>  call.at(`${userApi}/${username}`,svc).get<User>().then(intern)

        ,

        add: (user:User) =>  call.at(userApi,svc).post<User>(extern(user)).then(intern)
    
        ,
    
        update: (user:User) => call.at(`${userApi}/${user.username}`,svc).put<User>(extern(user)).then(intern)
    
        , 

        updateMany: (many: User[]) => call.at(`${userApi}/bulk`, svc).put<User[]>(many.map(extern)).then(saved=>saved.map(intern))

        ,

        delete: (username:string) => call.at(`${userApi}/${username}`,svc).delete()

        ,

        updateProfile: (user:User) => call.at(`${userApi}/${user.username}/profile`,svc).put<User>(extern(user)).then(intern)
    }

    return self;

}
