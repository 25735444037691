import { useCall } from 'apprise-frontend-core/client/call'
import { usePreload } from 'apprise-frontend-core/client/preload'
import { useUserBinder } from 'apprise-frontend-iam/user/calls'
import { User } from 'apprise-frontend-iam/user/model'



const svc="admin"
export const userApi = "/user"
export const loggedApi = "/logged"

export const useLoggedUserCalls = () => {

    const call = useCall()
    const { intern } = useUserBinder()

    const preload = usePreload()

    const self = {

        fetchLoggedUser: () => (preload.get<User>(loggedApi) ?? call.at(loggedApi,svc).get<User>()).then(intern)

    }

    return self;

}
