
import { useT } from 'apprise-frontend-core/intl/language'
import { TagCategory } from 'apprise-frontend-tags/category/model'
import { Tag } from 'apprise-frontend-tags/tag/model'
import { Label, LabelProps } from 'apprise-ui/label/label'
import { useCategoryRouting } from '../category/routing'
import { CategoryIcon, tagType } from '../constants'
import { unknownCategory } from './model'
import { useCategoryStore } from './store'

export type CategoryLabelProps = Partial<LabelProps & {

    category: string | TagCategory
    tag: Tag

}>

export const NoCategoryLabel = (props: Pick<CategoryLabelProps,'mode'>) => {

        const t = useT()

        return <Label mode={props.mode} icon={<CategoryIcon />} title={t('tag.no_category')} />
}

export const CategoryLabel = (props: CategoryLabelProps) => {

    const store = useCategoryStore()

    const { tag, mode, category = tag?.category } = props

    const router = useCategoryRouting()

    if (tag && !tag.category)
        return <NoCategoryLabel mode={mode} />

    const resolved = typeof category === 'string' ? store.safeLookupCategory(category) : category

    const unknown = !resolved || resolved.id === unknownCategory

    const inactive = resolved?.lifecycle?.state === 'inactive'

    const route = router.categoryDetailRoute(resolved?.id)

    return <Label readonly={resolved?.guarded} unknown={unknown} inactive={inactive} icon={<CategoryIcon />} title={resolved?.name} tip={resolved?.description} linkTarget={tagType} linkTo={route} {...props} />
}
