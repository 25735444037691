
import { MockHorizonContext } from 'apprise-frontend-core/client/mockhorizon'
import { useMockery } from 'apprise-frontend-core/client/mocks'
import { useRenderGuard } from 'apprise-frontend-core/utils/renderguard'
import { useLoggedUserMockery, useUserMocks } from 'apprise-frontend-iam/user/mockery'
import { useContext } from 'react'
import { LoginProps } from './login'
import { useLoggedStore } from './store'



export const LoggedUserLoader = (props: LoginProps) => {

    const belowMockHorizon = useContext(MockHorizonContext)

    const { children, mock= belowMockHorizon, mockLogged = !!mock } = props

    const store = useLoggedStore()
    const mocks = useMockery()
    const usermocks = useUserMocks()
    const mockery = useLoggedUserMockery()

    
    const setupMockery = async () => {

        if (!mock)
            return

        if (typeof mockLogged !== 'boolean') {

            usermocks.setLogged(mockLogged)
        }

        mocks.initMockery(mockery)

    }

    const { content } = useRenderGuard({

        render: children,

        orRun: () => setupMockery().then(store.fetchLoggeduser)


    })

    return content
}