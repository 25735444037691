
import { useUtils } from 'apprise-frontend-core/utils/common';
import { usePrevious } from 'apprise-frontend-core/utils/hooks';
import { useFilterState } from 'apprise-ui/utils/filter';
import { useContext, useEffect, useMemo } from 'react';
import { useTableApi } from './api';
import { TableDataContext } from './context';
import { TableData } from './provider';
import { TableElement, TableProps } from './table';


// empty array to use as a stable default when no data is provided.
const noData = []

export const useTableFilters = <E extends TableElement>(props: TableProps<E>) => {

    const utils = useUtils()

    const { name, context = 'table', contextKey = 'table', data = noData, filterProps, selection } = props

    const state = useFilterState(context)

    const api = useTableApi(props)

    const filter = filterProps?.filter ?? state.get(contextKey)

    /////////////////////////////////  applies the text filter whenever the data or the filter change.

    const filtereddata = useMemo(() => {

        const filterWhat = (s: E) => {

            const extracted = utils.join([...utils.arrayOf(filterProps?.filterWhat?.(s)), ...api.currentFilterText(s)])

            props.debug && console.log({ extracted })

            return extracted
        }
        
        const filterWith = filterProps?.filterWith ?? ((f, text) => (text ?? '').toLowerCase().includes(f?.toLowerCase()))

        props.debug && console.log("applying text filter...")

        return filter ? data.filter(t => {

            const what = filterWhat(t ?? '');

            return filterWith(filter, what);
        }) : data



        // eslint-disable-next-line 
    }, [data, filter])


    ////////////////////////////////  reset filter state when new filters arrive or depart from layout changes.

    const currentFilters = api.currentFilters()

    const previousFilters = usePrevious(currentFilters)

    useEffect(() => {

        if (previousFilters && !utils.deepequals(previousFilters, currentFilters))

            state.reset()
    })

    //////////////////////////////////////////////////////////////////////////


    /////////////////////////////// reports filter and selection changes to listeners.

    const tabledata = useContext(TableDataContext)

    // // if data has changed due to internal text filter, inform clients.
    useEffect(() => {

        if (!tabledata || !name)
            return

        tabledata.set(d => {

            const slot: TableData = {

                data: filtereddata,
                selected: selection?.selected ?? [],
                textfilter: filter

            }

            props.debug && console.log("updating table data listeners", slot)

            d[name] = slot

        })

        filterProps?.onFilteredDataChange?.(filtereddata)

        // eslint-disable-next-line 
    }, [filtereddata, props.selection?.selected, filter])

    /////////////////////////////////////////////////////////////////////////////////////////


    return {

        filter,
        filtereddata,
        filterProps: { 
            ...filterProps, 
            filter, 
            onChange: filterProps?.filter ? filterProps?.onChange : state.set(contextKey)
        
        }
    }

}