import { utils } from 'apprise-frontend-core/utils/common';
import { saveAs } from "file-saver";

type Cell = string | number | Date

type RenderFunc<T, S extends Cell = Cell> = (row: T) => S | undefined
type NotFunction<T> = T extends Function ? never : T | undefined;

type Column<T extends Row = Row, S extends Cell = Cell> = {
    width: number
    title: string | undefined
    type: 'text' | 'number' | 'date'
    render: NotFunction<S> | RenderFunc<T, S>
}

type Row = Record<string, any>

type Sheet<T extends Row = Row> = {
    name: string
    columns: Column<T>[]
    rows: T[]
}

export type WriterProps = {
    dateFormat: string
    defaultWidth: number
}

const defaultProps: WriterProps = {
    dateFormat: 'dd/mm/yyyy',
    defaultWidth: 150
}



const sheetApi = <T extends Row = Row>(sheet: Sheet<T>, api: Writer, clientProps: WriterProps) => {

    const { defaultWidth } = clientProps

    var self = {

        column: <S extends Cell = Cell>(title: Column<T, S>['title']) => {

            const clause = (c: Column<T, S>): Clause<T, S> => ({

                type: (type: Column<T, S>['type']) => clause({ ...c, type })
                ,

                width: (width: Column<T, S>['width']) => clause({ ...c, width })

                ,

                render: (render: Column<T, S>['render']) => {
                    sheet.columns.push({ ...c, render })
                    return self
                }

                ,

                renderAs: (value: any) => {
                    return clause(c).render(() => value)
                }


            })

            return clause({ title, width: defaultWidth, type: 'text', render: JSON.stringify as RenderFunc<T, S> })
        }

        ,

        text: (title: Column<T>['title']) => self.column<string>(title).type('text')

        ,

        num: (title: Column<T>['title']) => self.column<number>(title).type('number')

        ,

        numOrError: (title: Column<T>['title']) => self.column<number | string>(title).type('number')

        ,

        date: (title: Column<T>['title']) => self.column<string | Date>(title).type('date')

        ,

        as: (name: string) => {

            sheet.name = name
            return api
        }

        ,
        data: (rows_: T[]) => {
            sheet.rows = sheet.rows.concat(rows_)
            return api
        }



    }

    return self
}


export type SheetWriter<T extends Row> = {

    column: <S extends Cell = Cell>(title: Column<T, S>['title']) => Clause<T, S>

    ,

    text: (_: Column<T>['title']) => Clause<T, string>

    ,

    num: (_: Column<T>['title']) => Clause<T, number>

    ,

    numOrError: (_: Column<T>['title']) => Clause<T, string | number>

    ,

    date: (_: Column<T>['title']) => Clause<T, string | Date>



    as: (name: string) => Writer


    data: (rows_: T[]) => Writer


}


export type Clause<T extends Row=Row, S extends Cell=Cell> = {

    type: (_: Column<T, S>['type']) => Clause<T, S>

    width: (_: Column<T, S>['width']) => Clause<T, S>

    render: (_: Column<T, S>['render']) => SheetWriter<T>

    renderAs: (_: any) => SheetWriter<T>

}

// exposed to clients for convenience
export type Writer = ReturnType<typeof useWorkbookWriter>

export const useWorkbookWriter = (clientProps: Partial<WriterProps> = {}) => {

    const mergedprops = utils().merge(defaultProps, clientProps)

    const { dateFormat } = mergedprops

    var sheets: Sheet<any>[] = []



    const self = {


        sheetOf: <T extends Row = Row>(rows: T[]): SheetWriter<T> => {

            const newSheet = { columns: [], rows, name: "data" } as Sheet<T>

            sheets.push(newSheet)

            return sheetApi<T>(newSheet, self, mergedprops) as SheetWriter<T>

        }

        ,

        write: async  () => import('xlsx').then( xlsx => {

            const s2ab = (s) => {
                var buf = new ArrayBuffer(s.length);
                var view = new Uint8Array(buf);
                for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
                return buf;
            }

            const generateWorsheet = (sheet: Sheet) =>  {
                               
                const header = sheet.columns.map(col => col.title ?? '')
                const mappedRows = sheet.rows.map(row => sheet.columns.map(col => typeof col.render === 'function' ? col.render(row) : col.render))

                const headerAndRows = [header, ...mappedRows]

                var ws = xlsx.utils.aoa_to_sheet(headerAndRows, { cellDates: true, dateNF: dateFormat })

                const range = xlsx.utils.decode_range(ws['!ref']!)

                const columnsRange = range?.e.c
                const rowsRange = range?.e.r

                //Second pass over cell matrix to assign right types to cells
                for (let j = 1; j <= rowsRange; j++) { //Starting from 1 to skip header row

                    for (let i = 0; i <= columnsRange; i++) {

                        const columnType = sheet.columns[i]?.type

                        if (!columnType)
                            continue

                        var cellAddress = { c: i, r: j };

                        const cellRef = xlsx.utils.encode_cell(cellAddress);

                        const cellVal = ws[cellRef]?.v

                        if (!cellVal)
                            continue

                        if (ws[cellRef] === undefined)
                            ws[cellRef] = {}

                        switch (columnType) {
                            case 'text':
                                ws[cellRef].t = 's'
                                break
                            case 'number':
                                ws[cellRef].t = typeof cellVal === 'number' ? 'n' : 's'
                                break
                            case 'date':
                                ws[cellRef].t = 'd'
                                break
                        }
                    }
                }

                ws['!cols'] = sheet.columns.map(c => ({ 'wpx': c.width }))

                return ws
            }

            const workbook = xlsx.utils.book_new()

            sheets.forEach(sheet => {

                const worksheet = generateWorsheet(sheet)

                xlsx.utils.book_append_sheet(workbook, worksheet, sheet.name)
            })


            const xlsxOut = xlsx.writeXLSX(workbook, { bookType: 'xlsx', type: 'binary' })

            sheets = []

            return s2ab(xlsxOut)


        })

        ,

        save: async (filename: string) => {
        
            const buffer = await self.write()

            saveAs(new Blob([buffer], { type: "application/octet-stream" }), filename)

        
        }


    }

    return self

}