import { Collapse, Modal } from "antd";
import { useT } from 'apprise-frontend-core/intl/language';
import { Button } from 'apprise-ui/button/button';
import { TextBox } from 'apprise-ui/textbox/textbox';
import * as React from 'react';
import { AiOutlineNotification } from 'react-icons/ai';
import { toast } from 'react-toastify';
import "./feedback.scss";

type FeedbackProps = {


    title: React.ReactNode
    body: React.ReactNode
    okText: React.ReactNode
    cancelText: React.ReactNode

    noCancel: boolean


    width: string | number


}

export type FailureProps = FeedbackProps & {

    type: 'error' | 'warning'
    message: string,
    details?: string,
}

export type AskConsentProps = FeedbackProps & {

    onOk: (close: () => any) => any
    onCancel: () => any


    noValediction: boolean
    okChallenge: boolean | string
}

export type NotifyOptions = {

    icon: React.ReactNode,
    duration: number | false

    route: string
    routeMessage: string

    onClick: () => void
}



const mergeProps = (one: Partial<FeedbackProps> | undefined, two: Partial<FeedbackProps> | undefined) => (

    {
        ...one,

        title: one?.title ?? two?.title,
        body: one?.body ?? two?.body,
        okText: one?.okText ?? two?.okText,
        cancelText: one?.cancelText ?? two?.cancelText
    }

)


export const useFeedback = () => {

    const t = useT()


    const privately = {


        askConsent: (props: Partial<AskConsentProps>) => {

            const { title, body, okText, cancelText, onOk, width, noCancel, noValediction, okChallenge, onCancel } = props

            const valediction = t("ui.feedback.consent_generic_body")

            const confirmEnabled = { disabled: false }
            const confirmDisabled = { disabled: true }

            const challenge = okChallenge ? typeof okChallenge === 'string' ? okChallenge : t("ui.feedback.default_type_challenge") : undefined

            const onChangeStatus = (value: string | undefined) => modal.update({ okButtonProps: value === challenge ? confirmEnabled : confirmDisabled })


            let content = body ?

                (noValediction ?

                    body
                    : <div>
                        <div>{body}</div>
                        <div style={{ marginTop: 3 }}>{valediction}</div>
                    </div>
                )

                : valediction

            content = challenge ?

                <React.Fragment>
                    {content}
                    <TextBox uncontrolled onChange={onChangeStatus}
                        placeholder={challenge.toUpperCase()}
                        msg={t("ui.feedback.ask_consent_type_challenge", { challenge })}
                        style={{ margin: "20px 10px", width: 400 }}

                    />
                </React.Fragment>

                :

                content


            const modal = Modal.warning({

                title: title ?? t("ui.feedback.consent_generic_title"),
                content,
                okText: okText ?? t("ui.feedback.consent_generic_ok"),
                cancelText: cancelText ?? t("ui.feedback.consent_generic_cancel"),

                okCancel: !noCancel,
                onCancel: close => Promise.resolve(close).then(onCancel),

                keyboard: false,

                okButtonProps: { loading: false, ...okChallenge ? confirmDisabled : confirmEnabled },

                onOk
                ,

                width: width || 600,

            })

            return modal
        }
    }

    const self = {


        showNotification: (message: string = t('ui.feedback.saved_changes'), options: Partial<NotifyOptions> = {}) => {

            const { icon = <AiOutlineNotification />, duration, onClick, route, routeMessage } = options

            const content = <div className="toaster-container">
                <div className="toaster-icon">{icon}</div>
                <div className="toaster-message">{message}</div>
                {route &&
                    <div className="toaster-button">
                        <Button type="primary" size='small' linkTo={route}>{routeMessage ?? ""}</Button>
                    </div>
                }
            </div>

            toast.info(content, {

                className: "apprise-notification",

                position: 'bottom-right',
                autoClose: duration,
                onClick


            })

        }

        ,

        // delayed execution to use in promise chain for more fluency
        notify: (message?: string, options: Partial<NotifyOptions> = {}) => () => self.showNotification(message, options)

        ,

        showAndThrow: (props: string | Partial<FailureProps>) => (error: any) => {

            self.showError(props)(error)

            throw error

        }

        ,

        showAndRethrow: (error: any, props: string | Partial<FailureProps>) => {

            self.showError(props)(error)

            throw error

        }

        ,


        showError: (props: string | Partial<FailureProps>) => (error: any) => {

            self.showFailure({

                message: error.message,

                details: error.details ?? error.stack?.toString(),

                ...(typeof props === "string" ? { title: props } : props)

            })

        }

        ,

        showFailure: (props: Partial<FailureProps>) => {

            const { type = 'error', message, details, title, body, okText, cancelText, width } = props;

            const modalprops = {

                // defaults
                title: (typeof title === 'string'? t(title) :title) ?? (type === 'error' ? t("ui.feedback.consent_failure_title") : t("ui.feedback.consent_warning_title")),
                content: (typeof body === 'string'? t(body) : body) ??

                    <Collapse bordered={false}>
                        <Collapse.Panel style={{ whiteSpace: 'pre-wrap' }} header={message} key="1">
                            {details && details.toString()}
                        </Collapse.Panel>
                    </Collapse>,

                okText: okText ?? t("ui.feedback.consent_failure_ok"),
                cancelText: cancelText ?? t("ui.feedback.consent_failure_cancel"),

                width,

            }

            return type === 'error' ? Modal.error(modalprops) : Modal.warning(modalprops)


        }

        ,

        askRemovalConsent: (singular: string, props: Partial<AskConsentProps> = {}) => {

            return self.ask(mergeProps({

                ...props,

                body: props.body ? <>
                    <div style={{ marginTop: 10 }}>
                        {t("ui.feedback.consent_removal_body", { singular })}
                    </div>
                    <div style={{marginTop:5}}>{props.body}</div>
                </>
                    :
                    t("ui.feedback.consent_removal_body", { singular })
            }
                , {

                    ...props,
                    title: t("ui.feedback.consent_removal_title", { singular }),
                    okText: t("ui.feedback.consent_removal_ok", { singular })

                }

            ))

        }

        ,

        askRemovalManyConsent: (plural: string, props: Partial<AskConsentProps> = {}) => {

            return self.ask(mergeProps({

                ...props,

                body: props.body ? <>
                    <div>{props.body}</div>
                    <div style={{ marginTop: 10 }}>
                        {t("ui.feedback.consent_removal_many_body", { plural })}</div>
                </>
                    :
                    t("ui.feedback.consent_removal_many_body", { plural })
            }
                , {

                    ...props,
                    title: t("ui.feedback.consent_removal_many_title", { plural }),
                    okText: t("ui.feedback.consent_removal_many_ok", { plural })

                }

            ))

        }

        ,

        askDiscardConsent: (props: Partial<AskConsentProps> = {}) => {

            return self.ask(mergeProps(props, {

                title: t("ui.feedback.consent_discard_title"),
                body: t("ui.feedback.consent_discard_body"),
                okText: t("ui.feedback.consent_discard_ok")

            }))

        }

        ,

        ask: (props: Partial<AskConsentProps>) => {

            const $ask = (prev: Partial<AskConsentProps>[], ...props: Partial<AskConsentProps>[]) =>

            ({

                if: (test: boolean) => test ? $ask([], ...props) : $ask([], ...prev),

                thenAsk: (...next: AskConsentProps[]) => $ask(props, ...props, ...next),

                thenRun: (task: Function) => {

                    // creates a parallel chain of requests where each requests closes then runs onOk then runs the rest of the chain.
                    const chain = props.reverse()
                        .reduce((acc, step) => () => privately.askConsent({
                            ...step,
                            //
                            onOk: close => Promise.resolve(close()).then(() => step.onOk?.(close)).then(() => acc())
                        })
                            , task)

                    chain()
                }

            })

            return $ask([], props)
        }



    }

    return self


}







