import { useConfig } from 'apprise-frontend-core/config/api';
import { fallbackStateOver } from 'apprise-frontend-core/state/api';
import { mocks } from 'apprise-frontend-core/utils/mock';
import MockAdapter from "axios-mock-adapter";
import { useContext } from 'react';
import { useConfigState } from '../config/state';
import { ClientContext, initialClientState } from './state';




export type Mockery = (mock: MockAdapter) => void

export type MockStore<T> = {

    all: () => T[],
    replaceAll: (ts: T[]) => T[],
    get: (id: any) => T
    oneWith: (id: any) => T | undefined
    add: (t: T) => T
    addOrUpdate: (t: T) => T
    addMany: (..._: T[]) => T[],
    updateMany: (..._: T[]) => T[]
    update: (t: T) => T
    append: (t: T) => T
    appendMany: (..._: T[]) => T[]
    set: (at: number, t: T,) => T
    delete: (id: any) => void
    deleteObject: (t: T) => void
    deleteManyObjects: (..._: T[]) => void
    deleteMatching: (match: (t: T) => boolean) => void
}


export type StoreProps<T, ID = any> = Partial<{


    initial: () => T[]
    id: (t: T) => ID
    callbacks: Partial<StoreCallbacks<T>>

}>


export type StoreCallbacks<T> = {


    beforeChange: (_: T) => T

    onChange: (_: T[]) => any
    onDelete: (_: T[]) => any

}


const defaultMockProperties = {
    delayResponse: 100,
    onNoMatch: 'passthrough' as any
}




export const useMockery = () => {

    const state = useContext(ClientContext) ?? fallbackStateOver(initialClientState)

    const config = useConfig()
    const configstate = useConfigState()

    const self = {

        // initialises a mockery, unless mode is production.
        initMockery: (mockery: Mockery) => {

            // wraps an adapter around the axios implementation.
            const adapter = new MockAdapter(state.get().impl, { ...defaultMockProperties, delayResponse: state.get().config.mocks.responseDelay })

            state.setQuietly(s => s.mocks.adapter = adapter)

            // if we have a remote config and it says we're in production, undo any mocks we may have created.
            if (configstate.remoteConfig() && config.mode === 'production') {
                console.log("retracting all mocks in production...")
                adapter.restore()
                return
            }

            // if we don't have a remote config and the static one says we're in production, 
            // store mockery and init later when and if we have a remote config.
            if (configstate.clientConfig() && (config.mode === 'production')) {
                // stores mockery because if we re-init the client, we need to re-install it.
                state.setQuietly(s => s.mockeries.push(mockery))
                return
            }

            // activate mockery.
            mockery(adapter)

        }

    }

    return self
}


export const storesKey = 'apprise-stores'

export const useMocks = (props: Partial<{ debug: boolean }> = {}) => {

    const state = useContext(ClientContext)

    const {debug} = props ?? {}

    const privately = {


        lookupMocks: (name: string) => state.get().mocks.stores[name]
        
        
        ,

        storeMocks: (name: string, store: any) => state.setQuietly(s => {
            s.mocks.stores[name] = store 
        
        })
     
    }

    const self = {


        // includes all utilities for convenience.
        ...mocks

        ,

        getOrCreateStore: <T, ID = string>(name: string, storeprops: StoreProps<T, ID> = {}): MockStore<T> => {

            const { initial= () => [], id = t => (t as any).id, callbacks } = storeprops

            const { onChange, onDelete, beforeChange = (t: any) => t } = callbacks ?? {}

            let data =  privately.lookupMocks(name) as T[] 
           
            const log = (text: string, s: any) => debug && console.log(`mock ${name} store: ${text}`, s)

            // the api.
            const store = {

                data
                
                ,

                all: () => data

                ,

                replaceAll: (ts: T[]) => {

                    data = ts.map(beforeChange) 
                    
                    privately.storeMocks(name, data)

                    onChange?.(ts)
                    
                    return ts;
                }

                ,

                get: (id: ID) => {

                    const t = store.oneWith(id);

                    if (!t)
                        throw Error(`'${id}' is unknown.`)

                    return t

                }
                ,

                oneWith: (clientId: ID) => {
                    log("looking up", id)
                    return data.find(t=>id(t)===clientId)
                }
                
                ,

                add: (t: T, quietly?: 'quietly') => {

                    data = [beforeChange(t),...data]

                    privately.storeMocks(name, data)

                    quietly || onChange?.([t])

                    log("added", t)

                    return t
                }
                
                ,

                addOrUpdate: (t: T) => store.oneWith(id(t)) ? store.update(t) : store.add(t)

                ,

                addMany: (...ts: T[]) => {

                    const added = ts.reverse().map(t => store.add(t, 'quietly'))

                    onChange?.(added)

                    return added
                }

                ,

                append: (t: T, quietly?: 'quietly') => {

                    data = [...data, beforeChange(t)]

                    privately.storeMocks(name, data)

                    quietly || onChange?.([t])

                    log("appended", t)

                    return t
                }
                ,

                appendMany: (...ts: T[]) => {

                    const appended = ts.map(t => store.append(t, 'quietly'))

                    onChange?.(appended)

                    return appended
                }

                ,

                update: (t: T, quietly?: 'quietly') => {

                    data = data.map( e =>  id(e) === id(t) ? beforeChange(t) : e) 

                    privately.storeMocks(name, data)

                    quietly || onChange?.([t])

                    log("updated", t)

                    return t
                }

                ,

                updateMany: (...ts: T[]) => {

                    const updated = ts.map(t => store.update(t, 'quietly'))

                    onChange?.(updated)

                    return updated
                }

                ,

                set: (i: number, t: T) => {

                    data = data.map((e,j) => j === i ? beforeChange(t) : e )

                    privately.storeMocks(name, data)

                    onChange?.([t])

                    return t
                }

                ,

                delete: (id: ID) => store.deleteObject(store.get(id))

                ,

                deleteObject: (t: T) => {

                    data = data.filter( e =>  id(e) !== id(t) )

                    privately.storeMocks(name, data)

                    onDelete?.([t])

                    log("removed", t)
                }

                ,

                deleteManyObjects: (...ts: T[]) => ts.forEach(t => store.delete(id(t)), 'quietly')

                ,

                deleteMatching: (matching: (t: T) => boolean) => store.deleteManyObjects(...store.all().filter(matching))
            }


            // persist the initial store if it didn't exist already.
            if (!data)
                store.replaceAll(initial())

            return store;
        }


    }

    return self;

}