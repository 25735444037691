import { useT } from 'apprise-frontend-core/intl/language'
import { useEventBus } from 'apprise-frontend-events/bus'
import { userLogoutTopic } from 'apprise-frontend-iam/user/constants'
import { User, useUserModel } from 'apprise-frontend-iam/user/model'
import { useCrud } from 'apprise-ui/utils/crudtask'
import { useAccountCalls } from './calls'
import { Account, ExtendedState } from './model'
import { Configurator } from 'apprise-ui/utils/asynctask'


export const useAccounts = () => {

    const t = useT()

    const singular = t("account.shortname").toLowerCase()
    const plural = t("account.shortname_plural").toLowerCase()

    const calls = useAccountCalls()
    const users = useUserModel()
    const bus = useEventBus()

    const crud = useCrud({ singular: "account.shortname" })

    const model = {

        unknownAccount: (username: string): Account => ({ username, state: 'unknown' } as Account)

        ,

        noAccount: (user: User): Account => ({ ...self.accountFor(user), state: 'none' })

        ,

        accountFor: (user: User): Account => ({ username: user.username, firstname: user.details.firstname, email: user.details.email, language: user.details.preferences.language ?? users.defaultLanguage(user) }) as Account

        ,

        // adds sync-state for active records only, as extra info.
        extendedStateOf: (user: User, account: Account): ExtendedState => account.state !== 'active' || self.isSynced(user, account) ? account.state : 'offsync'

    }

    const inner = {

       

        syncWith: (account: Account, user: User) => ({ ...account, ...model.accountFor(user) })

    }

    const customFetchAccount = (configure: Configurator) => crud.fetchOneWith(async (user: User) =>

        calls.fetchAccount(user.username)

    ).with(config => configure(config

        .log(user => `fetching account for ${user.username}`)
        .quietly()
        .catch((e, user) => {

            return e.response?.status === 404 ? model.noAccount(user) : model.unknownAccount(user.username) as Account

        }) as any

    )).done()

    const self = {

        ...model


        ,

        ...inner
        
        ,

        // sync only on active/pending accounts (excludes also suspended, fusionauth won't accept updates on locked items)
        isSynced: (user: User, account: Account) =>

            (account.state !== 'active' && account.state !== 'pending') ||

            (account.email?.toLowerCase().trim() === user.details.email?.toLowerCase().trim() &&
                account.firstname?.toLocaleLowerCase().trim() === user.details.firstname.toLowerCase().trim() &&

                // disable this until fusionauth sorts this mess out: https://github.com/FusionAuth/fusionauth-issues/issues/441
                true //account.language === (user.details.preferences.language ?? users.defaultLanguage(user)) 
            )

        ,

        fetchAccount: customFetchAccount(t=>t)

        ,

        customFetchAccount
      

        ,

        createAccount: crud.saveOneWith(async (account: Account) =>

            calls.createAccount({ ...account, state: undefined! })


        ).with(config => config

            .log(account => `creating account for ${account.username}`)
            .notify('account.completed_notif')

        ).done()

        ,

        createAccountQuietly: crud.saveOneWith(async (account: Account) =>

            calls.createAccount({ ...account, state: undefined! })


        ).with(config => config

            .log(account => `creating account for ${account.username}`)
            .quietly()

        ).done()

        ,

        syncAccount: crud.saveOneWith(async (account: Account, user: User) => {

            const updatedAccount = inner.syncWith(account, user)

            return await calls.updateAccount(updatedAccount)


        }).with(config => config

            .log(account => `syncing account for ${account.username}`)
            .quietly()

        ).done()

        ,

        verifyAccount: crud.saveOneWith(async (user: User) =>

            calls.verifyAccount(user.username)


        ).with(config => config.notify('account.completed_notif')).done()

        ,

        verifyAccountWithConsent: crud.saveOneWith(async (user: User, onConfirm: (_: Account) => any) => {

            const verified = await calls.verifyAccount(user.username)

            onConfirm?.(verified)


        })
            .with(config => config.notify('account.completed_notif'))
            .withConsent({

                title: t('account.verify_pwd_action', { singular }),
                body: t("account.verify_pwd_action_consent", { singular }),
                okText: t("account.verify_pwd_action_confirm", { singular }),


            })

        ,


        activateAccount: crud.saveOneWith(async (user: User) =>


            calls.activateAccount(user.username)

        ).with(config => config.notify('account.completed_notif')).done()
        ,

        activateAccountQuietly: crud.saveOneWith(async (user: User) =>

            calls.activateAccount(user.username)

        ).with(config => config

            .notify('account.completed_notif')
            .quietly()

        ).done()


        ,


        suspendAccount: crud.saveOneWith(async (user: User, onConfirm: (_: Account) => any) => {

            const suspended = await calls.deactivateAccount(user.username)

            bus.publish(userLogoutTopic, { username: user.username })

            onConfirm?.(suspended)


        })
            .with(config => config

                .log(user => `suspending account for ${user.username}`)
                .notify('account.completed_notif')

            )
            .withConsent({

                title: t('account.suspend_action', { singular }),
                body: t("account.suspend_action_consent", { singular }),
                okText: t("account.suspend_action_confirm", { singular }),


            })

        ,

        suspendAccountQuietly: crud.saveOneWith(async (user: User) => {

            const suspended = await calls.deactivateAccount(user.username)

            bus.publish(userLogoutTopic, { username: user.username })

            return suspended


        })
            .with(config => config

                .log(user => `suspending account for ${user.username}`)
                .quietly()

            )
            .done()

        ,

        suspendManyAccounts: crud.saveManyWith(async (users: User[], onConfirm: (_: string[]) => any) => {

            const usernames = users.map(u => u.username)

            const suspended = await calls.deactivateAccounts(usernames)

            suspended.forEach(username => bus.publish(userLogoutTopic, { username }))

            onConfirm?.(suspended)


        })
            .with(config => config

                .log(user => `suspending account for [${user.map(u => u.username).concat(',')}]`)
                .notify('account.completed_notif')

            )
            .withConsent({

                title: t('account.suspend_action', { plural }),
                body: t("account.suspend_action_consent", { plural }),
                okText: t("account.suspend_action_confirm", { plural }),


            })

        ,

        suspendManyAccountsQuietly: crud.saveManyWith(async (users: User[]) => {

            const accountIds = users.map(u => u.details.preferences.accountId).filter(u => u) as string[]

            console.log(`suspending accounts for users [${users.map(u => u.username).join(',')}]`)

            const suspended = await calls.deactivateAccounts(accountIds)

            suspended.forEach(username => bus.publish(userLogoutTopic, { username }))

            return suspended


        })
            .with(config => config

                .log(user => `suspending account for [${user.map(u => u.username).concat(',')}]`)
                .quietly()

            )
            .done()

        ,

        resetAccount: crud.saveOneWith(async (user: User, onConfirm: (_: Account) => any) => {

            const account = await calls.resetAccount(user.username)

            onConfirm?.(account)

            return account

        })
            .with(config => config

                .log(user => `resetting account for ${user.username}`)
                .notify('account.completed_notif')

            )
            .withConsent({

                title: t('account.change_pwd_action', { singular }),
                body: t("account.change_pwd_action_consent", { singular }),
                okText: t("account.change_pwd_action_confirm", { singular }),

            })


        ,

        removeAccountQuietly: crud.removeOneWith(async (username: string) =>

            calls.deleteAccount(username)

        ).with(config => config

            .log(username => `removing account for ${username}`)
            .quietly()

        ).done()

        ,

        // ask consent to remove and notify
        removeAccountWithConsent: crud.removeOneWith(async (username: string, onConfirm?: () => any) => {

            await calls.deleteAccount(username)

            onConfirm?.()

        }).with(config => config

            .log(username => `removing account for ${username}`)

        ).withConsent({

            title: t('account.remove_action', { singular }),
            body: t("account.remove_action_consent", { singular }),
            okText: t("account.remove_action_confirm", { singular }),

        })

    }

    return self
}


