import * as React from "react"
import * as Router from "react-router-dom"
import { Linked } from './model'
import { RoutableTargetContext } from "./routabletargets"
import "./styles.scss"


type Props = React.PropsWithChildren<Linked>

export const Link = ($: Partial<Props>) => {

    const {include, exclude} = React.useContext(RoutableTargetContext)

    const history = Router.useHistory()
    const location = Router.useLocation()


    const notarget = $.linkTarget && (
        
        (include !== 'all' && !include?.includes($.linkTarget)) 
        || 
        (exclude ==='all' || exclude?.includes($.linkTarget)))
    
    // avoids a wrapper altogether where it can.
    const noop = $.noLink || notarget || !$.linkTo 

    const linkTo = $.linkTo ?? 'missing/link'
    const linkOn = $.linkOn ? () => $.linkOn?.().then(()=>history.push( typeof linkTo === 'function' ? linkTo(location) : linkTo)) : undefined
    
    const onClick = (e: React.MouseEvent) => {

        if (linkOn) {
            e.preventDefault();
            linkOn()
        }

    } 
    return noop ?

        <React.Fragment>{$.children}</React.Fragment>
        :

        <Router.Link onClick={onClick} className="apprise-link" to={linkTo}>{$.children}</Router.Link>

}
