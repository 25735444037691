import { Select } from 'antd'
import { BaseOptionType, LabeledValue } from 'antd/lib/select'
import { Optional, utils } from 'apprise-frontend-core/utils/common'
import { antsizeOf, classname, Sized, Wide } from 'apprise-ui/component/model'
import { useChangeHelper } from 'apprise-ui/field/changehelper'
import { Field, useFieldProps } from 'apprise-ui/field/field'
import { ChangeTracked, Fielded } from 'apprise-ui/field/model'
import { useReadonlyHelper } from 'apprise-ui/field/readonlyhelper'
import { useResetHelper } from 'apprise-ui/field/resethelper'
import { DownIcon, RemoveItemIcon } from 'apprise-ui/utils/icons'
import noop from 'lodash/noop'
import * as React from 'react'
import "./styles.scss"


//  selects one or more values presented as options.
//  values other than strings or numbers must be identified by a keyOf function.
//  values can serve as options unless a render function is a provided.


export type SelectProps<T> = Sized & Wide & Partial<{

    keyOf: (_: T) => string                  //  extracts identifiers from values.
    render: (_: T) => React.ReactNode        //  renders values.
    textOf: (_: T) => Optional<string>                 //  extracts searchable text from values.
    disabledOf: (_:T) => boolean

    noClear: boolean
    placeholderAsOption: boolean

    flexWidth?: boolean

    noSearch: boolean

    options: T[]

    noItemRemove: boolean

    showSelected: boolean
}>


export type SingleSelectProps<T> = Fielded<T> & ChangeTracked<T> & Omit<SelectProps<T>,'noItemRemove'> & Partial<{

    children?: T
}>


// single-value selection
export const SelectBox = <T extends any>(props: Partial<SingleSelectProps<T>>) => {

    return <PolySelectBox {...props} />
}

//  multi-value selection.
//  - typed API
//  - preserves undefined as initial value.

export type MultiSelectProps<T extends any> = Fielded<T[]> & ChangeTracked<T[]> & SelectProps<T> & {

    children?: T[]
}

export const MultiSelectBox = <T extends any>(props: Partial<MultiSelectProps<T>>) => {

    const initialValue = React.useRef(props.children)

    // restore initial value of undefined when selection is emptied.
    const onChange = (ts: T[]) => props.onChange?.(ts?.length > 0 || initialValue.current ? ts : undefined)

    return <PolySelectBox multi {...props} onChange={onChange} />

}

// we leave defaultValue/onChange untyped and handle the switch internally.

export type PolySelectProps<T extends any> = Fielded<any> & ChangeTracked<any> & SelectProps<T> & {

    children?: T | T[]
    multi?: boolean
}

const defaultOptionRender = (t: any) => typeof t === 'object' ? JSON.stringify(t) : `${t}`

type Option<T> = { value: T, label: React.ReactNode, key: string, disabled: boolean }



// *************************************************************************************************
//  NOTE: a known problem is that antd generates a warning if undefined is reset over an existing value.
//  it may happen when rerendering a form.
//  the causes are not yet entirely clear, but it appears an unintended side-effect.
//  no known solutions as yet, but the warning is harmless, occurs only at dev time, and does not repeat.
// ************************************************************************************


export const PolySelectBox = <T extends any>(clientprops: Partial<PolySelectProps<T>>) => {

    const props = useFieldProps({ ...clientprops, width: clientprops.width ?? '100%' })

    const {
        children,
        multi,
        innerClassName,
        innerStyle,
        onChange = console.log,
        keyOf = identity,
        render = defaultOptionRender,
        textOf = defaultOptionRender,
        disabledOf,
        noClear,
        disabled,
        placeholder,
        placeholderAsOption,
        size = 'normal',
        options = [],
        flexWidth,
        defaultValue,
        noItemRemove,
        noSearch,
        showSelected

    } = props

    // key => opts
    const optionmap: Record<string, Option<T>> = options.reduce((acc, t) => ({ ...acc, [keyOf(t)]: { value: t, key: keyOf(t), label: render(t), disabled: disabledOf ? disabledOf(t) : (render(t) as any)?.props?.disabled } }), {})

    const optionWith = (key: string | undefined) => key ? optionmap[key] : undefined!                 // key => opt
    const optionOf = (t: T): Option<T> => t ? optionWith(keyOf(t)) : undefined!                       // t => opt


    const selected = map(optionOf)(children)
    const defaultSelected = map(optionOf)(defaultValue)
    const isSelected = (o: Option<T>) => selected && (Array.isArray(selected) ? selected : [selected]).includes(o)


    const valueOf = (o: BaseOptionType | undefined) => optionWith(o?.key)?.value                                    // ant => T
    const antOptionOf = (o: Option<T>): BaseOptionType => ({ key: o.key, label: o.label, value: o.key , disabled:o.disabled})          // opt => ant

    const antoptions = Object.values(optionmap).filter(o => showSelected || !isSelected(o)).map(antOptionOf)
    const antselected = map(antOptionOf)(selected)

    const antfilter = (filter: string, o?: BaseOptionType) => valueOf(o) && (textOf(valueOf(o)) || '').toLowerCase().includes(filter.toLowerCase())

    // controls the applicability of defaults as fallback for missing value.
    const defaultingMode = React.useRef(true)

    const antsdefault = map(antOptionOf)(defaultSelected)
    const antselectedOrDefault = (utils().arrayOf(antselected)?.length ?? 0) > 0 || !defaultingMode.current ? antselected : antsdefault

    // forces rendering in corner case of deselecting a default when model is already undefined.
    const [, forceRender] = React.useState(false)

    const select = (options: CaseOf<LabeledValue>, resetDefault?: boolean) => {

        // unless we're explicitly resetting the default, 
        // exit defaulting mode because the user has just made a non-default choice.
        defaultingMode.current = !!resetDefault

        // tricky corner case: forces a render on `undefined` to deselect the default. 
        //  react would skip the transition `undefined` -> `undefined` otherwise.
        if (!options && !antselected && !!antselectedOrDefault)
            forceRender(v => !v)

        onChange(map(valueOf)(options))

    }

    // we use this to compare with respect to the past when no pastValue is provided.
    // in multiboxes, we must treat empty arrays like undefined. 
    const initialValue = React.useRef(Array.isArray(children) && children.length === 0 ? undefined : children);

    const currentValue = antselectedOrDefault

    const pastValueOptions = map(optionOf)(props.pastValue ?? initialValue.current)
    const pastValue = map(antOptionOf)(pastValueOptions)

    const { pastMode } = useChangeHelper(props, { currentValue, pastValue })

    const value = pastMode ? pastValue : currentValue

    useReadonlyHelper(props)

    useResetHelper(props, { onReset: () => select(undefined, true) })

    const classes = classname(props.className, multi ? 'select-multi' : 'select-single', placeholderAsOption && 'placeholder-option')

    props.debug && console.log({ initial: initialValue.current, past: pastValue, options, children, selected, defaultValue, readonly: props.readonly })

    // antd complains when we set the value to undefined (it sees it as an option it hasn't seen before, somehow).
    // so we do some bookkeeping and remount the control when we detect a 'reset' to undefined.
    const previous = React.useRef<typeof value>(value)
    const resetId = React.useRef(Date.now())

    React.useEffect(() => { previous.current = value })

    if (previous !== undefined && value === undefined)
        resetId.current = Date.now()

    // end fix /////////////////////////////////////////////////////////////////////////////////////////////////////////


    return <Field name='selectbox' {...props} className={classes} >

        <Select key={resetId.current} labelInValue mode={multi ? 'multiple' : undefined} disabled={disabled} placeholder={placeholder}

            options={antoptions}
            value={value}
            defaultValue={antsdefault}
            onChange={props.readonly ? noop : select}



            filterOption={antfilter}

            className={innerClassName}
            style={innerStyle}
            size={antsizeOf(size)}

            dropdownMatchSelectWidth={flexWidth}

            popupClassName={classname('selectbox-dropdown', `${props.className ?? 'selectbox'}-dropdown`)}

            allowClear={!noClear}
            showSearch={noSearch === undefined ? antoptions.length > 0 : !noSearch}      // force type-ahead also in single mode.
            showArrow                               // force down caret also in multi mode.


            // a pill can't be removed in readonly mode, but also
            //if there are no children => we're showing at most the default that can't be removed.
            removeIcon={noItemRemove || props.readonly || !children ? null : <RemoveItemIcon />}
            suffixIcon={<DownIcon />}
            clearIcon={props.readonly ? null : <RemoveItemIcon />}
        />

    </Field>

}

//  helpers

// cast as function, used for default.
const identity = <T extends any>(t: any) => t as T

type CaseOf<T> = T | T[] | undefined
// generates a map that handles T|T[]|undefined.
// undefined -> undefined
// array -> mapped array (no undefined elements)
// single -> mapped 
const map = <U, S extends any>(fun: (_: U) => S) => (values: CaseOf<U>) => values ? Array.isArray(values) ? values.map(fun).filter(u => !!u) : fun(values) : undefined
