
import produce from "immer"
import { useEffect, useMemo, useRef, useState } from "react"
import { useT } from '../intl/language'
import { DialogProps, useComponentBridge } from "./bridge"
import { utils } from "./common"
import { usePrevious } from './hooks'



/** 
 * Takes a model and returns a 'kit' to control a set of input fields defined over model properties.
 * 
 * The kit includes change functions to use in field change listeners.
 * The functions operate on a clone of the input model, which is also part of the kit.
 * This clone should be used to control field values.
 * 
 * This means the model is edited in a session, and clients are responsible for committing the changes in the clone at the end
 * of session, if, when, where, and how they see fit (e.g. saving them in a backend on demand).
 * 
 * The clone is created on mount, but clients may provide a reset function that returns true when the clone should resync with the input model 
 * (e.g. because the model has changed outside the form). Reset can also be invoked explicitly, and there is an option to "rebase" the form, i.e. reset it to
 * a model other than the input model. Rebasing can be tracked and, viceversa, the input model can be reset to match new base. 
 *  
 * On reset, a counter is incremented and returned in the kit  as a "reset signal" that can be passed to children to synchronize auxiliary state in effects.
 * 
 * Clients control when changes should trigger a render. In all cases, they may be observed immediately, in the same render cycle. 
 * So render remains asychronous but change is synchronous. 
 * This means change functions can be invoked multiple times, whether sequentially (e.g. in promise chains) or in parallel (e.g. in separate useEffect()),
 * and their effects will compose rather than cancel each other out. 
 * 
 * At each change, the clone is deeply compared with the input model to track changes. Purely technical differences betwween thw two are ignored 
 * to avoid positives in change detection which would appear false to users. This concerns 'empty' forms that can be considered equivalent from the domain
 * perspective, like null, undefined, empty strings, etc.
 * 
*/


export type FormOptions<T> = {

  resetOn?: (state: FormState<T>) => boolean
  trackedOf?: (_:T) => any

}


export const useForm = <T>(start: T, options:FormOptions<T> = {}): FormState<T> => {

  const { normalise } = useFormUtils()

  const { resetOn } = options

  // deep clone to preserve originals, deep compare track changes.
  const { deepequals, deepclone } = utils()

  const t = useT()
  const { renderDialog } = useComponentBridge()

  type FormData = { edited: T, initial: T, base: T }

  // eslint-disable-next-line
  const startClone = useMemo(() => deepclone(start), [])

  const state = useRef<FormData>({ edited: startClone, initial: start, base: start })

  const previous = usePrevious(state.current.edited)

  const unmounted = useRef(false)

  // runs on unmouunt and let us marke the event
  useEffect(() => () => { unmounted.current = true }, [])

  // incremented whenever the model is reset, can be used in effects to synchronize auxiliary state.
  const [resetSignal, resetSignalSet] = useState(0)

  // just a counter to force an asynchronous render when the state synchronously changes.
  const [, render] = useState(0)

  const setQuietly = (t: FormData) => {

    // noop
    if (unmounted.current)
      return

    state.current = t
  }
  const setAndRender = (t: FormData) => {

    // noop
    if (unmounted.current)
      return

    setQuietly(t)
    render(c => ++c)
  }





  // eslint-disable-next-line
  const dirty = useMemo(() =>

    !deepequals(state.current.edited, state.current.initial)

    // eslint-disable-next-line
    , [state.current.edited, state.current.initial])

  const rebased = useMemo(() =>

    !deepequals(state.current.initial, state.current.base),

    // eslint-disable-next-line   
    [state.current.initial, state.current.base])


  const set = (mode: 'quiet' | 'render') => ({

    with: <S>(mutator: (t: T, v: S, ...[args]) => any, props) => (v: S | undefined, ...[args]) => set(mode).using(t => mutator(t as any, v!, args), props),

    using: (mutator: (t: T) => any, props?: SetFunctionProps) => {

      // produces copy with applied changes as most syntactically convenient 
      const updated = produce(state.current.edited, t => void mutator(t as any))

      set(mode).to(updated, props)

    },

    it: (t: T, props?: SetFunctionProps) => () => set(mode).to(t, props),

    to: (t: T, props: SetFunctionProps = defaultSetFunctionProps) => {

      const { normalise: normaliseprop } = props

      // avoids false positive in dirty tracking: the empty values are normalised to match the original's.
      const normalised = normaliseprop ? normalise(t, state.current.edited, state.current.initial) : t

      const newstate = { edited: normalised, initial: state.current.initial, base: state.current.base }

      mode === 'quiet' ? setQuietly(newstate) : setAndRender(newstate)
    }

  })

  // base reset function, as most convenient: captures target and returns callback.
  const doreset = (t: T) => () => {

    setAndRender({ edited: deepclone(t), initial: t, base: state.current.base })
    resetSignalSet(i => ++i)

  }

  // moves original forward to 
  const resetOriginal = () => setAndRender({ ...state.current, base: state.current.initial })


  const withConsent = (action: () => void) => {

    const self = {

      confirm: () => self.confirmWith(),
      confirmWith: (props: Partial<DialogProps> = {}) => renderDialog({
        title: t("form.unsaved_title"),
        msg: t("form.unsaved_msg"),
        okLabel: t("form.unsaved_ok_msg"),
        canceLabel: t("form.unsaved_cancel_msg"),
        onOk: action,
        ...props
      }),
      quietly: action

    }

    return self
  }

  // reset options: with/without consent, to initial or to arbitrary value.
  const reset = {

    toInitial: withConsent(doreset(state.current.initial)),
    toBase: withConsent(doreset(state.current.base)),
    base: withConsent(resetOriginal),
    to: (t: T) => withConsent(doreset(t))

  }

  const kit = { edited: state.current.edited, initial: state.current.initial, base: state.current.base, previous, dirty, rebased, set: set('render'), setQuietly: set('quiet'), reset, resetSignal }

  // resets the form on a given condition.
  // eslint-disable-next-line
  useEffect(() => {

    if (resetOn?.(kit)) {

      //console.log("resetting form")
      reset.to(start).quietly()

    }

  })

  return kit

}


type ConsentMode = {

  confirm: () => void
  confirmWith: (props: Partial<DialogProps>) => void
  quietly: () => void

}


/**
 *  A kit to control a form over a domain model.
 */
export type FormState<M = any> = {

  /** the model under editing. Use to control form fields. */
  edited: M

  /** the model prior to any edits. Use for comparative analysis if and where required. */
  initial: M

  /** the model prior to any edits. Use for comparative analysis if and where required. */
  base: M

  /** the model in the previous render. Use for comparative analysis if and where required. */
  previous: M | undefined

  /** indicates the model has been changed. */
  dirty: boolean

  /** indicates the model has be been reset to a new base state.  */
  rebased: boolean

  // a set of function to update the model synchronously whilst triggering an asynchronous render. 
  set: SetFunctions<M>

  // a set of function to update the model synchronously without triggering an asynchronous render. 
  setQuietly: SetFunctions<M>

  // replaces the state and with some earlier, optionally after obtaining the user's consent.
  reset: {

    // resets to the initial state.
    toInitial: ConsentMode

    // resets to a given state. this "rebases" the form: new state becomes the initial model for future resets. 
    to: (t: M) => ConsentMode

    // resets to the base state. this differs from the initial if the form has been rebased.
    toBase: ConsentMode

    // resets the base state to match the latst rebase.
    base: ConsentMode


  }

  // changed on reset, can be used to synchronise auxiliary state in effects.
  resetSignal: number



}

export type SetFunctions<M = any> = {


  // updates the model to a given version.
  to: (t: M, props?: SetFunctionProps) => void

  // returns a function that updates the model to a given version.
  it: (t: M, props?: SetFunctionProps) => () => void

  // updates the model with a function that changes it in place.
  using: (mutator: (t: M) => any, props?: SetFunctionProps) => void

  // returns a field change listerer that updates the model with a function that changes it in place.
  // at this time we can infer a type only for the first parameter, the others if any must be typed at use site.
  // also we decide to forget that the parameter can be undefined, as the dominant case is a simple assignment.
  with: <S> (mutator: (t: M, v: S, ...[rest]) => any, props?: SetFunctionProps) => (v: S | undefined, ...[values]: any[]) => void



}

export type SetFunctionProps = {

  normalise: boolean
}

const defaultSetFunctionProps = {

  normalise: true
}


export const useFormUtils = () => {


  const self = {

    isNotEmpty: (v: any, recurse: boolean = false) =>

      v !== undefined && v !== null && (

        typeof v === 'object' ?
          // empty if all values are, but inner empty objects don't count:
          // {} => empty, {a:undefined} => empty, {a:{}} => not empty
          Object.keys(v).length === 0 ? recurse : Object.values(v).some(v => self.isNotEmpty(v, true))

          : true
      )

    ,

    isEmpty: (v: any, recurse: boolean = false) => !self.isNotEmpty(v, recurse)

    ,

    canonicalEmptyOf: (v: any) => Array.isArray(v) ? [] : typeof v === 'object' && v ? {} : undefined

    ,

    normalise: <T>(current: T, previous: T, initial: T) => {

      // values that haven't changed  don't need to be normalised against their initial counterpart.
      if (current === previous)
        return current

      if (self.isEmpty(current))
        if (self.isEmpty(initial))    // both empty, return old.
          return initial
        else
          return self.canonicalEmptyOf(current)  // only new is empty, return a canonical form.
      else
        if (self.isEmpty(initial))
          return current                      // only old is empty, return new.

      // both non-empty, recurse if matching.

      if (Array.isArray(current) && Array.isArray(initial))
        return current.map((ae, i) => self.normalise(ae, previous?.[i], initial?.[i]))

      else if (typeof current === 'object' && typeof initial === 'object') {

        const normalised = {}

        return Object.keys(current ?? {}).reduce((acc, k) => {

          if (k in (initial ?? {}))   // never 'cancel' a key.

            acc[k] = self.normalise(current?.[k], previous?.[k], initial?.[k])
          
          else

            if (!self.isEmpty(current?.[k]))

              acc[k] = current?.[k]    // prunes new empty data

          return acc

        }, normalised)
      }

      return current        // return new.

    }

  }
  return self

}