import { useCall } from 'apprise-frontend-core/client/call'
import { RemoteClientConfiguration } from 'apprise-frontend-core/client/config'
import { useMockery } from 'apprise-frontend-core/client/mocks'
import { usePreload } from 'apprise-frontend-core/client/preload'
import { BaseConfig } from 'apprise-frontend-core/config/model'
import { useComponentBridge } from 'apprise-frontend-core/utils/bridge'
import { useBusyState } from 'apprise-frontend-core/utils/busyguard'
import { useRenderGuard } from 'apprise-frontend-core/utils/renderguard'
import { useToolBridge } from 'apprise-frontend-core/utils/toolbridge'
import { PropsWithChildren } from 'react'
import { useT } from '../intl/language'
import { useConfigState } from './state'


export type ConfigProducer = () => Promise<BaseConfig>

export type ConfigProps = PropsWithChildren<Partial<{

    at: string
    mock: Partial<RemoteClientConfiguration> & Record<string, any>

}>>

export const defaultConfigPath = '/config.json'

export const ConfigLoader = (props: ConfigProps) => {

    const { children, at: path = defaultConfigPath, mock: mockConfig } = props

    const t = useT()
    const config = useConfigState()
    const mockery = useMockery()
    const client = useCall()

    const busy = useBusyState()

    const preload = usePreload()

    const { renderError } = useComponentBridge()

    const { absoluteOf } = useToolBridge()


    const fetch = () => {

        console.log("loading configuration @ ", path)

        return busy.toggle("config", t("config.loading"))

            .then(() => mockConfig ? mockery.initMockery(mock => mock.onGet(path).reply(() => [200, mockConfig])) : true)

            .then(() => mockConfig ? client.atPath(absoluteOf(path)).get<BaseConfig>() : (preload.get<BaseConfig>(defaultConfigPath) ?? client.atPath(absoluteOf(path)).get<BaseConfig>()))

            .then(fetched => config.addRemoteConfig(fetched))

            .catch(renderError)

            .finally(() => busy.toggle("config"))


    }

    const { content } = useRenderGuard({

        render: children,

        orRun: fetch

    })

    return content
}

export const useConfigPreloader = (path=defaultConfigPath) => {

    const { absoluteOf } = useToolBridge()

    
    return [

        { name: defaultConfigPath, task: call => call.atPath(absoluteOf(path)).get}
    ]

}