import { useCall } from 'apprise-frontend-core/client/call'
import { tagType } from '../constants'
import { Tag } from './model'

export const tagapi = "/tag"

export const useTagCalls = () => {

    const call = useCall()


    const self = {

        fetchAllTags: () => call.at(tagapi,tagType).get<Tag[]>()
        
        ,

        addTag: (tag:Tag)  =>  call.at(tagapi,tagType).post<Tag>(tag)
        
        ,  

        updateTag: (tag:Tag)  =>  call.at(`${tagapi}/${tag.id}`,tagType).put<Tag>(tag)

        ,

        deleteTag: (tag:Tag)  =>  call.at(`${tagapi}/${tag.id}`,tagType).delete()

    }

    return self;

}

