import { useEffect } from 'react'
import { Language, useLanguage } from '../../apprise-frontend-core/intl/language'


export const LanguageObserver = (props: Partial<{

    language: Language

}>) => {

    const lang = useLanguage()
    
    const { language } = props

     useEffect(() => {

        if (language && language !== lang.current())
            lang.change(language)

        // eslint-disable-next-line
    }, [language])

    return null
}