

export const mocks = {


    loremIpsumShort: `Lorem ipsum dolor sit amet`

    ,

    loremIpsum: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit essecillum 
    dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`


    ,


    randomNumber: (range: number = 100) => Math.floor(Math.random() * Math.floor(range))

    ,

    randomFloat: (range: number = 100) =>  Math.round((Math.random() * Math.floor(range))  * 100) / 100

    ,

    randomNumberBetween: (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min

    ,

    randomFloatBetween: (min: number, max: number) => Math.round((Math.random() * (max - min + 1) + min) * 100) / 100

    ,


    randomBoolean: (likelihood: number = .5) => Math.random() <= likelihood


    ,

    randomText: (thing: string, range: number = 100) => thing + mocks.randomNumber(range + 1)

    ,

    randomMultilang: (base: string = "value") => {

        const val = mocks.randomText(base);

        return { en: val, fr: `${val} en français`, es: `${val} in español`, ar: `${val} bialerby`, ru: `${val} на русском`, zh: `${val} 用中文（表达` }

    }


    ,

    randomIn: <T>(vals: T[], orUndefined?: boolean): T => orUndefined ? mocks.randomBoolean() ? vals[mocks.randomNumber(vals.length)] : undefined! : vals[mocks.randomNumber(vals.length)]

    ,

    randomSlice: <T>(vals: T[], min: number, max: number) => {

        let slice = [] as T[]

        if (vals === undefined || vals.length === 0)
            return vals

        Array.from({ length: mocks.randomNumberBetween(Math.min(vals.length, min), Math.min(vals.length, max)) }).map(_ => {
            let random: T
            do random = mocks.randomIn(vals)!; while (slice.indexOf(random) > -1)
            slice = [random, ...slice]
            return null
        })

        return slice

    }

    ,

    randomDateBetween: (start: Date, end: Date) => new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()))


    ,

    randomDateSince: (start: Date) => mocks.randomDateBetween(start, new Date())



    ,

    randomColor: () => {
        const letters = "0123456789ABCDEF"
        const res = Array(6).fill(undefined).map(() => letters[Math.floor(Math.random() * 16)]).reduce((acc, n) => acc.concat(n), "")
        return "#".concat(res)
    }

    ,

    randomArray: (min: number, max: number) => Array.from({ length: mocks.randomNumberBetween(min, max)})


}
