import { RefObject, createContext, useContext, useMemo } from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';

export type QuillConfigurator = (ref:RefObject<ReactQuill>)=>ReactQuillProps
export const ReactQuillContext = createContext<QuillConfigurator>(()=>null as any)

export const useQuillContext = (ref:RefObject<ReactQuill>) => {
    const quillContext = useContext(ReactQuillContext)

    const quillKeys = Object.keys(quillContext?.(ref)??{}).join()
    //eslint-disable-next-line
    return useMemo(()=>quillContext?.(ref) || {},[quillKeys]) // it is important to ensure that props coming from context does not update on every render
}