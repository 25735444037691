import { Action } from "./action"


export const any = "*"
export const noActionType = "app"

export const standardActions:Record<string, Action> = {

    do_anything: {
        type: any,
        name: "authz.do_anything_name",
        description: "authz.do_anything_description",
        virtual: false,
        labels: [any],
        resource: any,
        actionType: 'admin'
    }

    ,

    do_nothing: {
        type: any,
        name: "authz.do_nothing_name",
        description: "authz.do_nothing_description",
        virtual: true,
        labels: []
    }

}




