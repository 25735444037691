import { Drawer as AntdDrawer } from "antd"
import { State } from 'apprise-frontend-core/state/api'
import { StateProvider } from 'apprise-frontend-core/state/provider'
import { BusyGuard, LazyBusyGuard } from 'apprise-frontend-core/utils/busyguard'
import { useStable } from 'apprise-frontend-core/utils/function'
import { Component } from 'apprise-ui/component/component'
import { StyledContainer, classname } from 'apprise-ui/component/model'
import { Label } from 'apprise-ui/label/label'
import { Readonly } from 'apprise-ui/readonly/model'
import { ReadOnly, useReadonlyProps } from 'apprise-ui/readonly/readonly'
import { AskConsentProps, useFeedback } from 'apprise-ui/utils/feedback'
import { paramsIn, updateQuery } from 'apprise-ui/utils/routing'
import * as React from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import Skeleton from 'react-loading-skeleton'
import { useHistory, useLocation } from 'react-router-dom'
import { UnknownIcon } from '../utils/icons'
import "./styles.scss"


// state-driven router

export type DrawerSystem = DrawerControls & {

    Drawer: typeof Drawer,
    DrawerBox: React.FC,
}

export type DrawerControls = {

    open: boolean,
    openSet: (state: boolean) => void
    toggle: () => void

}

const DrawerControlsContext = React.createContext<State<DrawerControls>>(undefined!)

export const useDrawerControls = () => React.useContext(DrawerControlsContext).get()

export const useDrawer = (): DrawerSystem => {

    // holds the state of the drawer so that it can be referred from within the proxy.
    const [open, openSet] = React.useState(false)

    const set = React.useCallback((state: boolean) => openSet(state), [])

    const toggle = React.useCallback(() => openSet(s => !s), [])

    const controls = { open, openSet: set, toggle }

    const Proxy = useStable((props: DrawerProps) => {

        const wrappedOnClose = () => {
            props.onClose?.(); 
            openSet(false)
        }
        
        return <StateProvider initialState={controls} context={DrawerControlsContext}>
            <Drawer open={open} {...props} onClose={wrappedOnClose} >{props.children}</Drawer>
        </StateProvider>

    })


    return { Drawer: Proxy, DrawerBox, ...controls }
}



//  route-driven drawer.

export type RoutableDrawerControls = DrawerControls & {

    match: string
    openAt: (match: string) => void
    routeAt: (match?: string, location?: LocationType) => string
    closedRoute: string

}

export type RoutableDrawerSystem = DrawerSystem & RoutableDrawerControls

const RoutableDrawerControlsContext = React.createContext<State<RoutableDrawerControls>>(undefined!)

export const useRoutableDrawerControls = () => React.useContext(RoutableDrawerControlsContext).get()

type LocationType = { pathname: string, search: string }

export type RoutableDrawerProps = Partial<{

    dependentParams: string[]
}>

export const useRoutableDrawer = (param: string, props: RoutableDrawerProps = {}): RoutableDrawerSystem => {

    const history = useHistory()

    const location = useLocation()

    const { search } = location

    const { dependentParams = [] } = props

    const match = paramsIn(search)[param] as string

    // adds the drawer param to the route setting it to 'true' or a specific value
    const route = (state: boolean = true, match: string = 'true', clientLocation: LocationType = location) => {

        const { pathname, search } = clientLocation

        const querystring = updateQuery(search).with(ps => {

            if (state)
                ps[param] = match
            else
                [param, ...dependentParams].forEach(p => ps[p] = undefined)

        })

        return `${pathname}${querystring}`

    }
    

    const routeAt = (match?: string, search?: LocationType) => route(true, match, search)


    const closedRoute = route(false)
    

    // open with a specific param value
    const openAt = (match: string, search?: LocationType) => openSet(true, match, search)

    // open/closes with an optional value
    const openSet = (state: boolean, match?: string, search?: LocationType) => history.push(route(state, match, search))

    const toggle = () => openSet(!open)

    const open = !!match

    const controls = { open, openSet, match, openAt, routeAt, toggle, closedRoute }


    const Proxy = useStable((props: DrawerProps) => {

        const wrappedOnClose = () => {

            props.onClose?.()
            openSet(false)
        }

        return <StateProvider initialState={controls} context={RoutableDrawerControlsContext}>
            <Drawer open={open} {...props} onClose={wrappedOnClose} >{props.children}</Drawer>
        </StateProvider>
    })

    return { Drawer: Proxy, DrawerBox, ...controls }

}

//  --- layout utility for local drawers.
const DrawerBox = (props: React.PropsWithChildren<{}>) => {

    return <div style={{ position: 'relative', height: '100%', zIndex: 0 }}>
        {props.children}
    </div>
}




//  --- component


export type DrawerProps = React.PropsWithChildren<StyledContainer & Readonly & Partial<{

    open: boolean
    onClose: () => void

    icon: JSX.Element
    title: React.ReactNode
    titleSuffix? : React.ReactNode

    width: string | number,
    fixedHeight: boolean,

    scope: 'page' | 'box'

    noMask: boolean
    delayedRender: boolean
    closeButton: boolean


    warnOnClose: boolean
    warning: Partial<AskConsentProps>

    translation: string | number

    noBusyGuard: boolean

    renderOnMount: boolean
    unmountOnClose: boolean
}>>


export const Drawer = (props: DrawerProps) => {

    const {
        className,
        innerStyle,
        innerClassName,
        scope = 'page',
        title,
        titleSuffix,
        icon = <UnknownIcon />,
        open,
        onClose,
        closeButton,
        warnOnClose,
        warning = {},
        noMask,
        width = '40%',
        fixedHeight,
        delayedRender,
        translation,
        children,
        noBusyGuard,
        renderOnMount,
        unmountOnClose,
        ...rest } = props


    const { askDiscardConsent } = useFeedback()

    // tracks the end of the sliding animation, starting from the initial state.
    // note: innacurate as draw skeleton on refresh because we don't observe the transition if the state starts open. 
    const [animationOver, animationOverSet] = React.useState(open)

    const {readonly} = useReadonlyProps(props)

    // wraps a label around title, if any.
    const fullTitle = title ? <Label className='drawer-title' icon={icon} title={title} /> : undefined

    const titleAndSuffix = fullTitle && titleSuffix ? 
    
        <div style={{display:'flex'}}>
            {fullTitle}
            <div style={{marginLeft:'auto'}}>
                {titleSuffix}
            </div>
        </div>  
        
    : fullTitle

    const closeWithConsentIfRequired = () => {

        const _onClose = () => {

            animationOverSet(false)
            onClose?.()
        }

        if (warnOnClose)
            askDiscardConsent({ ...warning }).thenRun(_onClose)
        else
            _onClose();

    }

    const afterStateChange = (state: boolean) => animationOverSet(state)

    // renders children, or a skeleton if they should be rendered after animation.
    let content = <div style={{height: fixedHeight ? '100%' : 'auto',...innerStyle}} className={ classname("drawer-main-content", innerClassName)}>

        {animationOver || !delayedRender ?

            children

            :

            <Skeleton count={30} />
        }
    </div>

    content = <ReadOnly value={!!readonly}>
        {content}
    </ReadOnly>


    return <Component name="drawer" {...rest}  >{

        <AntdDrawer
        
            forceRender={ renderOnMount }

            destroyOnClose={ unmountOnClose }

            title={titleAndSuffix}

            className={classname(className, 'apprise-drawer-body', `${scope}-drawer`)}

            getContainer={scope === 'page' ? undefined : false}

            //  invoked before drawer closes, only if drawer observes event (not if client changes state).
            //  suitable for asking the user consent and abort without it.
            onClose={closeWithConsentIfRequired}

            closable={!!closeButton}
            closeIcon={<AiFillCloseCircle className='close-icon' />}

            open={open}
            mask={!noMask}
            width={width}

            push={scope === 'box' ? { distance: 0 } : { distance: translation ?? 200 }}

            // invoked _after_ the drawer opens and closes
            // and in all cases: explicit user action or open property change.
            // not suitable to prevent the change, only to act _after_ it.
            afterOpenChange={afterStateChange}
        >

            {noBusyGuard ?

                <LazyBusyGuard>
                    {content}
                </LazyBusyGuard>
                :
                <BusyGuard noBleed >
                    {content}
                </BusyGuard >
            }

        </AntdDrawer>
    }

    </Component>
}