import { EventSubscription } from "apprise-frontend-events/subscriptions"
import { useLogged, useLogin } from 'apprise-frontend-iam/login/logged'
import { useCurrentLocation } from 'apprise-ui/link/currentlocation'
import { useHistory } from 'react-router-dom'
import { userChangeTopic, userLogoutTopic } from "./constants"
import { User } from './model'
import { useUserStore } from './store'

export type UserLogoutEvent = {
    users: string[]
}

export const useUserLogoutListener = (): EventSubscription<UserLogoutEvent> => {

    const logged = useLogged()
    const login = useLogin()

    return {

        topic: userLogoutTopic

        ,

        onEvent: (event: UserLogoutEvent) => event.users.includes(logged.username) && login.logout({ askConsent: true })

    }
}


export type UserChangeEvent = {
    user: User
    type: 'add' | 'remove' | 'change'
}

export const useUserChangeListener = (): EventSubscription<UserChangeEvent> => {

    const userstore = useUserStore()

    const location = useCurrentLocation()

    const history = useHistory()
   
    return {

        topic: userChangeTopic

        ,

        onEvent: (event: UserChangeEvent) => {


            userstore.push(event)

            history.replace(location.current(), `${event.user.username}-${event.type}`)
                

        }

    }

}