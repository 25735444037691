

import { StateProvider } from 'apprise-frontend-core/state/provider'
import { useRenderGuard } from 'apprise-frontend-core/utils/renderguard'
import * as React from 'react'
import { Config } from './model'
import { ConfigContext, initialConfigState, useConfigState } from './state'





export type ConfigProps = React.PropsWithChildren<{

    config?: Config

}>

// activates configuration management support: mounts state and initialises it. 

export const Configuration = (props: ConfigProps) => {

    return <StateProvider initialState={initialConfigState} context={ConfigContext}>
            <Initialiser {...props} />
    </StateProvider>

}

// register client configuration and then renders children.

const Initialiser = (props: ConfigProps) => {

    const { children, config: clientConfig = {} } = props

    const config = useConfigState()

    const { content } = useRenderGuard({

        render: children,

        orRun: () => config.addClientConfig(clientConfig)
    })

    return content
}
