import { useL } from 'apprise-frontend-core/intl/multilang'
import { Optional } from 'apprise-frontend-core/utils/common'
import { CategoryReference, useCategoryModel } from './model'
import { useCategoryStore } from './store'


export const useCategoryUtils = () => {

    const l = useL()

    const store = useCategoryStore()
    const model = useCategoryModel()

    const self = {

        compareRef: (o1: Optional<string>, o2: Optional<string>) =>  model.categoryComparator(store.lookupCategory(o1), store.lookupCategory(o2))

        ,

        nameOf: (ref: Optional<CategoryReference>) => l(store.safeLookupCategory(ref)?.name)
    }

    return self
}