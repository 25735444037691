import { utils } from 'apprise-frontend-core/utils/common';
import { CategoryReference } from 'apprise-frontend-tags/category/model';
import { Tag, TagReference } from 'apprise-frontend-tags/tag/model';
import { ExternalTagCacheContext } from 'apprise-frontend-tags/tag/provider';
import { useContext } from 'react';
import { useExternalTagCalls } from './calls';


export const useExternalTagStore = () => {

    const state = useContext(ExternalTagCacheContext)

    const calls = useExternalTagCalls()

    const self = {

        lookupById: (id: TagReference | undefined = '', category?: CategoryReference | undefined) => {

            return category ?  state.get().all[category]?.[id] : Object.values(state.get().all).reduce((acc,next) => acc || next[id], undefined as Tag | undefined)
        }

        ,

        lookupByCode: (code: string | undefined = '', category?: CategoryReference | undefined) => {

            return category ?  state.get().allCodes[category]?.[code] : Object.values(state.get().allCodes).reduce((acc,next) => acc || next[code], undefined as Tag | undefined)
        }

        ,

        fetchTagsInCategory: async (category: CategoryReference, forceRefresh? :boolean) => utils().clock(`fetched ${category}`, async () => {


            const current = state.get().all[category] 

            if (current && !forceRefresh)
                return

            const all = await calls.fetchTagsInCategory(category) ?? []

            const idmap = utils().index(all).by(t=>t.id)
            const codemap = utils().index(all).by(t=>t.code)

            state.set(s => {
            
                s.all[category] = idmap
                s.allCodes[category] = codemap
            
            } )
        })

      

    }

    return self;

}