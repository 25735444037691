import { useT } from 'apprise-frontend-core/intl/language'
import { CategoryIcon } from 'apprise-frontend-tags/constants'
import { Tag } from 'apprise-frontend-tags/tag/model'
import { Label } from 'apprise-ui/label/label'
import { useOptionMenu } from 'apprise-ui/optionmenu/optionmenu'
import { useCallback, useMemo } from 'react'
import { CategoryLabel } from './label'
import { useCategoryModel } from './model'
import { useCategoryStore } from './store'


/*
    see tag/filter, just a variation.

*/

export type CategoryFilterProps = Partial<{

        label?: React.ReactNode

        initial?: Tag[]
        filtered: Tag[]

        excludeCategories?: string[]
        initiallyOpen?: boolean

}>

// works with Tagged objects, so knows how to extract tags.
export const useCategoryFilter = (props: CategoryFilterProps) => {

        const store = useCategoryStore()
        const model = useCategoryModel()
        const t = useT()

        const defaultLabel = <Label icon={<CategoryIcon />} title={t('tag.category_plural')} />

        const { filtered = [], initial = filtered, label = defaultLabel, initiallyOpen, excludeCategories = [] } = props

        // computes distinct tags from population.
        const distinctCategories = useMemo(() =>

                initial.filter(t => t.category && !excludeCategories.includes(t.category))
                        .map(t => store.safeLookupCategory(t.category))
                        .filter((v, i, a) => a.indexOf(v) === i)  // distinct
                        .sort(model.categoryComparator) // ordered

                // eslint-disable-next-line
                , [initial])                             // recompute on data change.



        const kit = useOptionMenu({ initiallyActive: distinctCategories, initiallyOpen })

        const { OptionMenu, Option, active } = kit

        // filter function: includes elements with no tags or that have at least one tag.
        const categoryFilter = useCallback(

                (t: Tag) => !t.category || active.some(c => c.id === t.category)

                // eslint-disable-next-line
                , [active])  // recompute when selection changes



        const CategoryFilter = (

                <OptionMenu id={c => c.id} label={label}>
                        {distinctCategories.map(cat =>
                                <Option key={cat.id} value={cat} title={<CategoryLabel bare category={cat} />} />
                        )}
                </OptionMenu>
        )



        // filtered data
        const categoryFilteredTags = useMemo(

                () => filtered.filter(categoryFilter),

                [filtered, categoryFilter])    // recompute on data or filter change


        return { ...kit, CategoryFilter, categoryFilter, categoryFilteredTags }
}
