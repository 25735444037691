


import { BaseConfig } from './model';
import { useConfigState } from './state';




// returns all the available config under a desidered type.

export const useConfig = <T = BaseConfig>() => {

    const state = useConfigState()

    return (state.remoteConfig() ?? state.clientConfig()) as T

}



// indicates if code is running for local development, staging, and production.
// to enable features only for local development check for 'development'.
// to enable features also in staging check for '!production'.

export const useMode = () => {

    const { mode } = useConfig()

    return { development: mode === 'development', staging: mode === 'staging', production: mode === 'production' }
}


export const useInclusionList = (type: string) => {

    const { propertyExcludes } = useConfig()

    const excludes = propertyExcludes?.[type] ?? []

    const self = {

        includedOf: <T extends Record<string, any>>(properties: T) =>

            Object.entries(properties).reduce((acc, e) => ({ ...acc, ...self.isIncluded(e[0]) ? { [e[0]]: e[1] } : {} }), {}) as Partial<T>

        ,

        isIncluded: (property: string) => !excludes.includes(property)


    }


    return self
}