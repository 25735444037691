import { useState } from "react";
import { TableElement } from "./table";


export type Selection<E extends TableElement> = SelectionProps<E> & {

    selected: E[]
    select: (_: E[]) => void
    isEmpty: () => boolean
    isNotEmpty: () => boolean
    clear: () => void
}

type SelectionProps<E extends TableElement> = {

    initial:E[]
    isSelectable: (_:E, selected: E[]) => boolean
    selectAll: (selected: E[]) => boolean
}



export const useSelection = <E extends TableElement> (props:Partial<SelectionProps<E>>={}): Selection<E> => {

    const {initial=[],isSelectable= ()=>true, selectAll=()=>true} = props

    const [selected, selectedSet] = useState(initial)

    const self = { initial,
             selected, 
             select: (records: E[]) => selectedSet(records), 
             isSelectable,
             selectAll,
             isEmpty: ()=>selected.length===0,
             isNotEmpty: () => !self.isEmpty(),
             clear: () => self.select([])
            }
            
    return self
}