
import { Submission } from '#submission/model'
import { useT } from 'apprise-frontend-core/intl/language'
import { DeepPartial } from 'apprise-frontend-core/utils/common'
import { useTenantStore } from 'apprise-frontend-iam/tenant/store'
import { ParseContext } from 'apprise-frontend-parse/model'
import { useParseUtils } from 'apprise-frontend-parse/utils'
import { useExternalTagStore } from 'apprise-frontend-tags/external/store'
import { useMemo } from 'react'
import { indicatorTypeCategory, operationBeneficiaryCategory, operationBeneficiaryGenderCategory, operationBooleanCategory, operationCfrCategoryFor, operationDomainCategory, operationInterventionCategory, operationNutsCategory, operationObjectiveCategory, operationProgressCategory, operationSectorCategory, operationSupportCategory, operationTypeCategory } from './constants'
import { IndicatorData, Operation, OperationRef, useOperationUtils } from './model'




export type SubmissionParseContext = {

    submission: Submission
    operations: Record<string,Operation> 
}


export type OperationRow = Partial<{

    cci: string
    id: string | number
    description: string
    cfr: string
    type: string
    sector: string
    objective: string
    location: string
    progress: string
    domain: string,
    intervention: string,

    people: string
    partners: string

    beneficiaryName: string
    beneficiaryCode: string
    beneficiaryType: string
    beneficiaryGender: string
    beneficiaryLead: string
    beneficiarySupported: string

    eligibleCost: string,
    eligiblePublicCost: string,
    emfafSupport: string,
    emfafEligibleExpenditure: string,
    approvalDate: any,
    eligibleExpenditure: string,
    eligiblePublicExpenditure: string,
    finalPaymentDate: any,

    gtIncrease: string,
    fleetSegment: string,
    sscf: string,

    landingObligation: string,
    climateChange: string,
    nonDiscrimination: string,
    genderEquality: string,
    disability: string,

    support: string


}>

export const officialFormat = 'dd/MM/yyyy'
export const defaultFormats = ['dd/MM/yy',officialFormat]


const padnumeric = (code: any) => isNaN(parseInt(code)) ? code : `${code}`?.padStart(2, '0') 


export const useOperationParser = (submission: Submission) => {


    const tenants = useTenantStore()
    const oputils = useOperationUtils()
    const externals = useExternalTagStore()
    const parse = useParseUtils()

    const tenantRef = submission.tenant

    const [cfrcat] = useMemo(() => {

        const tenant = tenants.lookup(tenantRef)
        const cfrcat = tenant?.code ? operationCfrCategoryFor(tenant.code) : undefined
        return [cfrcat]

        //eslint-disable-next-line
    }, [tenantRef])

   return ($: OperationRow) => {

        const operation: DeepPartial<Operation> = {

            tenant: tenantRef,
            submission: submission.id,

            id: oputils.mintId(),
            cci: $.cci ?? undefined,
            operationId: $.id ? `${$.id}` : undefined,
            description: $.description ?? undefined,
            sector: parse.code2id(padnumeric($.sector)).of(operationSectorCategory),
            type: parse.code2id(padnumeric($.type)).of(operationTypeCategory),
            state: parse.code2id(padnumeric($.progress)).of(operationProgressCategory),
            objective: parse.code2id($.objective).of(operationObjectiveCategory),
            support: parse.code2id(padnumeric($.support)).of(operationSupportCategory),
            domain: parse.code2id(padnumeric($.domain)).of(operationDomainCategory),
            interventionType: parse.code2id($.intervention).of(operationInterventionCategory),
            location: externals.lookupByCode($.location, operationNutsCategory)?.id ?? $.location,
            beneficiary: {

                name: $.beneficiaryName ?? undefined,
                code: $.beneficiaryCode ?? undefined,
                type: parse.code2id(padnumeric($.beneficiaryType)).of(operationBeneficiaryCategory),
                lead: parse.code2id(padnumeric($.beneficiaryLead)).of(operationBooleanCategory),
                gender: parse.code2id(padnumeric($.beneficiaryGender)).of(operationBeneficiaryGenderCategory),
                previouslySupported: parse.code2id(padnumeric($.beneficiarySupported)).of(operationBooleanCategory)

            },
            financial: {

                eligibleCost: parse.float($.eligibleCost, 2).orIssue('error').about('eligible cost').on($.id),
                eligiblePublicCost: parse.float($.eligiblePublicCost, 2).orIssue('error').about('eligible public cost').on($.id),

                eligibleExpenditure: parse.float($.eligibleExpenditure, 2).orIssue('error').about('eligible expenditure').on($.id),
                eligiblePublicExpenditure: parse.float($.eligiblePublicExpenditure, 2).orIssue('error').about('eligible public expenditure').on($.id),

                EMFAFSupport: parse.float($.emfafSupport, 2).orIssue('error').about('EMFAF support').on($.id),
                EMFAFEligibleExpenditure: parse.float($.emfafEligibleExpenditure, 2).orIssue('error').about('EMFAF eligible expenditure').on($.id),
                approvalDate: parse.date($.approvalDate, defaultFormats).orIssue('error').about('approval date').on($.id),
                finalPaymentDate: parse.date($.finalPaymentDate,defaultFormats).orIssue('error').about('final payment date').on($.id),

            },
            stats: {
                people: parse.int($.people).orIssue('error').about("number of people").on($.id),
                partners: parse.int($.partners).orIssue('error').about("number of partners").on($.id)
            },
            relevance: {

                sscf: parse.code2id(padnumeric($.sscf)).of(operationBooleanCategory),
                landingObligation: parse.code2id(padnumeric($.landingObligation)).of(operationBooleanCategory),
                climateChange: parse.code2id(padnumeric($.climateChange)).of(operationBooleanCategory),
                nonDiscrimination: parse.code2id(padnumeric($.nonDiscrimination)).of(operationBooleanCategory),
                genderEquality: parse.code2id(padnumeric($.genderEquality)).of(operationBooleanCategory),
                disability: parse.code2id(padnumeric($.disability)).of(operationBooleanCategory)

            }

            ,

            indicators: []

            ,

            vessel : {
                cfrNumbers: $.cfr?.trim() ? `${$.cfr}`.split(";").map(cfr=> cfr?.trim()).map(cfr => cfrcat ? externals.lookupByCode(cfr, cfrcat)?.id ?? cfr : cfr) : undefined,
                increaseGT: parse.float($.gtIncrease).orIssue('error').about('GT increase').on($.id),
                fleetSegment: $.fleetSegment ?? undefined
            }

        }

        return operation as Operation
    }

}


export type OperationIndicatorRow = Partial<{

    cci: string,
    id: string
    indicator: string
    baseline: string,
    expected: string
    expost: string

}>

export type ExtendedIndicatorData = IndicatorData & {

    op: OperationRef
}



export const useIndicatorParser = () => {

    const t = useT()
    const parse = useParseUtils()

    return ($: OperationIndicatorRow, ctx: ParseContext<SubmissionParseContext>) => {

        const { operations } = ctx

        // IN_1_1
        if ($.cci == undefined)
            throw Error(t('op.missing_cci_ind', { rule: 'IN_1_1',value: $.cci, item: $.id }))

        // iN 2_1
        if ($.id == undefined)
            throw Error(t('op.missing_id',{rule:'IN_2_1'}))

        const op = operations[$.id]

        // IN_1+2_2
        if (op == undefined)
            throw Error(t('op.invalid_id_ind', {rule: 'IN_1+2_2', value: $.id }))

        if (op.cci != undefined && ($.cci !== op.cci))
            throw Error(t('op.invalid_cci', { rule: 'IN_1_2', value: $.cci, cci: op.cci }))

        return {

            op: $.id,
            indicator: parse.code2id($.indicator).of(indicatorTypeCategory),
            baseline: parse.float($.baseline).orIssue('error').about('Indicator baseline value').on($.id),
            expected: parse.float($.expected).orIssue('error').about('Indicator expected value').on($.id),
            expost: parse.float($.expost).orIssue('error').about('Indicator expost value').on($.id)

        } as ExtendedIndicatorData  // we're forcing a match, but some strings but may go through to be visible to users but caught by validation.
    }

}