

import { AiOutlineTeam } from "react-icons/ai"


export const tenantType = "tenant"
export const TenantIcon = AiOutlineTeam

export const tenantSingular = `tenant.singular`
export const tenantPlural = `tenant.plural`

export const tenantChangeTopic = tenantType