


import { useT } from 'apprise-frontend-core/intl/language'
import { fallbackStateOver, State } from 'apprise-frontend-core/state/api'
import { StateProvider } from 'apprise-frontend-core/state/provider'
import * as React from 'react'
import { useComponentBridge, WaitIndicatorProps } from './bridge'
import { useStable } from './function'


//  maintains a state of tasks that make the application busy and provides an indicator that shows the busy state.
//  comes in a common eager flavour (<BusyGuard>) and a more flexible lazy flavour (<LazyBusyGuard />).
//  the eager guard mounts the busy indicator around its children.
//  the lazy guard puts the busy indicator in context and let consumers pick it and place it where they want to.
//  the eager is built as a consumer of the lazy guard, for code reuse.
//  there's an api that allows tasks to signal when they are running, or are no longer running.


export type BusyGuardProps = React.PropsWithChildren<{

    debug: boolean,
    indicator: (props: Partial<WaitIndicatorProps>) => JSX.Element
    waiting: boolean
    noBleed: boolean
    dispatch: boolean
}>




export type BusyTasks = {


    tasks: Task[]

}


type Task = {

    key: string,
    msg?: React.ReactNode | undefined
}


const initialState: BusyTasks = {

    tasks: []

}
export const BusyContext = React.createContext<State<BusyTasks>>(fallbackStateOver(initialState))


export const messageUpdateEvent = 'spin-msg'

//  indicator context

export const useBusyGuardIndicator = () => React.useContext(IndicatorContext)?.Indicator ?? React.Fragment
export const useBusyFlag = () => React.useContext(IndicatorContext)?.spinning

const IndicatorContext = React.createContext<IndicatorState>(undefined!)

type IndicatorState = {

    Indicator: React.FC<React.PropsWithChildren<{wait?:boolean, msg?:string}>>
    spinning: boolean,
    msg: Task['msg']

}



export const LazyBusyGuard = (props: Partial<BusyGuardProps>) => {

    const { children, noBleed=true, ...rest } = props

    return <StateProvider initialState={initialState} context={BusyContext}>
        <DeferredInner {...rest} noBleed={noBleed}>
            {children}
        </DeferredInner>
    </StateProvider>

}


export const BusyGuard = (props: Partial<BusyGuardProps>) => {

    const { children, noBleed=false, ...rest } = props

    return <LazyBusyGuard {...rest} noBleed={noBleed} >
        <EagerInner>
            {children}
        </EagerInner>
    </LazyBusyGuard>

}


export const useBusyState = () => {

    const state = React.useContext(BusyContext)

    const t = useT()

    const self = {

        // the first busy task, if any.
        next: () => state.get().tasks[0]

        ,

        // updates the message of a busy task.
        update: (key: string, msg: React.ReactNode) => state.set(s => {

            s.tasks.forEach(t => {

                if (t.key === key)
                    t.msg = msg

            })

        })

        ,

        // signals the start and stop of a busy task ( and returns a promise for chaining the actual task).
        toggle: (key: string, msg?: React.ReactNode) =>

            Promise.resolve(state.set(s => {

                const index = s.tasks.findIndex(t => t.key === key)

                if (index < 0)
                    s.tasks.push({ key, msg: typeof msg === 'string' ? t(msg) : msg })

                else
                    s.tasks.splice(index, 1)


            }))

    }

    return self
}


const EagerInner = (props: React.PropsWithChildren<{wait?: boolean}>) => {

    // pick indicator and mounts it eagerly
    const Indicator = useBusyGuardIndicator()

    return <Indicator {...props} />
}


const DeferredInner = (props: Partial<BusyGuardProps>) => {

    const busy = useBusyState()

    const bridge = useComponentBridge()

    const { noBleed, waiting: forceSpinning, children, indicator: clientIndicator, dispatch=true } = props

    const busyTask = busy.next()

    const spinning = forceSpinning || !!busyTask

    props.debug && console.log("outside",{spinning,busyTask})

    const msg = busyTask?.msg

    // this is stable, so that its children don't get mounted and remounted all the time.
    const Indicator = useStable((indicatorprops: React.PropsWithChildren<{wait?: boolean, msg?:string}>) => {

        const Compo = clientIndicator ?? bridge.Indicator

        const { children, wait = spinning, msg: message = msg  } = indicatorprops

        props.debug && console.log("inside",{spinning,busyTask})

        return <Compo style={{ height: "100%" }} noBleed={noBleed} waiting={wait} msg={message}>
            {children}
        </Compo>

    })

    // this changes with spinning or message so it refreshes the context and the consumers that mount the Indicator.
    const value = React.useMemo(() => {
    
        dispatch && window.dispatchEvent(new CustomEvent(messageUpdateEvent, { detail: msg }))
        
        return { spinning, msg, Indicator }

    }, [spinning, msg, Indicator, dispatch])

    return < IndicatorContext.Provider value={value} >
        {children}
    </IndicatorContext.Provider >
}


