import { InputNumber } from 'antd';
import { TagIcon } from 'apprise-frontend-tags/constants';
import { MultiTagRefBox, TagRefBox } from 'apprise-frontend-tags/tag/tagrefbox';
import { Sized, Tall, Wide } from 'apprise-ui/component/model';
import { Field } from 'apprise-ui/field/field';
import { Fielded, Uncontrolled } from 'apprise-ui/field/model';
import { RichBox } from 'apprise-ui/richbox/richbox';
import { SliderBox } from 'apprise-ui/sliderbox/slider';
import { SwitchBox } from 'apprise-ui/switchbox/switchbox';
import { TextBox } from 'apprise-ui/textbox/textbox';
import toNumber from 'lodash/toNumber';
import { AiOutlineCheck, AiOutlineNumber } from 'react-icons/ai';
import { MdTextFields } from 'react-icons/md';
import { IoMdArrowDropdownCircle } from 'react-icons/io';
import { CustomProperty } from './model';
import { MultiSelectBox, MultiSelectProps, SelectBox, SingleSelectProps } from 'apprise-ui/selectbox/selectbox';




type NumberBoxProps<T=number> = Fielded<T> & Sized & Wide & Tall & Uncontrolled & Partial<{

    children: T
    min? : number
    max? : number

}>


const NumberBox = (props:NumberBoxProps) => <Field name="numberbox" {...props}>
    <InputNumber<number> defaultValue={props.children} onChange={props.onChange as any} style={{width: '100%'}} min={props.min} max={props.max}/>
</Field>



export const textPropertyType : CustomProperty<string> =   { 
    
    id: 'text',
    name: 'tag.custom_text',
    as: (t:any) => t && `${t}`,
    Icon: MdTextFields,
    ValueBox: TextBox
    
}

export const richtextPropertyType : CustomProperty<string> =   { 
    
    id: 'richtext',
    name: 'tag.custom_richtext',
    as: (t:any) => t && `${t}`,
    Icon: MdTextFields,
    ValueBox: RichBox
    
}

export const numberPropertyType : CustomProperty<number> =   { 
    
    id: 'number',
    name: 'tag.custom_number',
    Icon: AiOutlineNumber,
    as: (t:any) => t && toNumber(t),
    ValueBox: NumberBox
    
}

export const sliderPropertyType : CustomProperty=   { 
    
    id: 'slider',
    name: 'tag.custom_slider',
    Icon: AiOutlineNumber,
    as: (t:any) => t && toNumber(t),
    ValueBox: SliderBox
    
}


export const booleanPropertyType : CustomProperty =   { 
    
    id: 'boolean',
    name: 'tag.custom_boolean',
    Icon:AiOutlineCheck,
    as: (t:any) => t,
    ValueBox: SwitchBox
    
}

export const selectorPropertyType : CustomProperty<any,SingleSelectProps<any>> =   { 
    
    id: 'selector',
    name: 'tag.custom_selector',
    Icon:IoMdArrowDropdownCircle ,
    as: (t:any) => t,
    ValueBox: SelectBox
    
}

export const multiSelectorPropertyType : CustomProperty<any,MultiSelectProps<any>> =   { 
    
    id: 'selector',
    name: 'tag.custom_selector',
    Icon:IoMdArrowDropdownCircle ,
    as: (t:any) => t,
    ValueBox: MultiSelectBox
    
}

export const tagSelectorPropertyType : CustomProperty = {
    id: 'tag-selector',
    name: 'tag.custom_tagselector',
    Icon: TagIcon,
    as: (t:any) => t,
    ValueBox: TagRefBox
}

export const multiTagSelectorPropertyType : CustomProperty = {
    id: 'tag-multiselector',
    name: 'tag.custom_tagselector',
    Icon: TagIcon,
    as: (t:any) => t,
    ValueBox: MultiTagRefBox
}


export const predefinedCustomPropertyTypes = [ 
    textPropertyType, 
    richtextPropertyType, 
    numberPropertyType, 
    booleanPropertyType,
    selectorPropertyType,
    sliderPropertyType,
    tagSelectorPropertyType,
    multiTagSelectorPropertyType
]