import { Component } from "apprise-ui/component/component"
import { Styled } from "apprise-ui/component/model"
import { utils } from 'apprise-frontend-core/utils/common'
import * as React from "react"
import "./styles.scss"

export type Props= React.PropsWithChildren< Styled & {

    icons: JSX.Element | JSX.Element[]
    tabs: JSX.Element

    noAffix:boolean
    offset: number

    priority: number

}>

export const  Topbar = ($:Partial<Props>) => {

    const {tabs,icons,noAffix,priority=1,offset=-1,children,style} = $ 

    // if not disabled, sticks at some offset from the parent's top as other content scrolling underneath.
    const affixStyle : React.CSSProperties = noAffix ? style ?? {} : {position:'sticky',background:'inherit',top: offset, zIndex: 100*priority, ...style}

    const allIcons = utils().arrayOf(icons).map((icon,i)=><div key={i} className="topbar-icon">{icon}</div>)

    return <Component name="topbar" {...$} style={affixStyle}>
                {allIcons}
                {tabs && tabs}
                <div className='topbar-right'>{children}</div>
            </Component>
}
