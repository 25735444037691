
import { Component } from 'apprise-ui/component/component';
import { CSSProperties } from 'react';
import errorUrl from "./error.jpg";



export const Error = ({ fit }: { fit?:boolean}) => {


  const bleedStyle: CSSProperties = {
    flexGrow:1,     // flex-parents
    height:'100%',  // sized-parents
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }

  return <Component name='todo' style={bleedStyle}>
    <img alt="in-development" style={{ objectFit: "cover", width:fit?'100%':undefined, height:fit?'100%':undefined }} src={errorUrl} />
  </Component>
}