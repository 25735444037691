import { CustomPropertyContext } from 'apprise-frontend-tags/category/provider'
import { useCategoryStore } from 'apprise-frontend-tags/category/store'
import { Tag } from 'apprise-frontend-tags/tag/model'
import { FieldInfo } from 'apprise-ui/field/model'
import { GenericIcon } from 'apprise-ui/utils/icons'
import { useContext } from 'react'
import { CustomProperty, CustomPropertyRef } from './model'
import { predefinedCustomPropertyTypes } from './types'
import { useTagModules } from 'apprise-frontend-tags/tag/modules'



export const useCustomTagProperties = () => {

    const state = useContext(CustomPropertyContext)

    const store = useCategoryStore()

    const tagModule = useTagModules()


    const self = {


        noType: (): CustomProperty => ({
            id: 'unknown',
            name: 'tag.custom_unknown',
            Icon: GenericIcon,
            ValueBox: ({ children }) => <>{children}</>
        })

        ,


        lookupType: (type: CustomPropertyRef) => ([...state.get().types, ...predefinedCustomPropertyTypes]?.find(t => t.id === type) ?? self.noType()) as CustomProperty

        ,

        propertiesFor: (category: string) => {

            const resolvedCategory = store.safeLookupCategory(category)

            const moduleCustomProps = resolvedCategory ? tagModule.lookup(resolvedCategory.type)?.customProperties ?? [] : []

            const categoryCustomProps = store.safeLookupCategory(category).properties.custom ?? []
            
            return [...categoryCustomProps, ...moduleCustomProps]
        }

        ,

        // allows any and heterogenoues subtypes of ValueBoxProperty
        register: (...types: CustomProperty<any, any>[]) => state.set(s => s.types = [...s.types, ...types])

    }

    return self
}


// transforms the custom properties of a tag so that each value is paired with the prop type required to proces it.
export const useCustomPropertyMap = (tag: Tag) => {

    const tagprops = useCustomTagProperties()

    const catprops = tag.category ? tagprops.propertiesFor(tag.category) ?? [] : []

    const freeprops = Object.keys(tag.properties.custom ?? {}).filter(key => !catprops.includes(key))
 
    const proptypes = [...catprops, ...freeprops].map(tagprops.lookupType)

    return proptypes.reduce((acc, type) => {

        const {id,info} = type

        const value = tag.properties.custom?.[id]
       
        return {

            ...acc,

            [id]: { type, value  , info: info?.(value)} 
        }

    }, {} as Record<string, { type: CustomProperty, value: any, info?: FieldInfo }>)
}





// export const useCustomPropFields = (form: FormState<Tag>) => {

//     const { edited } = form

//     const map = useCustomPropertyMap(edited)

//     const properties = edited.properties.custom ?? {}

//     const fields = Object.keys(properties)
//         .map(key => map[key].type )
//         .reduce((acc, type) =>

//             ({ ...acc, ...type?.fields?.(form) })

//             , {} as Fields)

//     return fields

// }
